import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CheckoutSection, CheckoutSectionHeadline, CheckoutSectionText, RadioList, } from "../components";
import { InputText } from "@mm/ui";
import styled from "styled-components";
import { ACTIONS, useCheckoutState } from "../context/CheckoutContext";
import { useCheckout } from "../hooks/api";
import { useField, useValidation, ValidateOnEvent, } from "../hooks";
const Row = styled.div `
  display: flex;
  width: 100%;
  gap: 1.5rem;
  @media (max-width: 760px) {
    flex-wrap: wrap;
  }
`;
const FullWidthWrapper = styled.div `
  flex: 1;
  @media (max-width: 760px) {
    flex: 1 0 100%;
  }
`;
const OneThirdWrapper = styled.div `
  flex: 0 0 33%;
  @media (max-width: 760px) {
    flex: 1 0 100%;
  }
`;
export const ShippingAddressSection = ({ defaultValues, schema, options, }) => {
    const { state, dispatch } = useCheckoutState();
    const { isFetching, isLoading } = useCheckout(state);
    const { errors: differingShippingAddressErrors, fieldState: differingShippingAddress, setFieldState: setDifferingShippingAddress, trigger: triggerDifferingShippingAddress, } = useField("differingShippingAddress", schema, Boolean(state?.differingShippingAddress || defaultValues?.differingShippingAddress));
    const { errors: salutationErrors, fieldState: salutation, setFieldState: setSalutation, trigger: triggerSalutation, } = useField("shippingAddressSalutation", schema, "Frau", {
        validateOn: ValidateOnEvent.Manual,
    });
    const { errors: firstNameErrors, fieldState: firstName, setFieldState: setFirstName, trigger: triggerFirstName, } = useField("shippingAddressFirstName", schema, "", {
        validateOn: ValidateOnEvent.Manual,
    });
    const { errors: lastNameErrors, fieldState: lastName, setFieldState: setLastName, trigger: triggerLastName, } = useField("shippingAddressLastName", schema, "", {
        validateOn: ValidateOnEvent.Manual,
    });
    const { errors: zipCodeErrors, fieldState: zipCode, setFieldState: setZipCode, trigger: triggerZipCode, } = useField("shippingAddressZipCode", schema, "", {
        validateOn: ValidateOnEvent.Manual,
    });
    const { errors: placeErrors, fieldState: place, setFieldState: setPlace, trigger: triggerPlace, } = useField("shippingAddressPlace", schema, "", {
        validateOn: ValidateOnEvent.Manual,
    });
    const { errors: streetErrors, fieldState: street, setFieldState: setStreet, trigger: triggerStreet, } = useField("shippingAddressStreet", schema, "", {
        validateOn: ValidateOnEvent.Manual,
    });
    const { errors: streetNumberErrors, fieldState: streetNumber, setFieldState: setStreetNumber, trigger: triggerStreetNumber, } = useField("shippingAddressStreetNumber", schema, "", {
        validateOn: ValidateOnEvent.Manual,
    });
    const { clearErrors, } = useValidation();
    return (_jsxs(CheckoutSection, { children: [_jsx(CheckoutSectionHeadline, { children: options?.title ?? "Lieferadresse für die Vertragsunterlagen" }), _jsx(RadioList, { checked: differingShippingAddress
                    ? "differingShippingAddress--yes"
                    : "differingShippingAddress--no", id: "differingShippingAddress", items: [
                    {
                        key: "differingShippingAddress--no",
                        label: "Entspricht Kontaktadresse",
                    },
                    {
                        key: "differingShippingAddress--yes",
                        label: "Abweichende Lieferadresse",
                    },
                ], onChange: (key) => {
                    clearErrors([
                        "shippingAddressSalutation",
                        "shippingAddressFirstName",
                        "shippingAddressLastName",
                        "shippingAddressZipCode",
                        "shippingAddressPlace",
                        "shippingAddressStreet",
                        "shippingAddressStreetNumber",
                    ]);
                    if (key === "differingShippingAddress--no") {
                        dispatch({
                            type: ACTIONS.RESET_FIELDS,
                            payload: [
                                "shippingAddressSalutation",
                                "shippingAddressFirstName",
                                "shippingAddressLastName",
                                "shippingAddressZipCode",
                                "shippingAddressPlace",
                                "shippingAddressStreet",
                                "shippingAddressStreetNumber",
                            ],
                        });
                    }
                    setDifferingShippingAddress(key === "differingShippingAddress--yes");
                    triggerDifferingShippingAddress();
                }, disabled: isLoading || isFetching, error: differingShippingAddressErrors?.[0]?.message }), _jsx(CheckoutSectionText, { children: "Bitte beachte, dass eine Lieferung an eine Packstation nicht m\u00F6glich ist." }), differingShippingAddress && (_jsxs(_Fragment, { children: [_jsx(RadioList
                    // TODO: refactor RadioList keys to enum
                    , { 
                        // TODO: refactor RadioList keys to enum
                        checked: salutation === "Frau"
                            ? "shippingAddressSalutation--frau"
                            : "shippingAddressSalutation--herr", id: "shippingAddressSalutation", items: [
                            {
                                key: "shippingAddressSalutation--frau",
                                label: "Frau",
                            },
                            {
                                key: "shippingAddressSalutation--herr",
                                label: "Herr",
                            },
                        ], onChange: (key) => {
                            setSalutation(key === "shippingAddressSalutation--frau" ? "Frau" : "Herr");
                            triggerSalutation();
                        }, disabled: isLoading || isFetching, error: salutationErrors?.[0]?.message }), _jsxs(Row, { children: [_jsx(FullWidthWrapper, { children: _jsx(InputText, { id: "shippingAddressFirstName", label: "Vorname*", value: firstName, message: firstNameErrors?.[0]?.message, layout: firstNameErrors && "error", onChange: (value) => {
                                        setFirstName(value);
                                    }, onBlur: () => triggerFirstName(), autoComplete: "none" }) }), _jsx(FullWidthWrapper, { children: _jsx(InputText, { id: "shippingAddressLastName", label: "Nachname*", value: lastName, message: lastNameErrors?.[0]?.message, layout: lastNameErrors && "error", onChange: (value) => {
                                        setLastName(value);
                                    }, onBlur: () => triggerLastName(), autoComplete: "none" }) })] }), _jsxs(Row, { children: [_jsx(OneThirdWrapper, { children: _jsx(InputText, { id: "shippingAddressZipCode", label: "PLZ*", value: zipCode ?? "", message: zipCodeErrors?.[0]?.message, layout: zipCodeErrors && "error", onChange: (value) => {
                                        setZipCode(value);
                                    }, onBlur: () => triggerZipCode(), autoComplete: "none" }) }), _jsx(FullWidthWrapper, { children: _jsx(InputText, { id: "shippingAddressPlace", label: "Ort*", value: place, message: placeErrors?.[0]?.message, layout: placeErrors && "error", onChange: (value) => {
                                        setPlace(value);
                                    }, onBlur: () => triggerPlace(), autoComplete: "none" }) })] }), _jsxs(Row, { children: [_jsx(FullWidthWrapper, { children: _jsx(InputText, { id: "shippingAddressStreet", label: "Stra\u00DFe*", value: street, message: streetErrors?.[0]?.message, layout: streetErrors && "error", onChange: (value) => {
                                        setStreet(value);
                                    }, onBlur: () => triggerStreet(), autoComplete: "none" }) }), _jsx(OneThirdWrapper, { children: _jsx(InputText, { id: "shippingAddressStreetNumber", label: "Nummer*", value: streetNumber ?? "", message: streetNumberErrors?.[0]?.message, layout: streetNumberErrors && "error", onChange: (value) => {
                                        setStreetNumber(value);
                                    }, onBlur: () => triggerStreetNumber(), autoComplete: "none" }) })] })] }))] }));
};
