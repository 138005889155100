import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { Surface, Headline } from "@mm/ui";
import { ThemeProvider } from "@core/theme";
import { InternetOfferTeaserRegards } from "@/components/organisms";
import { useInternetProvider } from "@/features/Internet";
import { Spinner } from "@/components/atoms";
const SpinnerWrapper = styled.div `
  display: flex;
  justify-content: center;
`;
const InternetTeaser = () => {
    const { state: { availabilityChecked, isLoadingOffers, promotionOffers, showRegards, }, } = useInternetProvider();
    if (showRegards && isLoadingOffers) {
        return (_jsx(Surface, { variant: "narrow", mt: "44px", children: _jsx(SpinnerWrapper, { children: _jsx(Spinner, { color: "ghost", size: "4rem" }) }) }));
    }
    if (promotionOffers.length < 1) {
        return null;
    }
    return (_jsx(ThemeProvider, { children: _jsxs(Surface, { variant: "narrow", children: [_jsxs(Headline, { variant: "h4", children: ["Unsere Empfehlung", availabilityChecked ? " für deine Adresse" : ""] }), _jsx(InternetOfferTeaserRegards, { availabilityChecked: availabilityChecked, data: promotionOffers })] }) }));
};
export default InternetTeaser;
