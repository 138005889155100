import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { RadioList, CheckoutSection, CheckoutSectionHeadline, } from "../../components";
import { useCheckoutState } from "../../context/CheckoutContext";
import { useField } from "../../hooks";
import { useCheckout } from "../../hooks/api";
import styled from "styled-components";
import { InstallationTimeWindow } from "../../types";
const InputWrapper = styled.div `
  width: 100%;
`;
export const InstallationTimeWindowSection = ({ defaultValues, options, schema, }) => {
    const { state } = useCheckoutState();
    const { errors, fieldState, setFieldState } = useField("installationTimeWindow", schema, String(defaultValues?.installationTimeWindow) ??
        InstallationTimeWindow.NotSpecified);
    const { installationTimeWindowOptions } = options ?? {
        installationTimeWindowOptions: [],
    };
    const { isFetching, isLoading } = useCheckout(state);
    return (_jsxs(CheckoutSection, { children: [_jsx(CheckoutSectionHeadline, { children: "W\u00FCnscht du dir ein Zeitfenster f\u00FCr die Installation? (Technikertermin)" }), _jsx(InputWrapper, { children: _jsx(RadioList, { checked: fieldState ?? "unspecified", id: "installationTimeWindow", items: installationTimeWindowOptions, onChange: (key) => {
                        setFieldState(key);
                    }, disabled: isLoading || isFetching, error: errors?.[0]?.message }) })] }));
};
