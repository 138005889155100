import slice from "lodash/slice";

// allow setting of config aliases as X.alias = X[Y]
/* eslint-disable prefer-destructuring */

export const fonts = [
  "EurostileNextLTPro, sans-serif",
  "Source Sans Pro, sans-serif",
  "mm-icons, sans-serif",
  "EurostileNextLTProBold, sans-serif",
];
fonts.headline = fonts[0];
fonts.text = fonts[1];
fonts.icon = fonts[2];
fonts.price = fonts[3];
fonts.headlineBig = fonts[3];

const black = "#000000";
const white = "#FFFFFF";

export const colors = {
  black,
  white,

  brand: {
    primary: "#ef7c00",
    muted: "#DB7100",
    grey: "#333333",
    lightgrey: "#454647",
  },
  feedback: {
    success: "#70C118",
    warning: "#FFA500",
    error: "#FF0000",
  },
  shade: [white, "#F4F4F4", "#CDCACA", "#958F8F", "#4C4A4A", "#201D1D", black],
};

// sat requires another borderRadius
const pillLabelList = {
  borderRadius: "0px",
};

const flexHero = {
  maxWidth: "290px",
  borderBottomRightRadius: "0",
  borderTopRightRadius: "0",
  connectLogo: {
    positionRight: "-122px",
  },
  dynamicStageStyles: {
    borderBottomRightRadius: "0",
    before: {
      left: "-100px",
    },
    after: {
      right: "-35px",
    },
  },
  corner: {
    clipPath:
      "polygon(50% 0%, 73% 0px, 119.46% -1px, 130% 57%, 120.09% 99.05%, 117.19% 215.72%, 8.45% 215.24%, 15.17% 99.85%, -1px 33.91%, 13.58% -2px);",
  },
  cornerxs: {
    clipPath:
      "polygon(50% 0%, 73% 0px, 119.46% -1px, 130% 57%, 120.09% 99.05%, 117.19% 215.72%, 8.45% 215.24%, -1.88% 97.95%, -25px 34.86%, -19.2% 0px);",
  },
};

const ColoredStageHeadline = {
  color: colors.brand.primary,
  fontsize: "32px",
};

/**
 * shim to not break old components
 *
 * @deprecated
 */
const colorsLegacy = {
  primary: [colors.brand.primary, colors.brand.muted],
  grey: [
    colors.black,
    ...slice(colors.shade, 1, colors.shade.length - 1),
    "#1f1d1d",
    "#d1d1d1",
    "#444444",
  ],
  white: colors.white,
  success: colors.feedback.success,
  warning: colors.feedback.warning,
  error: colors.feedback.error,
};

// Variants

const header = {
  primary: {
    backgroundColor: colors.brand.lightgrey,
    color: colors.white,
  },
};

const navigation = {
  primary: {
    backgroundColor: colors.brand.grey,
  },
};

const eyecatcher = {
  before: {
    borderRadius: "0",
  },
  after: {
    borderRadius: "0",
    borderTopLeftRadius: "4px",
  },
};

const button = {
  borderRadius: "0",
};

const priceDisplay = {
  // orange font
  primary: {
    textShadow: "none",
    color: colors.brand.primary,
  },
  // white font (stage)
  secondary: {
    textShadow: "none",
    color: colors.white,
  },
};

const theme = {
  // base theme
  colors: { ...colors, ...colorsLegacy },
  fonts,

  // Variants
  header,
  navigation,
  priceDisplay,
  eyecatcher,
  button,
  // extend for more custom styles for Saturn
  flexHero,
  ColoredStageHeadline,
  pillLabelList,
};

export default theme;
