import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCheckoutState } from "../../context/CheckoutContext";
import { useField } from "../../hooks";
import { useCheckout } from "../../hooks/api";
import { CheckboxWithLabel, CheckoutSection, CheckoutSectionHeadline, CheckoutSectionText, } from "../../components";
export const ContractSummarySection = ({ defaultValues, schema, }) => {
    const { state, state: { eeccError }, } = useCheckoutState();
    const { errors, fieldState, setFieldState } = useField("eeccApprovalToSendSummary", schema, Boolean(defaultValues?.eeccApprovalToSendSummary));
    const { isFetching, isLoading } = useCheckout(state);
    return (_jsxs(CheckoutSection, { children: [_jsx(CheckoutSectionHeadline, { children: "Vertragszusammenfassung per E-Mail" }), _jsx(CheckoutSectionText, { children: "Durch eine Gesetzes\u00E4nderung sind wir dazu verpflichtet, dir deine Vertragsdokumente zur Verf\u00FCgung zu stellen. Diese werden direkt von o2 (Telef\u00F3nica Germany GmbH & Co. OHG) per E-Mail versendet." }), _jsx(CheckoutSectionText, { children: "Nachdem du dem Versand der Dokumente an deine E-Mail-Adresse zugestimmt hast, gelangst du mit Klick auf den Button \u201Eweiter\u201C zur \u00DCbersicht deiner Bestellung." }), _jsx(CheckboxWithLabel, { checked: fieldState, error: errors?.[0]?.message, onChange: () => setFieldState(!fieldState), label: "Hiermit stimme ich zu, dass mir die Vertragsdokumente und rechtliche Pflichtinformationen von o2 (Telef\u00F3nica Germany GmbH & Co. OHG) per E-Mail zugesandt werden.*", disabled: isLoading || isFetching }), eeccError && (_jsxs(_Fragment, { children: [_jsx(CheckoutSectionText, { children: _jsx("strong", { children: "Leider ist es im Moment technisch nicht m\u00F6glich den Email Versand auszul\u00F6sen. Du erh\u00E4ltst nach Abschluss der Bestellung eine Mail, in der du die M\u00F6glichkeit hast, alle Vertragsdokumente anzufordern." }) }), _jsx(CheckoutSectionText, { children: "Erst nach Erhalt der Vertragsunterlagen k\u00F6nnen wir deine Bestellung an o2 weiterleiten." })] }))] }));
};
