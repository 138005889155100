import React from "react";
import { Surface } from "@mm/ui";
import { useViewport } from "@core/theme";
import Loading from "../../components/molecules/Loading";
import { useOfferConfigurator } from "../../features/Offer";
import styled from "styled-components";
import { breakpoints } from "@core/theme/src/theme/theme";

const PdpBannerWrapper = styled.div`
  .pdpBanner {
    height: auto;
    width: 100%;
  }
`;

const BannerSectionMiddle = () => {
  const {
    state: {
      rootInfo,
      extendedVariants,
      extendedVariant,
      activeTariff,
      stock,
      activeAccessoryVariantIds,
    },
  } = useOfferConfigurator();

  const { hardware } = rootInfo;

  if (!rootInfo) {
    return <Loading variant="details" gridWrapper marginTop={4} />;
  }

  const { is } = useViewport();
  const topDisneyPlus = {
    xlUrl: "/img/teaser/disneyplus_liste/1104x236.png",
    lgUrl: "/img/teaser/disneyplus_liste/1104x196.png",
    mdUrl: "/img/teaser/disneyplus_liste/946x254.png",
    smUrl: "/img/teaser/disneyplus_liste/700x251.png",
    xsUrl: "/img/teaser/disneyplus_liste/478x249.png",
    pdfUrl:
      "/img/teaser/disneyplus_liste/TNB_Disney_Abo_9_Mon_MSD_01112023.pdf",
  };

  return (
    <>
      {hardware?.manufacturer === "Samsung" && (
        <Surface variant="narrow" mt={[4, 4, 4, 6, 6]}>
          <PdpBannerWrapper>
            <a
              href={window.CDN_URL + topDisneyPlus.pdfUrl}
              target="_blank"
              rel="noreferrer"
            >
              <picture>
                <source
                  srcSet={window.CDN_URL + topDisneyPlus.xlUrl}
                  media={`(min-width: ${breakpoints.xl})`}
                />
                <source
                  srcSet={window.CDN_URL + topDisneyPlus.lgUrl}
                  media={`(min-width: ${breakpoints.lg})`}
                />
                <source
                  srcSet={window.CDN_URL + topDisneyPlus.mdUrl}
                  media={`(min-width: ${breakpoints.md})`}
                />
                <source
                  srcSet={window.CDN_URL + topDisneyPlus.smUrl}
                  media={`(min-width: ${breakpoints.sm})`}
                />
                <source
                  srcSet={window.CDN_URL + topDisneyPlus.xsUrl}
                  media={`(min-width: ${breakpoints.xs})`}
                />
                <img
                  className="pdpBanner"
                  alt="Disney+"
                  src={window.CDN_URL + topDisneyPlus.lgUrl}
                />
              </picture>
            </a>
          </PdpBannerWrapper>
        </Surface>
      )}
    </>
  );
};

export default BannerSectionMiddle;
