import React from "react";
import styled from "styled-components";

import { CartButton, TariffPriceDisplay } from "components/molecules";
import { EnergyPriceDetails } from "./EnergyPriceDetails";

const StyledFooter = styled("footer")`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: auto;
  gap: ${({ theme }) => theme.space.s}px;
`;

const Footer = (props) => {
  const { tariff, offerType, dataQa } = props;
  const { brutto_arbeitspreis, brutto_grundpreis } = tariff.apiData;

  return (
    <StyledFooter>
      <div>
        <TariffPriceDisplay noPostText tariff={tariff} size="s" />
        {brutto_arbeitspreis && brutto_grundpreis && (
          <EnergyPriceDetails
            brutto_arbeitspreis={brutto_arbeitspreis}
            brutto_grundpreis={brutto_grundpreis}
            offerType={offerType}
          />
        )}
      </div>
      <CartButton dataQa={dataQa} />
    </StyledFooter>
  );
};

Footer.propTypes = {};

export default Footer;
