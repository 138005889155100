import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormattedMessage, useIntl } from "@core/text";

import { Surface, Row, Col } from "@mm/ui";

import Link from "components/atoms/Link";

const StyledWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.grey[5]};
  padding: 32px 0;

  @media (min-width: ${({ theme }) => theme.breakpointsMap.md.min}px) {
    padding: 48px 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpointsMap.xl.min}px) {
    padding: 64px 0;
  }
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  margin: 0;
  list-style-type: none;
  padding: 0;

  @media (min-width: ${(props) => props.theme.breakpointsMap.lg.min}px) {
    flex-direction: row;
  }
`;

const Item = styled.li`
  margin-bottom: 20px;

  @media (min-width: ${(props) => props.theme.breakpointsMap.lg.min}px) {
    margin-bottom: 0;
    margin-right: 25px;
  }

  svg {
    fill: ${(props) => props.theme.colors.primary[0]};
    margin-right: 7px;
    position: relative;
    transform: rotate(90deg);
    top: 1px;
  }
`;

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors.grey[2]} !important;
  padding-left: 16px;

  :hover {
    color: ${(props) => props.theme.colors.grey[2]};
  }

  :before {
    background: ${(props) => props.theme.colors.grey[2]};
    left: 16px;
    width: calc(100% - 16px);
  }
`;

const ExternAnchor = styled(StyledLink)`
  :after {
    display: none;
  }
`;

const Legal = styled.p`
  color: ${(props) => props.theme.colors.grey[2]};
  font-size: 12px;
  margin: 24px 0 0;

  @media (min-width: ${(props) => props.theme.breakpointsMap.lg.min}px) {
    margin-top: 32px;
  }
`;

const MetaNavigation = ({ openModal }) => {
  const intl = useIntl();
  return (
    <StyledWrapper>
      <Surface variant="wide">
        <Row>
          <Col>
            <List>
              <Item>
                <StyledLink small href="/agb" target="_blank">
                  AGB
                </StyledLink>
              </Item>
              <Item>
                <StyledLink small href="/datenschutz" target="_blank">
                  Datenschutz
                </StyledLink>
              </Item>
              <Item>
                <StyledLink small href="/widerrufsrecht" target="_blank">
                  Widerruf
                </StyledLink>
              </Item>
              <Item>
                <StyledLink small href="/impressum" target="_blank">
                  Impressum
                </StyledLink>
              </Item>
              <Item>
                <ExternAnchor
                  href={intl.formatMessage({
                    id: "url.main.shop",
                  })}
                  small
                >
                  <FormattedMessage id="app.backToMainShop" />
                </ExternAnchor>
              </Item>
            </List>
            <Legal>
              Alle Preise in Euro und inkl. der gesetzlichen Mehrwertsteuer,
              zzgl. Versandkosten. Änderungen und Irrtümer vorbehalten.
              Abbildungen ähnlich. Nur solange der Vorrat reicht. Liefergebiet:
              Deutschland
            </Legal>
          </Col>
        </Row>
      </Surface>
    </StyledWrapper>
  );
};

MetaNavigation.propTypes = {
  openModal: PropTypes.string,
};

MetaNavigation.defaultProps = {
  openModal: null,
};

export default MetaNavigation;
