import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { addDays, parse, startOfDay } from "date-fns";
import { CheckoutSection, CheckoutSectionHeadline, CheckoutSectionText, DatePicker, } from "../../components";
import { useCheckoutState } from "../../context/CheckoutContext";
import { useCheckout } from "../../hooks/api";
import { useField } from "../../hooks";
import styled from "styled-components";
import { formatDate } from "@/utils/formatDate";
const Row = styled.div `
  display: flex;
  width: 100%;
  gap: 1.5rem;
`;
const InputWrapper = styled.div `
  flex: 1;
`;
export const ActivationDateSection = ({ defaultValues, schema, options, }) => {
    const { state } = useCheckoutState();
    const { selectableStartInDays, selectableEndInDays } = options ?? {
        selectableStartInDays: 0,
        selectableEndInDays: 365,
    };
    const startDate = startOfDay(addDays(new Date(), Number(selectableStartInDays)));
    const endDate = startOfDay(addDays(new Date(), Number(selectableEndInDays)));
    const { errors: activationDateErrors, fieldState: activationDate, setFieldState: setActivationDate, } = useField("activationDate", schema, String(defaultValues?.activationDate) ?? "");
    const { isFetching, isLoading } = useCheckout(state);
    return (_jsxs(CheckoutSection, { children: [_jsx(CheckoutSectionHeadline, { children: "Zu wann w\u00FCnschst du die Aktivierung des Anschlusses?" }), _jsxs(CheckoutSectionText, { children: ["Dein Anschluss kann nach deiner Bestellung zwischen dem", " ", formatDate(startDate), " und dem ", formatDate(endDate), " bereitgestellt werden. W\u00E4hle bitte dein Wunschdatum aus."] }), _jsx(Row, { children: _jsx(InputWrapper, { children: _jsx(DatePicker, { id: "activationDate", label: "Aktivierungsdatum*", selectableStart: startDate, selectableEnd: endDate, selected: activationDate
                            ? parse(activationDate, "dd.MM.yyyy", new Date())
                            : undefined, openToDate: startDate, onChange: (date) => setActivationDate(date?.toLocaleString("de-DE", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                        }) ?? ""), layout: activationDateErrors && "error", message: activationDateErrors?.[0]?.message, disabled: isLoading || isFetching }) }) })] }));
};
