import React from "react";
import { Box } from "@mm/ui";

import { Bulletlist, Eyecatchers, TileBox } from "components/atoms";
import { ProductAccessoryDisplay, Loading } from "components/molecules";

import {
  AffiliateOfferContainer,
  AffiliateOfferItem,
  AffiliateButton,
  StyledOfferHeadline,
} from "./AffiliateOffer.styled";

import PriceDisplayAffOffer from "./PriceDisplay";

const handleAffiliate = (trackingEvent, affUrl) => {
  const trackingObj = {
    event: "gaEvent",
    eventCategory: "AffiliateOffer Element",
    eventAction: "click",
    eventLabel: `${trackingEvent}`,
  };
  window.dataLayer.push(trackingObj);
  if (affUrl.length > 0) {
    window.open(`${affUrl}`, "_blank").focus();
  }
};

const AffiliateOffer = ({ data }) => {
  if (!data) return <Loading variant="manufacturer" />;

  return (
    <AffiliateOfferContainer>
      {data.map((item) => (
        <AffiliateOfferItem key={item.title.replace(" ", "_")}>
          {item.eyeCatcherOne && (
            <Eyecatchers
              eyecatchers={[item.eyeCatcherOne, item.eyeCatcherTwo]}
              variant="affOffer"
            />
          )}
          <TileBox variant="tile">
            {item.title && (
              <Box mb={2}>
                <StyledOfferHeadline>{item.title}</StyledOfferHeadline>
              </Box>
            )}
            {item.imagePath && (
              <ProductAccessoryDisplay
                alt={item.title}
                name={item.title}
                manufacturer={item.title}
                singleImage={item.imagePath}
              />
            )}
            {item.bullets && (
              <Box mt={2}>
                <Bulletlist variant="checkmark" list={item.bullets} size="m" />
              </Box>
            )}
            <Box mt={2} marginTop="auto">
              <>
                <PriceDisplayAffOffer
                  AffOffer={item.legalNotes}
                  direction="column"
                  oldPrice={`${item.oldPrice}`}
                  oneTimePrice={item.oneTimePrice}
                  price={item.price}
                  priceNotice={item.priceNotice}
                />
                <AffiliateButton
                  onClick={() =>
                    handleAffiliate(item.trackingEvent, item.affiliateUrl)
                  }
                >
                  Zur Bestellung
                </AffiliateButton>
              </>
            </Box>
          </TileBox>
        </AffiliateOfferItem>
      ))}
    </AffiliateOfferContainer>
  );
};

export default AffiliateOffer;
