/* eslint-disable no-shadow */
import React from "react";
import styled from "styled-components";
import { Surface, Headline } from "@mm/ui";

const MarkerBox = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`;

const MarkerItem = styled.a`
  display: flex;
  justify-items: center;
  align-self: center;
  text-decoration: none;
  height: ${(props) => props.theme.space.xl}px;
  color: #1f1d1d;
  font-weight: bold;
  border: 1px solid rgb(207, 203, 202);
  border-radius: ${(props) => props.theme.space.xxs}px;
  padding: ${(props) => props.theme.space.xs}px;
  &:hover {
    padding: calc(7px);
    cursor: pointer;
    border: 2px solid #ff0000;
  }
  @media (min-width: ${(props) => props.theme.breakpointsMap.md.min}px) {
    height: 32px;
  }
`;

const MarkerDescription = styled.p`
  margin: 0;
  font-size: ${(props) => props.theme.space.s}px;
  align-self: center;
  @media (min-width: ${(props) => props.theme.breakpointsMap.md.min}px) {
    font-size: ${(props) => props.theme.space.m}px;
  }
`;

// TODO: Check this filter after OfferTeaser refactoring.
const allowedElements = [
  "OfferTeaser",
  "OfferTeaserTwoColumns",
  "OfferTeaserFourColumns",
];

const JumpMarker = ({ sections, data }) => {
  const preparedMarker = sections.filter((item) => {
    if (allowedElements.includes(item.elements[0].type)) {
      return item;
    }
    return null;
  });

  return (
    <Surface variant="narrow">
      {data.title && data.active && (
        <Headline variant="h3" mb={2}>
          {data.title}
        </Headline>
      )}
      <MarkerBox>
        {preparedMarker.map(
          (item) =>
            item.internalName && (
              <MarkerItem
                href={`#${item.internalName}`}
                key={item.internalName}
              >
                <MarkerDescription>{item.headlineText}</MarkerDescription>
              </MarkerItem>
            )
        )}
      </MarkerBox>
    </Surface>
  );
};

export default JumpMarker;
