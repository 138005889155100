import React from "react";
/**
 *
 * SECTIONS
 *
 * */

const Anchors = React.lazy(() => import("sections/Anchors/index.js"));
const AffiliateOffer = React.lazy(
  () => import("sections/AffiliateOffer/index.js")
);
const CategoryBar = React.lazy(() => import("sections/CategoryBar/index.js"));
const ContactInfo = React.lazy(() => import("sections/ContactInfo/index.js"));
const Copy = React.lazy(() => import("sections/Copy/index.js"));
const CopyImg = React.lazy(() => import("sections/CopyImg/index.js"));
const CountDown = React.lazy(() => import("sections/Countdown/index.tsx"));
const StageSlider = React.lazy(() => import("sections/StageSlider/index.js"));
const SliderTeaser = React.lazy(() => import("sections/SliderTeaser/index.js"));
const Headline = React.lazy(() => import("sections/SectionHeadline/index.js"));
const GridTeaser = React.lazy(() => import("sections/GridTeaser/index.js"));
const TwoToOneTeaser = React.lazy(
  () => import("sections/TwoToOneTeaser/index.js")
);
const ThreeStageTeaser = React.lazy(
  () => import("sections/ThreeStageTeaser/index.js")
);
const StandardTeaser = React.lazy(
  () => import("sections/StandardTeaser/index.js")
);
const ManufacturerTile = React.lazy(
  () => import("sections/ManufacturerTile/index.js")
);

const ProviderQuittingTile = React.lazy(
  () => import("sections/ProviderQuittingTile/index.js")
);

const OfferTeaser = React.lazy(() => import("sections/OfferTeaser/index.js"));
const OfferHero = React.lazy(() => import("sections/OfferHero/index.js"));
const OfferTeaserTwoColumns = React.lazy(
  () => import("sections/OfferTeaserTwoColumns/index.js")
);
const OfferTeaserFourColumns = React.lazy(
  () => import("sections/OfferTeaserFourColumns/index.js")
);
const RichSEOSection = React.lazy(
  () => import("sections/RichSEOSection/index.js")
);
const ImageBanner = React.lazy(() => import("sections/ImageBanner/index.js"));
const ServiceAccordion = React.lazy(
  () => import("sections/ServiceAccordion/index.js")
);
const EnergyHero = React.lazy(
  () => import("sections/OfferHero/layout/energy/energy.js")
);
const Filter = React.lazy(() => import("sections/Filter/index.js"));
const JumpMarker = React.lazy(() => import("sections/JumpMarker/index.js"));

const Sections = {
  Anchors,
  AffiliateOffer,
  CategoryBar,
  ContactInfo,
  Copy,
  CopyImg,
  CountDown,
  GridTeaser,
  StageSlider,
  SliderTeaser,
  Headline,
  TwoToOneTeaser,
  ThreeStageTeaser,
  StandardTeaser,
  ManufacturerTile,
  ProviderQuittingTile,
  OfferTeaser,
  OfferHero,
  RichSEOSection,
  ImageBanner,
  ServiceAccordion,
  Filter,
  OfferTeaserTwoColumns,
  OfferTeaserFourColumns,
  EnergyHero,
  JumpMarker,
};

export default Sections;
