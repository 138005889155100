import styled from "styled-components";
import { variant, compose, space } from "styled-system";
import { Surface, Flex } from "@mm/ui";
export const BackgroundSurface = styled(Surface) `
  display: flex;
  align-items: center;
  background-image: url(${(props) => props.backgroundImg});
  background-position: center;
  background-size: cover;
  padding-top: ${({ theme }) => theme.space.m}px;
  padding-bottom: ${({ theme }) => theme.space.xxlhuge}px;
  min-height: 410px;
  border-radius: 0;
  box-shadow: ${({ theme }) => theme.shadows.elevation2};
  max-width: 1440px;
`;
export const ContentColor = styled("div") `
  h1 {
    color: ${(props) => {
    switch (props.variant) {
        case "white":
            return ({ theme }) => theme.colors.white;
        case "black":
            return ({ theme }) => theme.colors.black;
        case "brand":
            return ({ theme }) => theme.colors.brand.primary;
        default:
            return "white";
    }
}};
  }

  p,
  span {
    color: ${(props) => {
    switch (props.variant) {
        case "white":
            return ({ theme }) => theme.colors.white;
        case "black":
            return ({ theme }) => theme.colors.black;
        case "brand":
            return ({ theme }) => theme.colors.brand.primary;
        default:
            return "white";
    }
}};
  }

  p,
  span {
    display: block;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  @media (min-width: ${({ theme }) => theme.breakpointsMap.sm.min}px) {
    p {
      margin-bottom: 0;
    }
  }

  ${variant({
    variants: {
        black: {
            color: "black",
        },
        white: {
            color: "white",
        },
    },
})}
`;
export const ErrorSvgWrapper = styled("div") `
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid red;

  svg {
    display: block;
    position: absolute;
    top: 21px;
    left: 21px;
    transform: scale(1.5);

    path {
      fill: red;
    }
  }
`;
export const FlexWrapper = styled(Flex) `
  gap: ${({ theme }) => theme.space.m}px;
`;
export const FlexContainer = styled(Flex) `
  gap: ${({ theme }) => theme.space.m}px;
  justify-content: space-between;

  button[type="submit"] {
    width: 100%;
  }

  ${variant({
    variants: {
        noShrink: {
            flex: "0 0 auto",
        },
        shrink: {
            flex: "1 0 auto",
        },
        full: {
            flexWrap: "wrap",
        },
        half: {
            flexWrap: "nowrap",
        },
    },
})}
`;
export const FormSurface = styled(Surface) `
  .internet-hero {
    &__form,
    &__submitMessage {
      background-color: white;
      border-radius: 4px;
      box-shadow: ${({ theme }) => theme.shadows.elevation2};
      padding: ${({ theme }) => theme.space.xxl}px
        ${({ theme }) => theme.space.s}px;
      width: auto;
      margin-top: -70px;

      @media (min-width: ${({ theme }) => theme.breakpointsMap.sm.min}px) {
        padding: ${({ theme }) => theme.space.xxl}px
          ${({ theme }) => theme.space.xl}px;
      }

      @media (min-width: ${({ theme }) => theme.breakpointsMap.md.min}px) {
        padding: ${({ theme }) => theme.space.xxl}px;
      }

      @media (min-width: ${({ theme }) => theme.breakpointsMap.lg.min}px) {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &__headline {
      display: block;
      font-weight: 600;
      font-size: 20px;
      margin: 0;
      padding: 0;
    }

    &__subheadline {
      display: block;
      font-weight: 400;
      font-size: 16px;
      margin: 0 8px 0 0;
      padding: 0;
    }
  }

  .changeAddressButton {
    appearance: none;
    border: 0;
    background: transparent;
    color: #000000;
    text-decoration: underline;

    &:hover,
    &:focus {
      cursor: pointer;
    }
  }

  .posIdButton {
    appearance: none;
    border: 0;
    background: transparent;
    color: #000000;
    text-decoration: none;
  }
`;
export const InnerWrapper = styled("div") `
  margin: 0 auto;
  width: 100%;
  max-width: 1024px;
`;
export const OptionList = styled("ul") `
  background: white;
  border-radius: 4px;
  border: 2px solid rgb(205, 202, 202);
  display: block;
  list-style: none;
  min-width: 100%;
  max-height: 190px;
  max-width: 200px;
  margin: 0;
  overflow-y: scroll;
  position: absolute;
  padding: 10px;
  z-index: 10;

  .option-list {
    &__item {
      padding: 5px 0;

      &:hover,
      &:focus {
        cursor: pointer;
      }

      &--text-right {
        text-align: right;
      }

      &.active {
        background-color: #c2c2c2;
      }

      &.hide {
        display: none;
      }
    }
  }
`;
export const StyledInputText = styled("div") `
  flex: 1 0 0;
  position: relative;

  ${variant({
    variants: {
        full: {
            flex: "1 0 auto",
        },
        half: {
            flex: "1 0 0",
        },
        short: {
            flex: "0.5 0 0",
        },
        big: {
            flex: "2 0 0",
        },
    },
})}

  ${compose(space)}
`;
export const SvgWrapper = styled("span") `
  position: relative;
  z-index: 1;

  svg {
    display: block;
    path {
      fill: ${({ theme }) => theme.colors.grey[4]};
    }
  }
`;
