import slice from "lodash/slice";
import merge from "lodash/merge";
import { THEME as MM_THEME } from "@mm/theme";
import { THEME as SATURN_THEME } from "@saturn/theme";

const tenantTheme = {
  MEDIAMARKT: MM_THEME,
  SATURN: SATURN_THEME,
}[TENANT];

const maxWidthNarrow = 1104;
const maxWidthWide = 1472;
const maxWidthUltra = 100;

// allow setting of config aliases as X.alias = X[Y]
/* eslint-disable prefer-destructuring */

export const space = [0, 4, 8, 12, 16, 20, 24, 40, 48, 56, 72, 88];
space.xxs = space[1]; // 4
space.xs = space[2]; // 8
space.s = space[3]; // 12
space.m = space[4]; // 16
space.l = space[5]; // 20
space.xl = space[6]; // 24
space.xxl = space[7]; // 40
space.xxlLong = space[8]; // 48
space.xxxl = space[9]; // 56
space.huge = space[10]; // 72
space.xxlhuge = space[11]; // 88
space.horizontal = space.xs; // 8
space.h = space.xs; // 8

export const breakpoints = ["320px", "512px", "752px", "1008px", "1232px"];
breakpoints.xs = breakpoints[0]; // 320
breakpoints.sm = breakpoints[1]; // 512
breakpoints.md = breakpoints[2]; // 752
breakpoints.lg = breakpoints[3]; // 1008
breakpoints.xl = breakpoints[4]; // 1232

export const modalSizes = [1200, 1024, 756, 560, 440];
modalSizes.xxl = modalSizes[0];
modalSizes.xl = modalSizes[1];
modalSizes.lg = modalSizes[2];
modalSizes.md = modalSizes[3];
modalSizes.sm = modalSizes[4];

/**
 * Media Queries for use outside of styled-system
 */
export const mediaQueries = {
  xs: `@media screen and (min-width: ${breakpoints[0]})`,
  sm: `@media screen and (min-width: ${breakpoints[1]})`,
  md: `@media screen and (min-width: ${breakpoints[2]})`,
  lg: `@media screen and (min-width: ${breakpoints[3]})`,
  xl: `@media screen and (min-width: ${breakpoints[4]})`,
  xsMax: `@media screen and (max-width: ${breakpoints[0] - 1})`,
  smMax: `@media screen and (max-width: ${breakpoints[1] - 1})`,
  mdMax: `@media screen and (max-width: ${breakpoints[2] - 1})`,
  lgMax: `@media screen and (max-width: ${breakpoints[3] - 1})`,
  xlMax: `@media screen and (max-width: ${breakpoints[4] - 1})`,
};

const black = "#000000";
const white = "#FFFFFF";

export const colors = {
  black,
  white,
  brand: {
    primary: "#DF0000",
    muted: "#A10000",
  },
  orange: "#ffa500",
  green: "#00ff00",
  feedback: {
    success: "#70C118",
    warning: "#FFA500",
    error: "#FF0000",
  },
  shade: [white, "#F4F4F4", "#CDCACA", "#958F8F", "#4C4A4A", "#201D1D", black],
};

export const borders = {
  small: "1px solid #CDCACA",
  radius: "4px",
};

export const flexGrid = {
  gap: 24,
};

export const radii = ["50%"];
radii.circle = radii[0];

const fontIcons = {
  ShoppingCart: "\\1f6d2",
  Menu: "\\2261",
  ArrowR: "\\276f",
  ArrowU: "\\5e",
  ArrowL: "\\276e",
  ArrowD: "\\76",
  Info: "\\69",
  Plus: "\\1F6D3",
};

/* Component Variants */
const icons = {
  teaser: {
    size: 80,
  },
  primary: {
    size: 24,
    bg: "red",
    color: colors.white,
  },
};

export const fontSizes = [10, 12, 14, 16, 18, 20, 24, 28, 32, 40];

const headlines = {
  h1: {
    fontSize: [8, null, null, 9],
    fontFamily: tenantTheme.fonts.headlineBig,
    lineHeight: 1.2,
    letterSpacing: 0.4,
  },
  h2: {
    fontSize: [7, null, null, 8],
    fontFamily: tenantTheme.fonts.headline,
    lineHeight: 1.25,
    letterSpacing: 0.4,
  },
  h3: {
    fontSize: 6,
    fontFamily: tenantTheme.fonts.headline,
    lineHeight: 1.33,
    letterSpacing: 0.4,
  },
  h4: {
    fontSize: 5,
    fontFamily: tenantTheme.fonts.headline,
    lineHeight: 1.2,
    letterSpacing: 0.4,
  },
  h5: {
    fontSize: 3,
    fontFamily: tenantTheme.fonts.headline,
    lineHeight: 1.5,
  },
  teaserBig: {
    fontSize: [8, null, null, 9],
    fontFamily: tenantTheme.fonts.headlineBig,
    lineHeight: 1.2,
    letterSpacing: 0.4,
  },
  teaserSmall: {
    fontSize: 6,
    fontFamily: tenantTheme.fonts.headlineBig,
    lineHeight: 1.33,
    letterSpacing: 0.4,
  },
  CartModalItemHeadline: {
    fontSize: 6,
    fontFamily: tenantTheme.fonts.headline,
    lineHeight: 1.33,
    letterSpacing: 0.4,
    display: "-webkit-box",
    "-webkit-line-clamp": "2",
    "-webkit-box-orient": "vertical",
    "max-height": 62,
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
};

export const fontWeights = [400, 600];
fontWeights.normal = fontWeights[0];
fontWeights.highlighted = fontWeights[1];
// [10, 12, 14, 16, 18, 20, 24, 28, 32, 40];
export const typography = {
  type: {
    primary: tenantTheme.fonts.text,
    button: tenantTheme.fonts.headline,
  },
  weight: {
    normal: fontWeights.normal,
    highlighted: fontWeights.highlighted,
  },
  size: {
    xs: fontSizes[0], // 10px
    s: fontSizes[1], // 12px
    s1: fontSizes[2], // 14px
    m: fontSizes[3], // 16px
    m2: fontSizes[4], // 18px
    l: fontSizes[5], // 20px
  },
};

export const text = {
  xs: {
    fontSize: 0,
  },
  s: {
    fontSize: 1,
  },
  copy: {
    fontSize: 3,
  },
  smallCopy: {
    fontSize: 2,
  },
  l: {
    fontSize: [5, null, null, 4],
  },
  btn: {
    fontSize: 3,
    fontFamily: tenantTheme.fonts.headline,
    letterSpacing: 0.4,
  },
  // add headline styles to Text component
  ...headlines,
};

const shadows = {
  elevation2:
    "0 2px 10px 0 rgba(0, 0, 0, 0.07), 0 2px 5px 0 rgba(0, 0, 0, 0.06)",
  elevation3:
    "0 4px 10px 0 rgba(0, 0, 0, 0.07), 0 1px 5px 0 rgba(0, 0, 0, 0.06), 0 8px 44px 0 rgba(0, 0, 0, 0.1)",
  elevation4:
    "0 16px 28px 0 rgba(0, 0, 0, 0.07), 0 1px 5px 0 rgba(0, 0, 0, 0.06), 0 25px 55px 0 rgba(0, 0, 0, 0.1)",
};

const pages = {
  wide: {
    maxWidth: `${maxWidthWide}px`,
  },
  narrow: {
    maxWidth: `${maxWidthNarrow}px`,
  },
  ultra: {
    maxWidth: `${maxWidthUltra}vw`,
  },
};

/* LEGACY THEME */

/**
 * shim to not break old components
 *
 * @deprecated
 */
const colorsLegacy = {
  primary: [colors.brand.primary, colors.brand.muted],
  grey: [
    colors.black,
    ...slice(colors.shade, 1, colors.shade.length - 1),
    "#1f1d1d",
    "#d1d1d1",
    "#444444",
  ],
  white: colors.white,
  success: colors.feedback.success,
  warning: colors.feedback.warning,
  error: colors.feedback.error,
};

/**
 * @deprecated
 */
const borderRadius = ["0.2em", "0.3rem"];

/**
 * @deprecated
 */
const padding = ["0.4em", "0.8em", "0.2em", "1em"];

/**
 * lagacy fontSizes which don't match styleguide
 *
 * @deprecated
 */
const legacyFontSize = [
  "1.3rem",
  "1rem",
  "0.8rem",
  "0.7rem",
  "1.4rem",
  "1.6rem",
  "0.9rem",
];

/**
 * @deprecated
 */
const pageMargins = {
  xs: 16,
  sm: 16,
  md: 16,
  lg: 16,
  xl: 0,
};

const whiteShadowGradient =
  "linear-gradient(180deg, hsla(0, 0%, 100%, 0) 0, #f4f4f4)";

/**
 * @deprecated
 * TODO remove those as soon as possible ( same content width as header and footer )
 */
const alternativePageMargins = {
  xs: 16,
  sm: 24,
  md: 32,
  lg: 48,
  xl: 64,
};

/**
 * @deprecated
 */
const gutterWidths = {
  xs: 16,
  sm: 16,
  md: 24,
  lg: 24,
  xl: 24,
};

/**
 * Map of breakpoint values to min/max
 *
 * @deprecated
 */
export const breakpointsMap = {
  xs: {
    min: 320,
    max: 511,
  },
  sm: {
    min: 512,
    max: 751,
  },
  md: {
    min: 752,
    max: 1007,
  },
  lg: {
    min: 1008,
    max: 1231,
  },
  xl: {
    min: 1232,
    max: Infinity,
  },
};

/**
 * @deprecated
 */
const maxcols = 24;

/* END LEGACY THEME */

export const skeletonBase = `
    background: #f4f4f4;
    background-image: linear-gradient(to right,#f4f4f4 8%,#cccccc 18%,#f4f4f4 33%);
    -webkit-background-size: 800px 104px;
    background-size: 1000px 104px;
    display: block;
    height: 1rem;
    color: rgba(0, 0 , 0, 0);
`;

const extendedTheme = merge(
  {
    // base theme
    borders,
    breakpoints,
    modalSizes,
    mediaQueries,
    radii,
    space,
    colors: { ...colors, ...colorsLegacy },
    fontSizes,
    flexGrid,
    typography,

    // variants
    headlines,
    icons,
    pages,
    shadows,
    text,
    whiteShadowGradient,

    // loading states
    skeletonBase,

    // legacy
    fontIcons,
    alternativePageMargins,
    borderRadius,
    breakpointsMap,
    legacyFontSize,
    gutterWidths,
    maxcols,
    padding,
    pageMargins,
  },
  // add custom tenant theme
  tenantTheme
);

export default extendedTheme;
