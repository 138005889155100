import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useOfferConfigurator } from "@/features/Offer";
import { TariffBadge, } from "@/components/atoms";
import { ProductAccessoryDisplay, Loading, LegalNotes, } from "@/components/molecules";
import "./styles.scss";
function objectToArray(obj) {
    const resultArray = [];
    const hasOwnProperty = Object.prototype.hasOwnProperty;
    for (const key in obj) {
        if (hasOwnProperty.call(obj, key)) {
            if (obj[key] !== "") {
                resultArray.push(obj[key]);
            }
        }
    }
    return resultArray;
}
function stripHtmlTags(input) {
    // Create a new div element
    const tempDiv = document.createElement("div");
    // Set the innerHTML of the div to the input string
    tempDiv.innerHTML = input;
    // Retrieve the text content of the div, which will be the input string without HTML tags
    const textContent = tempDiv.textContent || tempDiv.innerText || "";
    return textContent;
}
const ThreeStageTeaser = ({ doubleCard, eyeCatchers, onlineOnly, accessories, active, configuratorView, hardware, headerImg, headline, tariff, text, teaserFormFields, type = "static", }) => {
    const { state } = useOfferConfigurator();
    if (!state)
        return _jsx(Loading, { variant: "teaser" });
    const getTypeBasedEyeCatcher = type == "dynamic" ? eyeCatchers : teaserFormFields.eyeCatchers;
    const modifiedEyeCatcher = objectToArray(getTypeBasedEyeCatcher) || false;
    return (_jsxs("a", { className: "msd-three-stage-teaser msd-teaser-box msd-teaser-box--flex-column", "aria-label": teaserFormFields.headline || teaserFormFields.text, href: teaserFormFields?.url || hardware?.link || tariff?.link, children: [_jsxs("div", { className: "msd-three-stage-teaser-header", style: {
                    backgroundImage: `url(${window.CDN_URL + teaserFormFields.headerImg})`,
                }, children: [hardware && tariff && type == "dynamic" && (_jsx("div", { className: "msd-three-stage-teaser-tariff-badge", children: _jsx(TariffBadge, { tariff: tariff }) })), type == "dynamic" && (_jsxs("div", { className: "msd-three-stage-teaser-price-info", children: [hardware && _jsxs("span", { className: "price", children: [hardware.price, ",- \u20AC "] }), !hardware && (_jsxs("span", { className: "price", children: [tariff.connectionFee, ",- \u20AC "] })), tariff && (_jsx(LegalNotes, { tariffLegalNotes: tariff.legalNotes, tariffId: tariff.id, stageStyle: false, pl: "1" })), " ", "einm.", _jsx("br", {}), _jsxs("span", { children: ["monatlich ", tariff.monthlyPrice, "\u20AC"] })] })), hardware && type == "dynamic" && (_jsx("div", { className: "msd-three-stage-teaser-product-accessory-display", children: _jsx(ProductAccessoryDisplay, { singleImage: hardware.image, name: hardware.name, imageHeight: "150px", manufacturer: hardware.manufacturer, accessories: accessories }) }))] }), _jsx("div", { className: "msd-three-stage-teaser-badges", children: _jsxs("ul", { className: "msd-badge-wrapper msd-badge-wrapper--zero-margin", children: [teaserFormFields.onlineOnly && (_jsx("li", { className: "msd-badge msd-badge--onlineOnly", children: _jsx("span", { className: "msd-badge-text", children: "Nur online" }) })), modifiedEyeCatcher &&
                            modifiedEyeCatcher.map((eyeCatcher) => (_jsx("li", { className: "msd-badge", children: _jsx("span", { className: "msd-badge-text", children: eyeCatcher }) }, eyeCatcher)))] }) }), _jsxs("div", { className: "msd-three-stage-teaser-content", children: [_jsx("span", { className: "msd-three-stage-teaser-headline", children: teaserFormFields.headline }), _jsx("span", { className: "msd-three-stage-teaser-text", children: teaserFormFields.text }), tariff && type == "dynamic" && (_jsx("div", { className: "msd-three-stage-teaser-footer", children: _jsx("ul", { className: "msd-badge-wrapper msd-badge-wrapper--zero-margin", children: tariff.bullets.slice(0, 2).map((eyeCatcher) => (_jsx("li", { className: "msd-badge msd-badge--bullets", children: _jsx("span", { className: "msd-badge-text", children: stripHtmlTags(eyeCatcher) }) }, eyeCatcher))) }) }))] })] }));
};
export default ThreeStageTeaser;
