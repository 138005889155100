import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, } from "react";
import { extendURLParams } from "@/utils/fetchData";
import { Box, Button, Headline, InputText, Text, Flex } from "@mm/ui";
const ProtectedForm = ({ headline, errorMessage, onKeyPress, onClick, buttonText, disclaimerText, validationRule, maxLength, label, setIsPageProtected, setVoucherCode, }) => {
    const { tenant, environment, restUrl } = extendURLParams();
    const [value, setValue] = useState("");
    const [isValid, setIsValid] = useState(true);
    const handleChange = (val) => {
        setValue(val);
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        fetch(`${restUrl}/v1/samsung/validate/${value}?tenant=${tenant}&environment=${environment}`, {
            method: "get",
            credentials: "include",
        })
            .then((response) => {
            if (response.ok) {
                setIsPageProtected(false);
                setVoucherCode(value);
            }
            return response.json();
        })
            .catch((response) => {
            setIsValid(false);
            // eslint-disable-next-line no-console
            console.log(response);
        });
    };
    return (_jsx("form", { onSubmit: handleSubmit, children: _jsxs(Box, { p: [4, 4, 4, 4, 0], children: [headline && _jsx(Headline, { variant: "h2", children: headline }), disclaimerText && _jsx(Text, { children: disclaimerText }), _jsxs(Flex, { alignItems: ["normal", "normal", "normal", "end", "end"], justifyContent: "space-around", flexDirection: ["column", "column", "column", "row", "row"], children: [_jsx(Box, { mt: 4, flexBasis: "50%", children: _jsx(InputText, { id: label, width: "100%", label: label, maxLength: maxLength, onKeyPress: onKeyPress, onChange: handleChange, layout: isValid ? "normal" : "error", message: !isValid ? errorMessage : "", value: value }) }), _jsx(Button, { size: "small", mt: 4, type: "submit", children: buttonText })] }), _jsx(Box, { my: 6, children: _jsxs(Text, { children: ["Du kannst dich nicht authentifizieren? Weiter zur", " ", _jsx("a", { href: "/", children: " Startseite" })] }) })] }) }));
};
export default ProtectedForm;
