import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import { Box } from "@mm/ui";
import { LoadingOverlay, PageHeader, PageFooter } from "@/components/organisms";
import { InternetServiceProvider, InternetFilter, InternetHero, InternetTeaserRegards, InternetInfoBox, InternetOfferGroupsTeaser, } from "@/sections";
import { StandardPageTemplate } from "@/components/templates";
import { usePage } from "@/utils/fetchData";
import { Loading } from "@/components/molecules";
import { InternetProvider } from "@/features/Internet";
import PageSections from "@/pages/PageSections";
import { useLocation } from "react-router-dom";
import dataLayer from "@/helper/dataLayer";
const InternetPage = () => {
    const { data: page } = usePage();
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    useEffect(() => {
        if (page) {
            dataLayer({
                pageType: "Landingpage",
                category: "DSL",
            });
        }
    }, [page]);
    if (!page)
        return _jsx(Loading, { variant: "home" });
    return (_jsx(InternetProvider, { page: page, children: _jsx(StandardPageTemplate, { header: _jsx(PageHeader, { itemCount: 1 }), sections: [
                // eslint-disable-next-line react/jsx-key
                _jsx(InternetHero, {}),
                // eslint-disable-next-line react/jsx-key
                _jsx(InternetTeaserRegards, {}),
                page.internet.serviceProvider.length === 0 && (
                // eslint-disable-next-line react/jsx-key
                _jsx(InternetServiceProvider, {})),
                // eslint-disable-next-line react/jsx-key
                page.name === "Vodafone" && _jsx(InternetInfoBox, {}),
                // eslint-disable-next-line react/jsx-key
                _jsx(InternetFilter, {}),
                // eslint-disable-next-line react/jsx-key
                _jsx(InternetOfferGroupsTeaser, {}),
                // eslint-disable-next-line react/jsx-key
                _jsx(Box, { mb: 4 }),
                // eslint-disable-next-line react/jsx-key
                _jsx(PageSections, { sections: page.sections, variant: page.siteType }),
                // eslint-disable-next-line react/jsx-key
                _jsx(LoadingOverlay, {}),
            ], footer: _jsx(PageFooter, {}) }) }));
};
export default InternetPage;
