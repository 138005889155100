import React, { useState, useEffect, useReducer, useRef } from "react";
import PropTypes from "prop-types";
import { QueryClient } from "react-query";

import { Box, Headline, Text, Button, InputText, Flex, Image } from "@mm/ui";

import { OfferProvider } from "features/Offer";

import { OfferTeaserEnergy, EnergyInfoBox } from "components/organisms";

import { Loading } from "components/molecules";

import { useEnergyTariffs } from "utils/fetchData";
import { useInternetCitiesPerZipCode } from "@/features/Internet";

import dataLayer from "helper/dataLayer";

import {
  BackgroundSurface,
  ContentColor,
  FormSurface,
  FlexWrapper,
  FlexContainer,
  InnerWrapper,
  ImageWrapper,
  OptionList,
  StyledWrapper,
  StyledCol,
  StyledOfferTeaserEnergy,
  StyledRadioWrapper,
  StyledInputText,
} from "./styledComponents";

import ConsumptionBtn from "./ConsumptionBtn";

const energyHeroConfig = {
  content: {
    flexDirection: ["column", "column", "row", "row", "row"],
    colSizeRight: ["100%", "100%", "50%", "50%", "50%"],
    colSizeLeft: ["100%", "100%", "50%", "50%", "50%"],
  },
  btnWrapper: {
    variant: ["wrap", "wrap", "wrap", "wrap", "nowrap"],
    btnContainer: ["shrink", "shrink", "noShrink", "noShrink", "noShrink"],
  },
  inputWrapper: {
    variant: ["full", "full", "full", "half", "half"],
    inputText: ["full", "full", "full", "half", "half"],
  },
};

const initialState = {
  city: {
    filteredOptions: [],
    label: "Stadt",
    layout: "normal",
    open: false,
    options: [],
    value: "",
  },
  consumption: {
    label: "Verbrauch kWh",
    layout: "normal",
    value: "",
    message: "",
  },
  energyType: {
    value: "strom",
    message: "",
  },
  energyVoucher: {
    label: "Aktionscode",
    layout: "normal",
    value: "",
    message: "",
  },
  zip: {
    label: "PLZ",
    layout: "normal",
    value: "",
    previousZip: "",
  },
};

const EnergyHero = (props) => {
  const {
    data: {
      active,
      color,
      intro,
      promoCode: displayPromoCode,
      headline,
      content,
      backgroundImg,
    },
  } = props;

  if (!active) {
    return null;
  }

  const [energyTariffs, setEnergyTariffs] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const queryClient = new QueryClient();

  const formReducer = (state, action) => {
    switch (action.type) {
      case "energyType": {
        return {
          ...state,
          energyType: action.value,
        };
      }

      case "consumption": {
        return {
          ...state,
          consumption: handleNumberInput(
            state.consumption,
            action.value,
            "Bitte geben Sie Ihren Verbrauch an."
          ),
        };
      }

      case "zip": {
        return {
          ...state,
          zip: handleNumberInput(
            state.zip,
            action.value,
            "Bitte geben Sie Ihre PLZ ein."
          ),
        };
      }

      case "setPreviousZip": {
        return {
          ...state,
          previousZip: action.value,
        };
      }

      case "city": {
        return {
          ...state,
          city: handleSelectInput(
            state.city,
            action.payload.value,
            action.payload.message,
            action.payload.open
          ),
        };
      }

      case "cityOptions": {
        return {
          ...state,
          city: {
            ...state.city,
            options: action.payload,
          },
        };
      }

      case "promoCode": {
        return {
          ...state,
          energyVoucher: handleNumberInput(
            state.energyVoucher,
            action.value,
            "Bitte geben Sie einen Aktionscode ein."
          ),
        };
      }

      default:
        throw new Error(`Reducer action not set: ${action.type}`);
    }
  };

  const [state, dispatch] = useReducer(formReducer, initialState);
  const { city, consumption, energyType, energyVoucher, zip } = state;

  const { data: citiesPerZipCode } = useInternetCitiesPerZipCode(zip.value, {
    enabled: zip.value.length === 5,
  });

  const findPromoCodeFromUrlParam = (url) => {
    const paramsString = url;
    const code = paramsString.split("promo=")[1];
    if (!code) {
      return false;
    }
    return code;
  };

  function handleNumberInput(stateObject, value, message) {
    let object = stateObject;
    if (!/^[0-9]*$/.test(value)) {
      object = {
        value: "",
        message: "Es sind nur Zahlen erlaubt.",
        layout: "error",
      };
      return object;
    }
    if (!value) {
      object = {
        value: "",
        message,
        layout: "error",
      };
      return object;
    }
    object = {
      value,
      message: "",
      layout: "normal",
    };
    return object;
  }

  function handleSelectInput(stateObject, value, message, open) {
    const regex = /[\w\säöüÄÖÜß\-.]+/g;

    if (!/^[a-z,A-Z,0-9,\-,., ,Ä-ä,Ü-ü,Ö-ö]*$/.test(value)) {
      return {
        ...stateObject,
        message: "Es sind nur Buchstaben erlaubt.",
        layout: "error",
        open,
      };
    }

    if (!value) {
      return {
        ...stateObject,
        value: "",
        message,
        layout: "normal",
        filteredOptions: stateObject.options,
        open,
      };
    }

    if (
      value.match(regex) &&
      !stateObject.options.find((a) =>
        a.includes(value.match(regex)?.[0] ?? "")
      )
    ) {
      return {
        ...stateObject,
        value,
        message: `Wir können diese ${stateObject.label} nicht finden`,
        layout: "error",
        filteredOptions: stateObject.options.filter((item) => {
          return item.toLowerCase().includes(value.toLowerCase());
        }),
        open,
      };
    }

    return {
      ...stateObject,
      value,
      message: "",
      layout: "normal",
      filteredOptions: stateObject.options.filter((item) => {
        return item.toLowerCase().includes(value.toLowerCase());
      }),
      open,
    };
  }

  const handleEnergyTypeBtn = (value) => {
    dataLayer({
      eventCategory: `${value}`,
      eventAction: "click",
      eventLabel: `EnergyType:${value}`,
    });
    dispatch({
      type: "energyType",
      value: {
        value,
        message: "",
      },
    });
  };

  const handleConsumptionBtn = (value) => {
    dataLayer({
      eventCategory: `${state.energyType.value}`,
      eventAction: "click",
      eventLabel: `EnergyType:${state.energyType.value}:Consumption:${value}`,
    });
    dispatch({
      type: "consumption",
      value,
    });
  };

  const handleConsumptionInput = (value) => {
    if (value.length >= 4) {
      dataLayer({
        eventCategory: `${state.energyType.value}`,
        eventAction: "input",
        eventLabel: `EnergyType:${state.energyType.value}:Consumption:${value}`,
      });
    }
    dispatch({
      type: "consumption",
      value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!consumption.value) {
      dispatch({
        type: "consumption",
        value: consumption.value,
      });
    }

    if (!zip.value) {
      dispatch({
        type: "zip",
        value: zip.value,
      });
    }

    if (!energyType.value) {
      dispatch({
        type: "energyType",
        value: {
          value: energyType.value,
          message: "Wählen Sie eine Stromart aus.",
        },
      });
    }

    if (consumption.value && zip.value && city.value && energyType.value) {
      setLoading(true);
      useEnergyTariffs(
        energyType.value,
        zip.value,
        city.value,
        consumption.value
      )
        .then((data) => setEnergyTariffs(data.payload))
        .then(() => setLoading(false));
    }
  };

  const btnConfigStrom = [
    {
      type: energyType.value,
      minValue: 1500,
      maxValue: 2499,
      selectedValue: consumption.value,
      onClickHandler: () => handleConsumptionBtn(1500),
    },
    {
      type: energyType.value,
      minValue: 2500,
      maxValue: 3499,
      selectedValue: consumption.value,
      onClickHandler: () => handleConsumptionBtn(2500),
    },
    {
      type: energyType.value,
      minValue: 3500,
      maxValue: 4499,
      selectedValue: consumption.value,
      onClickHandler: () => handleConsumptionBtn(3500),
    },
    {
      type: energyType.value,
      minValue: 4500,
      maxValue: 100000,
      selectedValue: consumption.value,
      onClickHandler: () => handleConsumptionBtn(4500),
    },
  ];

  const btnConfigGas = [
    {
      type: energyType.value,
      minValue: 3500,
      maxValue: 12490,
      selectedValue: consumption.value,
      onClickHandler: () => handleConsumptionBtn(3500),
    },
    {
      type: energyType.value,
      minValue: 12500,
      maxValue: 19999,
      selectedValue: consumption.value,
      onClickHandler: () => handleConsumptionBtn(12500),
    },
    {
      type: energyType.value,
      minValue: 20000,
      maxValue: 27499,
      selectedValue: consumption.value,
      onClickHandler: () => handleConsumptionBtn(20000),
    },
    {
      type: energyType.value,
      minValue: 27500,
      maxValue: 100000,
      selectedValue: consumption.value,
      onClickHandler: () => handleConsumptionBtn(27500),
    },
  ];

  useEffect(() => {
    const getTheUrlCode = findPromoCodeFromUrlParam(window.location.href);
    if (getTheUrlCode) {
      dispatch({
        type: "promoCode",
        value: getTheUrlCode,
      });
    }
    if (citiesPerZipCode) {
      dispatch({
        type: "cityOptions",
        payload: citiesPerZipCode,
      });
    }
  }, [citiesPerZipCode]);

  const cityRef = useRef(null);
  const optionsRef = useRef(null);
  const promoRef = useRef(null);
  const buttonRef = useRef(null);

  const navigateOptionList = (event) => {
    if (event.key === "Tab" && !event.shiftKey && !buttonRef.current.disabled) {
      event.preventDefault();
      buttonRef.current.focus();
      return;
    }
    if (event.key === "Tab" && !event.shiftKey && displayPromoCode) {
      event.preventDefault();
      promoRef.current.focus();
      return;
    }
    if (event.key === "ArrowDown") {
      event.preventDefault();
      optionsRef.current.children[0].focus();
      return;
    }
  };

  const navigateListItems = (event, item, type) => {
    const currentInput = event.target;

    currentInput?.classList.remove("active");
    if (event.key === "Enter") {
      dispatch({
        type: type,
        payload: {
          value: item,
          open: false,
        },
      });
    }
    if (event.key === "ArrowDown") {
      const nextInput = currentInput.nextElementSibling;

      if (nextInput) {
        nextInput.classList.add("active");
        nextInput.focus();
      }
    }
    if (event.key === "ArrowUp") {
      const previousInput = currentInput.previousElementSibling;

      if (previousInput !== null) {
        previousInput.classList.add("active");
        previousInput.focus();
      }
    }
  };

  return (
    <>
      <section className="energy-hero">
        <BackgroundSurface
          variant="wide"
          shape="sharp"
          color="black"
          backgroundImg={backgroundImg}
        >
          <InnerWrapper>
            <Flex flexDirection={energyHeroConfig.content.flexDirection}>
              <Box width={energyHeroConfig.content.colSizeLeft}>
                <ContentColor variant={color}>
                  {intro && <Text variant="smallCopy">{intro}</Text>}

                  {headline && <Headline variant="h1">{headline}</Headline>}

                  <Image
                    src="/img/energy/better-way-logo.svg"
                    alt="Better Way Logo"
                    className="contentColor__logo"
                  />
                  {content && <Text>{content}</Text>}
                </ContentColor>
              </Box>
              <ImageWrapper
                width={energyHeroConfig.content.colSizeRight}
                alignItems="flex-end"
              >
                <div className="imageWrapper__seal">
                  <div className="imageWrapper__text">
                    <p>In Kooperation mit</p>
                  </div>
                  <Image
                    src="/img/energy/E.ON-logo.svg"
                    alt="e.on Logo"
                    className="imageWrapper__logo"
                  />
                  <Image
                    src="/img/energy/eprimo-logo.png"
                    alt="eprimo logo mit text"
                    className="imageWrapper__logo"
                  />
                </div>
              </ImageWrapper>
            </Flex>
          </InnerWrapper>
        </BackgroundSurface>
        <FormSurface variant="narrow" shape="roundedSmall">
          <form onSubmit={handleSubmit} autoComplete="off">
            <StyledRadioWrapper mb={4}>
              <label htmlFor="strom">
                <input
                  type="radio"
                  id="strom"
                  name="energy-type"
                  onClick={() => handleEnergyTypeBtn("strom")}
                  defaultChecked={energyType.value === "strom"}
                />
                Strom
              </label>
              <label htmlFor="gas">
                <input
                  type="radio"
                  id="gas"
                  name="energy-type"
                  onClick={() => handleEnergyTypeBtn("gas")}
                  defaultChecked={energyType.value === "gas"}
                />
                Gas
              </label>
              {energyType.message && (
                <p className="error">{energyType.message}</p>
              )}
            </StyledRadioWrapper>
            <FlexWrapper
              justifyContent="space-between"
              flexWrap={energyHeroConfig.btnWrapper.variant}
            >
              <FlexContainer
                mb={4}
                flexGrow="1"
                variant={energyHeroConfig.btnWrapper.btnContainer}
              >
                {(energyType.value === "strom"
                  ? btnConfigStrom
                  : btnConfigGas
                ).map(
                  (
                    { type, minValue, maxValue, selectedValue, onClickHandler },
                    index
                  ) => (
                    <ConsumptionBtn
                      energyType={type}
                      minValue={minValue}
                      maxValue={maxValue}
                      selectedValue={selectedValue}
                      onClickHandler={onClickHandler}
                      position={index}
                      key={`${type}-${minValue}`}
                    />
                  )
                )}
              </FlexContainer>
              <FlexContainer
                mb={4}
                flexGrow="2"
                variant={energyHeroConfig.inputWrapper.variant}
              >
                <StyledInputText
                  variant={energyHeroConfig.inputWrapper.inputText}
                >
                  <InputText
                    id="consumption"
                    label="Verbrauch kWh"
                    onChange={(value) => handleConsumptionInput(value)}
                    value={consumption.value}
                    message={consumption.message}
                    layout={consumption.layout}
                    maxLength="6"
                    qaName="kWh"
                  />
                </StyledInputText>
                <StyledInputText
                  variant={energyHeroConfig.inputWrapper.inputText}
                >
                  <InputText
                    id="zip"
                    label="PLZ"
                    onChange={(value) => {
                      dispatch({
                        type: "zip",
                        value,
                      });
                      if (value.length === 5) {
                        dispatch({
                          type: "setPreviousZip",
                          value,
                        });
                      }
                      if (value !== zip.previousZip) {
                        dispatch({
                          type: "city",
                          payload: {
                            ...city,
                            value: "",
                            message: "",
                            open: false,
                            options: [],
                          },
                        });
                        queryClient.invalidateQueries([
                          "useInternetCitiesPerZipCode",
                        ]);
                      }
                    }}
                    value={zip.value}
                    message={zip.message}
                    layout={zip.layout}
                    maxLength="5"
                    qaName="zip"
                  />
                </StyledInputText>
                <StyledInputText
                  variant={energyHeroConfig.inputWrapper.inputText}
                >
                  <InputText
                    id="city"
                    ref={cityRef}
                    label="Ort"
                    onChange={(value) => {
                      dispatch({
                        type: "city",
                        payload: {
                          value,
                          open: true,
                        },
                      });
                    }}
                    onFocus={(value) => {
                      if (city.options.length === 0) {
                        dispatch({
                          type: "city",
                          payload: {
                            value,
                            message: `Wir können keine passende ${city.label} finden`,
                            open: false,
                          },
                        });
                        return;
                      }
                      if (city.options.length === 1) {
                        dispatch({
                          type: "city",
                          payload: {
                            value: city.options[0],
                            open: false,
                          },
                        });
                        return;
                      }
                      dispatch({
                        type: "city",
                        payload: {
                          value,
                          message: "Bitte geben Sie Ihre Stadt ein.",
                          open: true,
                        },
                      });
                    }}
                    onKeyDown={(event) => {
                      navigateOptionList(event);
                    }}
                    value={city.value}
                    message={city.message}
                    layout={city.layout}
                    disabled={zip.value.length !== 5}
                  />
                  {city.open && (
                    <OptionList tabIndex={-1} ref={optionsRef}>
                      {city.filteredOptions &&
                        city.value.length !== 0 &&
                        city.filteredOptions.map((item) => (
                          <li
                            tabIndex={0}
                            className="option-list__item"
                            key={item}
                            onClick={() => {
                              dispatch({
                                type: "city",
                                payload: {
                                  value: item,
                                  open: false,
                                },
                              });
                            }}
                            onKeyDown={(event) => {
                              navigateListItems(event, item, "city");
                            }}
                          >
                            {item}
                          </li>
                        ))}
                      {city.value.length === 0 &&
                        city.options.map((item) => (
                          <li
                            tabIndex={0}
                            className="option-list__item"
                            key={item}
                            onClick={() => {
                              dispatch({
                                type: "city",
                                payload: {
                                  value: item,
                                  open: false,
                                },
                              });
                            }}
                            onKeyDown={(event) => {
                              navigateListItems(event, item, "city");
                            }}
                          >
                            {item}
                          </li>
                        ))}
                    </OptionList>
                  )}
                </StyledInputText>
                {displayPromoCode && (
                  <StyledInputText
                    variant={energyHeroConfig.inputWrapper.inputText}
                  >
                    <InputText
                      id="promoCode"
                      innerRef={promoRef}
                      label="Aktionscode"
                      onChange={(value) =>
                        dispatch({
                          type: "promoCode",
                          value,
                        })
                      }
                      value={energyVoucher.value}
                      message={energyVoucher.message}
                      layout={energyVoucher.layout}
                      maxLength="5"
                    />
                  </StyledInputText>
                )}
              </FlexContainer>
              <FlexContainer flexGrow="1" flexBasis="200px">
                <Button
                  ref={buttonRef}
                  type="submit"
                  data-qa="calculate"
                  disabled={!city.options.includes(city?.value)}
                  loading={isLoading}
                  onClick={() =>
                    dataLayer({
                      eventCategory: `${state.energyType.value}`,
                      eventAction: "input",
                      eventLabel: `EnergyType:${
                        state.energyType.value
                      }:Action:${
                        energyTariffs ? "Aktualisieren" : "Jetzt berechnen"
                      }`,
                    })
                  }
                  onFocus={() => {
                    dispatch({
                      type: "city",
                      payload: {
                        ...city,
                        open: false,
                      },
                    });
                  }}
                >
                  {energyTariffs ? "Aktualisieren" : "Jetzt berechnen"}
                </Button>
              </FlexContainer>
            </FlexWrapper>
          </form>
        </FormSurface>
      </section>
      <section className="energy-teaser">
        <StyledWrapper variant="narrow">
          <StyledCol mt={4} isLoading={isLoading}>
            <>
              {energyTariffs?.length === 0 && !isLoading && (
                <Box p={4}>
                  <p>
                    Wir können Ihnen gerade keine Tarife zu diesem Produkt
                    anbieten.
                  </p>
                </Box>
              )}
              {isLoading && <Loading variant="energyContent" width="100%" />}
              {!isLoading &&
                energyTariffs?.map((item) => {
                  return (
                    <StyledOfferTeaserEnergy key={`${item.offerId}-provider`}>
                      <OfferProvider
                        isTariff
                        isProductDetailInterface
                        rootInfoData={item}
                      >
                        <OfferTeaserEnergy />
                      </OfferProvider>
                    </StyledOfferTeaserEnergy>
                  );
                })}
            </>
          </StyledCol>
        </StyledWrapper>
      </section>
      <section className="energy-infobox">
        <StyledWrapper variant="narrow" shape="roundedSmall" mt={4}>
          <EnergyInfoBox />
        </StyledWrapper>
      </section>
    </>
  );
};

EnergyHero.defaultProps = {
  promoCode: false,
  color: "black",
  intro: undefined,
  headline: undefined,
  content: undefined,
  backgroundImg: "https://via.placeholder.com/1440x900/DF0000",
  providerImg: "https://via.placeholder.com/150x150/000000",
};

EnergyHero.propTypes = {
  promoCode: PropTypes.bool,
  color: PropTypes.oneOf(["black", "white"]),
  intro: PropTypes.string,
  headline: PropTypes.string,
  content: PropTypes.string,
  backgroundImg: PropTypes.string,
  providerImg: PropTypes.string,
};

export default EnergyHero;
