import { BankAccountSection } from "./BankAccountSection";
import { BuildingTypeSection } from "./o2/BuildingTypeSection";
import { ConnectionActivationSection as EinsUndEinsConnectionActivationSection } from "./1und1/ConnectionActivationSection";
import { ConnectionAddressSection } from "./ConnectionAddressSection";
import { ConnectionOverviewSection as EinsUndEinsConnectionOverviewSection } from "./1und1/ConnectionOverviewSection";
import { ConnectionOverviewSection as O2ConnectionOverviewSection } from "./o2/ConnectionOverviewSection";
import { ConnectionOverviewSection as VodafoneConnectionOverviewSection } from "./vodafone/ConnectionOverviewSection";
import { ContactAddressSection } from "./ContactAddressSection";
import { ContactOverviewSection as EinsUndEinsContactOverviewSection } from "./1und1/ContactOverviewSection";
import { ContactOverviewSection as O2ContactOverviewSection } from "./o2/ContactOverviewSection";
import { ContactOverviewSection as VodafoneContactOverviewSection } from "./vodafone/ContactOverviewSection";
import { ContactOverviewSection as WaipuContactOverviewSection } from "./waipu/ContactOverviewSection";
import { ContractSummarySection as EinsUndEinsContractSummarySection } from "./1und1/ContractSummarySection";
import { ContractSummarySection as O2ContractSummarySection } from "./o2/ContractSummarySection";
import { ContractSummarySection as VodafoneContractSummarySection } from "./vodafone/ContractSummarySection";
import { ContractOverviewSection as EinsUndEinsContractOverviewSection } from "./1und1/ContractOverviewSection";
import { ContractOverviewSection as O2ContractOverviewSection } from "./o2/ContractOverviewSection";
import { ContractOverviewSection as VodafoneContractOverviewSection } from "./vodafone/ContractOverviewSection";
import { ActivationDateSection as O2ActivationDateSection } from "./o2/ActivationDateSection";
import { ActivationDateSection as VodafoneActivationDateSection } from "./vodafone/ActivationDateSection";
import { ExistingCustomerToggleSection } from "./ExistingCustomerToggleSection";
import { InstallationTimeWindowSection } from "./o2/InstallationTimeWindowSection";
import { ItemisedBillingSection } from "./o2/ItemisedBillingSection";
import { NumberPortabilityInfoSection } from "./NumberPortabilityInfoSection";
import { NumberPortingSection as EinsUndEinsNumberPortingSection } from "./1und1/NumberPortingSection";
import { PaymentOverviewSection as O2PaymentOverviewSection } from "./o2/PaymentOverviewSection";
import { PaymentOverviewSection as VodafonePaymentOverviewSection } from "./vodafone/PaymentOverviewSection";
import { PaymentOverviewSection as WaipuPaymentOverviewSection } from "./waipu/PaymentOverviewSection";
import { PersonalCustomerCodeSection } from "./o2/PersonalCustomerCodeSection";
import { PhoneNumberAmountSection } from "./o2/PhoneNumberAmountSection";
import { PhoneSubscriberSection } from "./PhoneSubscriberSection";
import { PromotionCodeSection } from "./PromotionCodeSection";
import { ProviderChangeSection as EinsUndEinsProviderChangeSection } from "./1und1/ProviderChangeSection";
import { ProviderChangeSection as O2ProviderChangeSection } from "./o2/ProviderChangeSection";
import { ContractTerminationSection as EinsUndEinsContractTerminationSection } from "./1und1/ContractTerminationSection";
import { ShippingAddressSection } from "./ShippingAddressSection";
export * from "./BankAccountSection";
export * from "./o2/BuildingTypeSection";
export * from "./ConnectionAddressSection";
export { ConnectionOverviewSection as O2ConnectionOverviewSection } from "./o2/ConnectionOverviewSection";
export { ConnectionOverviewSection as VodafoneConnectionOverviewSection } from "./vodafone/ConnectionOverviewSection";
export * from "./ContactAddressSection";
export { ContactOverviewSection as EinsUndEinsContactOverviewSection } from "./1und1/ContactOverviewSection";
export { ContactOverviewSection as O2ContactOverviewSection } from "./o2/ContactOverviewSection";
export { ContactOverviewSection as VodafoneContactOverviewSection } from "./vodafone/ContactOverviewSection";
export { ContactOverviewSection as WaipuContactOverviewSection } from "./waipu/ContactOverviewSection";
export { ContractOverviewSection as EinsUndEinsContractOverviewSection } from "./1und1/ContractOverviewSection";
export { ContractOverviewSection as O2ContractOverviewSection } from "./o2/ContractOverviewSection";
export { ContractOverviewSection as VodafoneContractOverviewSection } from "./vodafone/ContractOverviewSection";
export { ContractSummarySection as EinsUndEinsContractSummarySection } from "./1und1/ContractSummarySection";
export { ContractSummarySection as O2ContractSummarySection } from "./o2/ContractSummarySection";
export { ContractSummarySection as VodafoneContractSummarySection } from "./vodafone/ContractSummarySection";
export * from "./ExistingCustomerToggleSection";
export * from "./o2/InstallationTimeWindowSection";
export * from "./o2/ItemisedBillingSection";
export * from "./NumberPortabilityInfoSection";
export { NumberPortingSection as EinsUndEinsNumberPortingSection } from "./1und1/NumberPortingSection";
export { PaymentOverviewSection as O2PaymentOverviewSection } from "./o2/PaymentOverviewSection";
export { PaymentOverviewSection as VodafonePaymentOverviewSection } from "./vodafone/PaymentOverviewSection";
export { PaymentOverviewSection as WaipuPaymentOverviewSection } from "./waipu/PaymentOverviewSection";
export * from "./o2/PersonalCustomerCodeSection";
export * from "./o2/PhoneNumberAmountSection";
export * from "./PhoneSubscriberSection";
export * from "./PromotionCodeSection";
export { ProviderChangeSection as EinsUndEinsProviderChangeSection } from "./1und1/ProviderChangeSection";
export { ProviderChangeSection as O2ProviderChangeSection } from "./o2/ProviderChangeSection";
export * from "./ShippingAddressSection";
export { ActivationDateSection as O2ActivationDateSection } from "./o2/ActivationDateSection";
export { ActivationDateSection as VodafoneActivationDateSection } from "./vodafone/ActivationDateSection";
// TODO: refactor - use same structure for all sections that are only used with one provider
export const sectionComponents = {
    bankAccountSection: BankAccountSection,
    "o2.buildingTypeSection": BuildingTypeSection,
    "1und1.connectionActivationSection": EinsUndEinsConnectionActivationSection,
    connectionAddressSection: ConnectionAddressSection,
    "1und1.connectionOverviewSection": EinsUndEinsConnectionOverviewSection,
    "o2.connectionOverviewSection": O2ConnectionOverviewSection,
    "vodafone.connectionOverviewSection": VodafoneConnectionOverviewSection,
    contactAddressSection: ContactAddressSection,
    "1und1.contactOverviewSection": EinsUndEinsContactOverviewSection,
    "o2.contactOverviewSection": O2ContactOverviewSection,
    "vodafone.contactOverviewSection": VodafoneContactOverviewSection,
    "waipu.contactOverviewSection": WaipuContactOverviewSection,
    "1und1.contractSummarySection": EinsUndEinsContractSummarySection,
    "o2.contractSummarySection": O2ContractSummarySection,
    "vodafone.contractSummarySection": VodafoneContractSummarySection,
    "1und1.contractOverviewSection": EinsUndEinsContractOverviewSection,
    "o2.contractOverviewSection": O2ContractOverviewSection,
    "vodafone.contractOverviewSection": VodafoneContractOverviewSection,
    "o2.activationDateSection": O2ActivationDateSection,
    "vodafone.activationDateSection": VodafoneActivationDateSection,
    existingCustomerToggleSection: ExistingCustomerToggleSection,
    "o2.installationTimeWindowSection": InstallationTimeWindowSection,
    "o2.itemisedBillingSection": ItemisedBillingSection,
    numberPortabilityInfoSection: NumberPortabilityInfoSection,
    "1und1.numberPortingSection": EinsUndEinsNumberPortingSection,
    // why "1und1.paymentOverviewSection": O2PaymentOverviewSection, -> should be EinsUndEins...
    "1und1.paymentOverviewSection": O2PaymentOverviewSection,
    "o2.paymentOverviewSection": O2PaymentOverviewSection,
    "vodafone.paymentOverviewSection": VodafonePaymentOverviewSection,
    "waipu.paymentOverviewSection": WaipuPaymentOverviewSection,
    "o2.personalCustomerCodeSection": PersonalCustomerCodeSection,
    "o2.phoneNumberAmountSection": PhoneNumberAmountSection,
    phoneSubscriberSection: PhoneSubscriberSection,
    promotionCodeSection: PromotionCodeSection,
    "1und1.providerChangeSection": EinsUndEinsProviderChangeSection,
    "o2.providerChangeSection": O2ProviderChangeSection,
    "1und1.contractTerminationSection": EinsUndEinsContractTerminationSection,
    shippingAddressSection: ShippingAddressSection,
};
