import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { AGBModalContent, ContractPartnerLabel, GiftcardDisplay, LegalNoteAsterisk, MetaLink, } from "@/components/atoms";
import { formatNumberAsCurrencyString } from "@/utils/price/formatNumberAsCurrencyString";
import { provideIsLoadingProp, useLoadingState, } from "@/utils/context/LoadingContext";
import { Button } from "@mm/ui";
import { ACTIONS, ACTIONS as ModalActions, useModal, } from "@/utils/context/ModalContext";
import { THEME } from "@core/theme";
import { extendURLParams } from "@/utils/fetchData";
import Arrow from "@core/svgs/arrow.svg";
import dataLayer from "@/helper/dataLayer";
import Datasecurity from "@/components/molecules/MetaNavigation/datasecurity";
import { getConfigByCheckoutType } from "./config";
const { skeletonBase } = THEME;
const StyledOfferSummary = styled.div `
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey[7]};
  padding: 0.875rem 1.25rem 1.5rem;
  border-radius: 4px;

  ${({ disabled }) => disabled && "opacity: 0.5"};

  @media (max-width: 760px) {
    padding: 0;
    border: 0;
    border-radius: 0;
  }
`;
const Headline = styled.h2.attrs(provideIsLoadingProp) `
  margin-top: 0;
  margin-bottom: 0.25rem;
  ${({ isLoading }) => isLoading && skeletonBase + "height: 2rem;"}
`;
const MetaLinks = styled.div `
  margin-top: auto;
  padding-top: 0.5rem;
  display: flex;
  gap: 0.5rem;

  @media (max-width: 760px) {
    display: none;
  }
`;
const Section = styled.section `
  padding-bottom: ${({ divideAfter }) => (divideAfter ? "2rem" : "0")};
  border-bottom: ${({ divideAfter, theme }) => divideAfter ? "1px solid " + theme.colors.grey[7] : "0"};
  margin-bottom: ${({ divideAfter }) => (divideAfter ? "2rem" : "0")};
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 760px) {
    padding: 0;
    border: 0;
    margin-bottom: 1rem;
    ${({ noMarginForMobile, noMargin }) => (noMarginForMobile || noMargin) && "margin: 0;"};
    ${({ hideForMobile, hide }) => (hideForMobile || hide) && "display: none;"};
  }
`;
const PriceList = styled.ul `
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  @media (max-width: 760px) {
    gap: 0.5rem;

    ${({ inline }) => inline &&
    `
        justify-content: space-between;
        flex-direction: row;
    `}
  }
`;
const PriceListItemLabel = styled.div.attrs(provideIsLoadingProp) `
  ${({ isLoading }) => isLoading && skeletonBase}
`;
const PriceListItemPrice = styled.span.attrs(provideIsLoadingProp) `
  ${({ isLoading }) => isLoading && skeletonBase};

  display: flex;

  svg {
    ${({ isLoading }) => isLoading && "display: none;"};
  }
`;
const PriceListItem = styled.li.attrs(provideIsLoadingProp) `
  font-size: ${({ large }) => (large ? "1.25rem" : "1rem")};
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${PriceListItemLabel}, ${PriceListItemPrice} {
    ${({ isLoading, large }) => isLoading && large && "height: 1.5rem;"}
  }

  @media (max-width: 760px) {
    ${({ inline }) => inline &&
    `
            gap: 2rem;
        `};
  }
`;
const PriceListItemInterval = styled.span `
  font-weight: normal;
  margin-top: 0.5rem;
  font-size: 0.875rem;
`;
const HardwareItem = styled.div `
  display: flex;
  align-items: center;
  margin-top: 1rem;

  @media (max-width: 760px) {
    display: none;
  }
`;
const TariffItem = styled.div `
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;

  @media (max-width: 760px) {
    display: none;
  }
`;
const ItemImageWrapper = styled.div.attrs(provideIsLoadingProp) `
  flex: 0 0 7rem;
  width: 7rem;
  max-height: 7rem;
  margin-right: 1rem;

  ${({ isLoading }) => isLoading && skeletonBase + "height: 6rem; width: 7rem;"}
`;
const ItemImage = styled.img.attrs(provideIsLoadingProp) `
  display: block;
  width: 100%;
  height: auto;
  max-height: 9rem;
  object-fit: cover;
  object-position: center;

  ${({ isLoading }) => isLoading && "display: none;"}

  &:before {
    content: "";
    padding-top: 6rem;
  }

  @media (max-width: 760px) {
    width: 100%;
    max-height: 8rem;
  }
`;
const HardwareItemDescription = styled.div `
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const HardwareItemLabel = styled.p.attrs(provideIsLoadingProp) `
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0 0 0.5rem;

  ${({ isLoading }) => isLoading && skeletonBase + "height: 1.5rem;"}
`;
const HardwareItemDetails = styled.div.attrs(provideIsLoadingProp) `
  font-size: 0.875rem;
  margin: 0 0 0.5rem;

  ${({ isLoading }) => isLoading &&
    `
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        align-items: flex-start;
    `}
  ${({ isLoading }) => isLoading && "& > * {" + skeletonBase + "}"}
`;
const TariffName = styled.h2.attrs(provideIsLoadingProp) `
  margin-bottom: 0.25rem;
  ${({ isLoading }) => isLoading && skeletonBase + "height: 2rem;"};

  @media (max-width: 760px) {
    display: none;
  }
`;
const ContractTerm = styled.p.attrs(provideIsLoadingProp) `
  ${({ isLoading }) => isLoading && skeletonBase + "height: 1.25rem;"};

  @media (max-width: 760px) {
    display: none;
  }
`;
const ButtonWrapper = styled.div `
  width: 100%;
  display: flex;
  margin-top: 1.5rem;

  > * {
    flex: 1;
  }
`;
const ContractPartnerLabelWrapper = styled.div `
  @media (max-width: 760px) {
    display: none;
  }
`;
const ToggleButton = styled.button `
  position: absolute;
  background: #fff;
  top: -1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: flex-end;
  margin: 0 auto;
  font-size: 0.75rem;
  left: 50%;
  transform: translate(-50%, 0);
  border: 0;
  outline: 0;
  border-top: 1px solid transparent;
  padding: 0 2rem;
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
  box-shadow: 0px -4px 12px 0px rgba(0, 0, 0, 0.05);
  cursor: pointer;

  @media (min-width: 761px) {
    display: none;
  }
`;
const ShowOnDesktop = styled.span `
  @media (max-width: 760px) {
    display: none;
  }
`;
const ShowOnMobile = styled.span `
  @media (min-width: 761px) {
    display: none;
  }
`;
const ToggleButtonSpan = styled.span `
  display: flex;
  align-items: flex-end;
`;
const ToggleButtonIcon = styled.div `
  margin-left: 0.25rem;
  ${({ open }) => open && "transform: rotate(180deg);"};

  svg {
    height: 0.6125rem;
  }
`;
const LegalNoteContainer = styled.div `
  margin-top: 2rem;
  & > p {
    margin-top: 1rem;
  }
  & > p:first-of-type {
    margin-top: 0;
  }
`;
export const OfferSummary = ({ coupon, disabled, legalNote, onSubmit, submitDisabled, submitLabel, summary, type, }) => {
    const { state: { isLoading }, } = useLoadingState();
    const [minimized, setMinimized] = useState(true);
    const { dispatch } = useModal();
    const { tenant, cdnUrl } = extendURLParams();
    const currentConfig = getConfigByCheckoutType(type);
    useEffect(() => {
        if (currentConfig?.forceExpandedSummary) {
            setMinimized(false);
        }
    }, [currentConfig]);
    const metaLinks = (typeof currentConfig?.metaLinks === "function"
        ? currentConfig.metaLinks(dispatch)
        : currentConfig?.metaLinks) ?? [];
    // TODO: get from process.env
    const imageUriPrefix = "https://content.ekontor24.net/mediamarkt";
    if (!summary) {
        return _jsx(_Fragment, {});
    }
    const { tariff, hardware, accessories: baseAccessories } = summary;
    const accessories = baseAccessories.filter(({ type }) => type === "bonus") ?? [];
    const pkAccessories = baseAccessories.filter(({ type }) => type === "pk") ?? [];
    return (_jsxs(_Fragment, { children: [_jsxs(StyledOfferSummary, { disabled: disabled, children: [!currentConfig?.forceExpandedSummary && (_jsx(ToggleButton, { onClick: () => setMinimized(!minimized), children: _jsxs(ToggleButtonSpan, { children: ["Bestell\u00FCbersicht", _jsx(ToggleButtonIcon, { open: !minimized, children: _jsx(Arrow, {}) })] }) })), _jsxs(Section, { divideAfter: true, noMarginForMobile: true, hide: minimized, children: [_jsx(Headline, { children: currentConfig?.tariffHeadline }), _jsx(ContractPartnerLabelWrapper, { children: _jsx(ContractPartnerLabel, { contractPartner: typeof currentConfig?.contractPartner === "function"
                                        ? currentConfig.contractPartner(tariff)
                                        : currentConfig?.contractPartner, onClick: () => dispatch({
                                        type: ACTIONS.SET_MODAL,
                                        payload: {
                                            type: "contractPartner",
                                            title: "Vertragspartner",
                                            content: tariff?.serviceProvider ? (tariff.info ? (_jsx("div", { dangerouslySetInnerHTML: {
                                                    __html: (typeof currentConfig?.contractPartnerModalContent ===
                                                        "function"
                                                        ? currentConfig.contractPartnerModalContent(tariff)
                                                        : currentConfig?.contractPartnerModalContent) ??
                                                        "",
                                                } })) : (_jsx("div", { dangerouslySetInnerHTML: {
                                                    __html: (typeof currentConfig?.contractPartnerModalContent ===
                                                        "function"
                                                        ? currentConfig.contractPartnerModalContent(tariff)
                                                        : currentConfig?.contractPartnerModalContent) ??
                                                        "",
                                                } }))) : undefined,
                                        },
                                    }) }) }), _jsx(TariffName, { children: tariff?.name }), tariff?.images && (_jsx(TariffItem, { children: _jsx(ItemImageWrapper, { children: _jsx(ItemImage, { src: `${imageUriPrefix}${(tariff?.images?.[0].png ?? tariff?.images?.[0].webp)
                                            ?.medium}`, alt: tariff?.name }) }) })), _jsxs(ContractTerm, { children: ["Mindestvertragslaufzeit ", tariff.contractTerm, " ", tariff.contractTerm > 1 ? "Monate" : "Monat"] }), metaLinks && (_jsx(MetaLinks, { children: metaLinks.map(({ urlKey, ...props }, index) => {
                                    const url = urlKey
                                        ? tariff[urlKey]
                                        : props.url;
                                    return (_jsx(MetaLink, { ...props, details: tariff.details, url: url }, `metaLinks-${index}`));
                                }) }))] }), _jsx(Section, { divideAfter: Boolean(hardware) ||
                            accessories.length > 0 ||
                            pkAccessories.length > 0 ||
                            currentConfig?.showTotalPrice, hide: minimized, children: _jsxs(PriceList, { children: [currentConfig?.showConnectionFee && (_jsxs(PriceListItem, { children: [_jsx(PriceListItemLabel, { children: "Einmaliger Anschlusspreis" }), _jsxs(PriceListItemPrice, { children: [formatNumberAsCurrencyString(tariff.connectionFee), tariff.legalNote && (_jsx(LegalNoteAsterisk, { legalNote: tariff.legalNote }))] })] })), _jsxs(PriceListItem, { children: [_jsxs(PriceListItemLabel, { children: ["Monatlicher Grundpreis", tariff.pricingIntervals?.intervals?.length > 1 && (_jsxs(_Fragment, { children: [_jsx("br", {}), _jsxs(PriceListItemInterval, { children: ["ab dem ", tariff.pricingIntervals.intervals[1]?.startMonth, ". Monat", " ", formatNumberAsCurrencyString(tariff.pricingIntervals.intervals[1]?.price)] })] })), !(tariff.pricingIntervals?.intervals?.length > 1) &&
                                                    tariff.monthlyPriceAddendum && (_jsxs(_Fragment, { children: [_jsx("br", {}), _jsx(PriceListItemInterval, { children: tariff.monthlyPriceAddendum })] }))] }), _jsxs(PriceListItemPrice, { children: [formatNumberAsCurrencyString(tariff.pricingIntervals?.intervals?.[0]?.price ??
                                                    tariff.monthlyPrice), tariff.legalNote && (_jsx(LegalNoteAsterisk, { legalNote: tariff.legalNote }))] })] })] }) }), hardware && (_jsxs(_Fragment, { children: [_jsxs(Section, { divideAfter: true, noMarginForMobile: true, hide: minimized, children: [_jsx(Headline, { children: currentConfig?.hardwareHeadline }), _jsxs(HardwareItem, { children: [_jsx(ItemImageWrapper, { children: _jsx(ItemImage, { src: `${imageUriPrefix}${(hardware?.extendedVariants?.[0].variant.images?.[0]
                                                        .png ??
                                                        hardware?.extendedVariants?.[0].variant.images?.[0].webp)?.medium}`, alt: hardware?.name }) }), _jsx(HardwareItemDescription, { children: _jsx(HardwareItemLabel, { children: hardware?.name }) })] })] }), _jsx(Section, { divideAfter: true, hide: minimized, children: _jsxs(PriceList, { children: [_jsxs(PriceListItem, { children: [_jsx(PriceListItemLabel, { children: "Monatlicher Grundpreis" }), _jsx(PriceListItemPrice, { children: formatNumberAsCurrencyString(hardware?.extendedVariants?.[0].variant.price) })] }), hardware?.extendedVariants?.[0].variant.deliveryPrice && (_jsxs(PriceListItem, { children: [_jsx(PriceListItemLabel, { children: "Einmalige Versandkosten" }), _jsx(PriceListItemPrice, { children: formatNumberAsCurrencyString(hardware?.extendedVariants?.[0].variant.deliveryPrice) })] }))] }) })] })), accessories.length > 0 && (_jsxs(_Fragment, { children: [_jsxs(Section, { divideAfter: true, noMarginForMobile: true, hide: minimized, children: [_jsxs(Headline, { children: ["Deine ", accessories.length > 1 ? "Zugaben" : "Zugabe"] }), _jsx(ContractPartnerLabelWrapper, { children: _jsx(ContractPartnerLabel, { contractPartner: "S-KON eKontor 24 GmbH", onClick: () => dispatch({
                                                type: ACTIONS.SET_MODAL,
                                                payload: {
                                                    type: "contractPartner",
                                                    title: "Details zum Vertragspartner",
                                                    content: summary?.accessories[0].info ? (_jsx("div", { dangerouslySetInnerHTML: {
                                                            __html: summary?.accessories[0].info,
                                                        } })) : undefined,
                                                },
                                            }) }) }), accessories.map((accessory) => {
                                        const image = (accessory?.extendedVariants?.[0].variant.images?.[0].png ??
                                            accessory?.extendedVariants?.[0].variant.images?.[0].webp)?.medium;
                                        return (_jsxs(HardwareItem, { children: [image && (_jsx(ItemImageWrapper, { children: _jsx(ItemImage, { src: `${imageUriPrefix}${image}`, alt: accessory?.name }) })), _jsxs(HardwareItemDescription, { children: [_jsx(HardwareItemLabel, { children: accessory?.name }), _jsxs(HardwareItemDetails, { children: [_jsx("span", { children: "Erh\u00E4ltlich nach Hochladen der ersten Rechnung auf" }), " ", tenant === "mediamarkt" ? (_jsx("a", { href: "https://vorteile.mediamarkt.de", target: "_blank", rel: "noreferrer", children: "vorteile.mediamarkt.de" })) : (_jsx("a", { href: "https://vorteile.saturn.de", target: "_blank", rel: "noreferrer", children: "vorteile.saturn.de" }))] }), _jsxs(MetaLinks, { children: [_jsx(MetaLink, { details: accessory.details, modalContent: accessory.conditions, text: "Einl\u00F6sebedingungen", onClick: (details, content) => {
                                                                        dispatch({
                                                                            type: ModalActions.SET_MODAL,
                                                                            payload: {
                                                                                title: "Einlösebedingungen",
                                                                                type: "redemptionConditions",
                                                                                content: content ? (_jsx("div", { dangerouslySetInnerHTML: {
                                                                                        __html: content,
                                                                                    } })) : undefined,
                                                                            },
                                                                        });
                                                                        dataLayer({
                                                                            event: "gaEvent",
                                                                            eventCategory: "Details",
                                                                            eventAction: "click",
                                                                            eventLabel: "Summary:Prämie:Einlösebedingungen",
                                                                        });
                                                                    } }), _jsx(MetaLink, { details: accessory.details, text: "Pr\u00E4miendetails", onClick: (details) => {
                                                                        dispatch({
                                                                            type: ModalActions.SET_MODAL,
                                                                            payload: {
                                                                                details,
                                                                                title: "Prämiendetails",
                                                                                type: "accessoryDetails",
                                                                            },
                                                                        });
                                                                        dataLayer({
                                                                            event: "gaEvent",
                                                                            eventCategory: "Details",
                                                                            eventAction: "click",
                                                                            eventLabel: "Summary:Prämie:Prämiendetails",
                                                                        });
                                                                    } })] })] })] }, accessory.id));
                                    })] }), _jsx(Section, { divideAfter: true, hide: minimized, children: _jsx(PriceList, { children: accessories.map((accessory) => (_jsxs(PriceListItem, { children: [_jsx(PriceListItemLabel, { children: "Einmaliger Zugabenpreis" }), _jsx(PriceListItemPrice, { children: formatNumberAsCurrencyString(accessory?.extendedVariants?.[0].variant.price) })] }, accessory.id))) }) })] })), pkAccessories.length > 0 && (_jsxs(_Fragment, { children: [_jsxs(Section, { divideAfter: true, noMarginForMobile: true, hide: minimized, children: [_jsxs(Headline, { children: ["Deine ", pkAccessories.length > 1 ? "Prämien" : "Prämie"] }), _jsx(ContractPartnerLabelWrapper, { children: _jsx(ContractPartnerLabel, { contractPartner: "S-KON eKontor 24 GmbH", onClick: () => dispatch({
                                                type: ACTIONS.SET_MODAL,
                                                payload: {
                                                    type: "contractPartner",
                                                    title: "Details zum Vertragspartner",
                                                    content: pkAccessories[0].info ? (_jsx("div", { dangerouslySetInnerHTML: {
                                                            __html: pkAccessories[0].info,
                                                        } })) : undefined,
                                                },
                                            }) }) }), pkAccessories.map((accessory) => {
                                        const image = (accessory?.extendedVariants?.[0].variant.images?.[0].png ??
                                            accessory?.extendedVariants?.[0].variant.images?.[0].webp)?.medium;
                                        return (_jsxs(HardwareItem, { children: [image && (_jsx(ItemImageWrapper, { children: _jsx(ItemImage, { src: `${imageUriPrefix}${image}`, alt: accessory?.name }) })), _jsxs(HardwareItemDescription, { children: [_jsx(HardwareItemLabel, { children: accessory?.name }), _jsxs(HardwareItemDetails, { children: [_jsx("span", { children: "Erh\u00E4ltlich nach Hochladen der ersten Rechnung auf" }), " ", tenant === "mediamarkt" ? (_jsx("a", { href: "https://vorteile.mediamarkt.de", target: "_blank", rel: "noreferrer", children: "vorteile.mediamarkt.de" })) : (_jsx("a", { href: "https://vorteile.saturn.de", target: "_blank", rel: "noreferrer", children: "vorteile.saturn.de" }))] }), _jsxs(MetaLinks, { children: [_jsx(MetaLink, { details: accessory.details, modalContent: accessory.conditions, text: "Einl\u00F6sebedingungen", onClick: (details, content) => {
                                                                        dispatch({
                                                                            type: ModalActions.SET_MODAL,
                                                                            payload: {
                                                                                title: "Einlösebedingungen",
                                                                                type: "redemptionConditions",
                                                                                content: content ? (_jsx("div", { dangerouslySetInnerHTML: {
                                                                                        __html: content,
                                                                                    } })) : undefined,
                                                                            },
                                                                        });
                                                                        dataLayer({
                                                                            event: "gaEvent",
                                                                            eventCategory: "Details",
                                                                            eventAction: "click",
                                                                            eventLabel: "Summary:Prämie:Einlösebedingungen",
                                                                        });
                                                                    } }), _jsx(MetaLink, { details: accessory.details, text: "Pr\u00E4miendetails", onClick: (details) => {
                                                                        dispatch({
                                                                            type: ModalActions.SET_MODAL,
                                                                            payload: {
                                                                                details,
                                                                                title: "Prämiendetails",
                                                                                type: "accessoryDetails",
                                                                            },
                                                                        });
                                                                        dataLayer({
                                                                            event: "gaEvent",
                                                                            eventCategory: "Details",
                                                                            eventAction: "click",
                                                                            eventLabel: "Summary:Prämie:Prämiendetails",
                                                                        });
                                                                    } })] })] })] }, accessory.id));
                                    })] }), _jsx(Section, { divideAfter: true, hide: minimized, children: _jsx(PriceList, { children: pkAccessories.map((accessory) => (_jsxs(PriceListItem, { children: [_jsx(PriceListItemLabel, { children: "Einmaliger Pr\u00E4mienpreis" }), _jsx(PriceListItemPrice, { children: formatNumberAsCurrencyString(accessory?.extendedVariants?.[0].variant.price) })] }, accessory.id))) }) })] })), currentConfig?.showTotalPrice && (_jsx(Section, { divideAfter: Boolean(coupon), noMargin: minimized, children: _jsxs(PriceList, { inline: minimized, children: [_jsxs(PriceListItem, { large: true, inline: minimized, children: [_jsxs(PriceListItemLabel, { children: [_jsx(ShowOnDesktop, { children: "Einmalige Kosten gesamt" }), _jsx(ShowOnMobile, { children: minimized ? "Einmal." : "Einmalige Kosten gesamt" })] }), _jsxs(PriceListItemPrice, { children: [formatNumberAsCurrencyString(tariff.connectionFee +
                                                    (hardware?.extendedVariants?.[0].variant.deliveryPrice ||
                                                        0) +
                                                    accessories.reduce((sum, accessory) => (sum +=
                                                        accessory?.extendedVariants?.[0].variant.price ||
                                                            0), 0)), tariff.legalNote && (_jsx(LegalNoteAsterisk, { legalNote: tariff.legalNote }))] })] }), _jsxs(PriceListItem, { large: true, inline: minimized, children: [_jsxs(PriceListItemLabel, { children: [_jsx(ShowOnDesktop, { children: "Monatliche Gesamtkosten" }), _jsx(ShowOnMobile, { children: minimized ? "Mtl." : "Monatliche Gesamtkosten" })] }), _jsxs(PriceListItemPrice, { children: [formatNumberAsCurrencyString(tariff.monthlyPrice +
                                                    (hardware?.extendedVariants?.[0].variant.price || 0)), tariff.legalNote && (_jsx(LegalNoteAsterisk, { legalNote: tariff.legalNote }))] })] })] }) })), coupon && (_jsxs(Section, { hideForMobile: true, children: [_jsx(Headline, { children: "Dein Coupon" }), _jsxs(HardwareItem, { children: [_jsx(ItemImageWrapper, { children: _jsx(GiftcardDisplay, { giftcard: {
                                                image: `${cdnUrl}/img/giftcard.png`,
                                                value: coupon.value,
                                            }, giftCardDisplayWidthNonPx: "7rem", giftCardDisplayTop: "0", giftCardClampMaxWidth: true, size: "xs" }) }), _jsxs(HardwareItemDescription, { children: [_jsxs(HardwareItemLabel, { children: [formatNumberAsCurrencyString(coupon.value), " Geschenk-Coupon"] }), coupon.bullets.length > 0 && (_jsx(HardwareItemDetails, { children: _jsx("ul", { children: coupon.bullets.map((bullet, index) => (_jsx("li", { children: bullet }, `coupon-bullet-${index}`))) }) })), _jsx(MetaLinks, { children: _jsx(MetaLink, { details: coupon.details, text: "Details zum Geschenk-Coupon", onClick: (details) => {
                                                        dispatch({
                                                            type: ModalActions.SET_MODAL,
                                                            payload: {
                                                                details,
                                                                meta: {
                                                                    coupon: coupon,
                                                                },
                                                                title: "Details zum Geschenk-Coupon",
                                                                type: "giftCardDetails",
                                                            },
                                                        });
                                                        dataLayer({
                                                            event: "gaEvent",
                                                            eventCategory: "Details",
                                                            eventAction: "click",
                                                            eventLabel: "Summary:Coupoon:Details zum Geschenk-Coupon",
                                                        });
                                                    } }) })] })] })] })), legalNote && (_jsx(LegalNoteContainer, { children: typeof legalNote === "string" ? (_jsx("p", { children: legalNote })) : typeof legalNote === "function" ? (legalNote(tariff.pibUrl ?? "", 
                        // TODO: needs to be rebuild (fetch static data)
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        () => {
                            dispatch({
                                type: ModalActions.SET_MODAL,
                                payload: {
                                    type: "generic",
                                    title: "Allgemeine Geschäftsbedingungen",
                                    content: _jsx(AGBModalContent, {}),
                                },
                            });
                        }, () => {
                            dispatch({
                                type: ModalActions.SET_MODAL,
                                payload: {
                                    type: "generic",
                                    title: "Datenschutzerklärung",
                                    content: _jsx(Datasecurity, {}),
                                },
                            });
                        })) : (legalNote) }))] }), _jsx(ButtonWrapper, { "data-tk-cobrowse-hidden": "true", children: _jsx(Button, { onClick: () => {
                        onSubmit();
                    }, loading: isLoading, disabled: submitDisabled, children: submitLabel ?? "Weiter" }) })] }));
};
