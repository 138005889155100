import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Image } from "@mm/ui";

import { carrierPropTypes, serviceproviderPropTypes } from "sharedPropTypes";
import { CDN_URL } from "helper/globals";

const ProviderLogo = styled("img")`
  display: block;
  width: 80px;
  height: 50px;
`;

const Img = styled(Image)`
  height: inherit;
`;

const TariffBadge = ({ className, sim, tariff }) => {
  const { carrier, serviceProvider } = tariff;

  const tariffDownloadSpeed =
    tariff.properties?.tariff_conditions_download_speed || "";

  const downloadSpeed = (tariffDownloadSpeed || "").split(" Mbit/s")[0];
  let badge;

  switch (serviceProvider) {
    case "o2":
      switch (carrier) {
        case "Super Select":
          badge = "superselect";
          break;
        case "super-select":
          badge = "superselect";
          break;
        case "o2":
        default:
          badge = "o2";
          break;
      }
      break;
    case "o2 cable":
      badge = "o2";
      break;
    // Eutelsat
    case "konnect":
      switch (downloadSpeed) {
        case "30":
          badge = "eutelsat_30";
          break;
        case "50":
          badge = "eutelsat_50";
          break;
        case "100":
          badge = "eutelsat_100";
          break;
        default:
          badge = "SPEED_UNKNOWN";
          break;
      }
      break;
    case "eprimo":
      badge = "eprimo-strom";
      break;
    case "E.ON":
      badge = "E.ON-strom";
      break;
    case "kabel deutschland":
    case "vodafone dsl":
      badge = "Logo-Vodafone";
      break;
    case "klarmobil":
      switch (carrier.toLowerCase()) {
        case "vodafone klarmobil":
          badge = "km-vodafone";
          break;
        case "telekom klarmobil":
          badge = "km-telekom";
          break;
        default:
          badge = "klarmobil";
          break;
      }
      break;
    case "freenet":
    default:
      switch (carrier.toLowerCase()) {
        case "vodafone":
          badge = "md-vodafone";
          break;
        case "telefonica":
          badge = "md-telefonica";
          break;
        case "telekom":
        default:
          badge = "md-telekom";
          break;
      }
      break;
  }

  const Comp = sim ? (
    <Img
      height="100%"
      src={`/img/${carrier ? "sim" : "energy"}/${badge}.png`}
      loading="lazy"
      alt={`${badge} logo`}
    />
  ) : (
    <ProviderLogo
      src={`${CDN_URL}/img/mm-neu/${badge}.svg`}
      className={className}
      alt={`${badge} logo`}
    />
  );

  return Comp;
};

TariffBadge.propTypes = {
  className: PropTypes.string,
  framed: PropTypes.bool,
  sim: PropTypes.bool,
  tariff: PropTypes.shape({
    carrier: carrierPropTypes.isRequired,
    serviceProvider: serviceproviderPropTypes.isRequired,
  }).isRequired,
};

TariffBadge.defaultProps = {
  className: "",
  framed: false,
  sim: false,
};

export default TariffBadge;
