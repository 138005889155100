import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { BorderBox, Checkbox, GeneralModalShell } from "@/components/atoms";
import Datasecurity from "@/components/molecules/MetaNavigation/datasecurity";
import { PageHeader, PageFooter } from "@/components/organisms";
import { StandardPageTemplate } from "@/components/templates";
import { useOrderValidation } from "@/features/SEPAPage";
import { Box, Button, Flex, HeadlineSourceSansPro, Surface, Text, InputText, } from "@mm/ui";
const InlineOpener = styled.span `
  text-decoration: underline;
`;
const FieldRow = styled.div `
  display: grid;
  position: relative;
  grid-template-columns: 1fr;
  margin-bottom: 24px;

  @media (min-width: ${({ theme }) => theme.breakpointsMap.sm.max}px) {
    grid-template-columns: 1fr 1fr;
    gap: 24px;

    &.row02 {
      grid-template-columns: 70% 1fr;
    }

    &.row03 {
      grid-template-columns: 1fr 70%;
    }
  }
`;
function handleNumberInput(stateObject, value, message) {
    let object = stateObject;
    if (!value) {
        object = {
            value: "",
            layout: "error",
            message: message,
            valid: false,
        };
        return object;
    }
    if (!/^[0-9]*$/.test(value)) {
        object = {
            value: value,
            layout: "error",
            message: "Es sind nur Zahlen erlaubt.",
            valid: false,
        };
        return object;
    }
    object = {
        value: value,
        layout: "normal",
        message: "",
        valid: true,
    };
    return object;
}
function handleTextInput(stateObject, value, message) {
    let object = stateObject;
    if (!value) {
        object = {
            value: "",
            layout: "error",
            message: message,
            valid: false,
        };
        return object;
    }
    if (!/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u.test(value)) {
        object = {
            value: value,
            layout: "error",
            message: "Es sind nur Buchstaben erlaubt.",
            valid: false,
        };
        return object;
    }
    object = {
        value: value,
        layout: "normal",
        message: "",
        valid: true,
    };
    return object;
}
function handleMailInput(stateObject, value, message) {
    let object = stateObject;
    if (!/^([\w%.\-_]+@[\w%.-]+\.[a-zA-Z]{2,6})?$/.test(value)) {
        object = {
            value: value,
            layout: "error",
            message: "Bitte prüfe deine E-Mail Adresse.",
            valid: false,
        };
        return object;
    }
    if (!value) {
        object = {
            value: "",
            layout: "error",
            message: message,
            valid: false,
        };
        return object;
    }
    object = {
        value: value,
        layout: "normal",
        message: "",
        valid: true,
    };
    return object;
}
function handleStreetNumberInput(stateObject, value, message) {
    let object = stateObject;
    if (!value) {
        object = {
            value: "",
            layout: "error",
            message: message,
            valid: false,
        };
        return object;
    }
    if (!/^[0-9a-z./-]*$/.test(value)) {
        object = {
            value: value,
            layout: "error",
            message: "Es sind nur bestimmte Zeichen erlaubt.",
            valid: false,
        };
        return object;
    }
    object = {
        value: value,
        layout: "normal",
        message: "",
        valid: true,
    };
    return object;
}
const DataSecurity = () => {
    return (_jsx(Box, { mt: "4", children: _jsxs(Text, { children: ["Mit Absenden des Formulars stimme ich zu, dass meine Informationen im Auftrag von der Media-Saturn Deutschland GmbH von der S-KON eKontor 24 GmbH zur Vervollst\u00E4ndigung meiner Bestellung erhoben werden. Weitere Informationen finde ich in der", " ", _jsx(GeneralModalShell, { size: "xl", openModal: false, opener: _jsx(InlineOpener, { children: "Datenschutzerkl\u00E4rung" }), children: _jsx(Datasecurity, {}) }), "."] }) }));
};
const ThankYouSection = () => {
    return (_jsx(Surface, { variant: "narrow", shape: "roundedSmall", mt: "6", children: _jsxs(BorderBox, { qaName: "qa-thankyou", children: [_jsx(HeadlineSourceSansPro, { size: "30", type: "h1", text: "Vielen Dank f\u00FCr deinen Auftrag!", mt: "0", mb: "4" }), _jsxs(Text, { children: ["Wir haben deine Angaben erfasst. Viel Spa\u00DF mit der Nutzung deines Abos.", _jsx("br", {}), _jsx("br", {}), _jsx("a", { href: "/", children: "zur Startseite" })] })] }) }));
};
const SEPASection = () => {
    const [internalState, setInternalState] = useState({
        orderNo: {
            value: "",
            layout: "normal",
            message: "Bitte prüfe deine Bestellnummer.",
            valid: false,
        },
        mail: {
            value: "",
            layout: "normal",
            message: "Bitte prüfe deine E-Mail Adresse.",
            valid: false,
        },
        firstname: {
            value: "",
            layout: "normal",
            message: "Gib einen gültigen Vornamen ein.",
            valid: false,
        },
        lastname: {
            value: "",
            layout: "normal",
            message: "Gib einen gültigen Nachnamen ein.",
            valid: false,
        },
        street: {
            value: "",
            layout: "normal",
            message: "Gib eine Straße ein.",
            valid: false,
        },
        streetNumber: {
            value: "",
            layout: "normal",
            message: "Gib eine Hausnummer ein.",
            valid: false,
        },
        zip: {
            value: "",
            layout: "normal",
            message: "Gib eine gültige PLZ ein.",
            valid: false,
        },
        city: {
            value: "",
            layout: "normal",
            message: "Gib einen gültigen Ort ein.",
            valid: false,
        },
        iban: {
            value: "",
            layout: "normal",
            message: "Gib eine gültige IBAN ein.",
            valid: false,
        },
        bic: {
            value: "",
            layout: "normal",
            message: "",
            valid: false,
        },
        bank: {
            value: "",
            layout: "normal",
            message: "",
            valid: false,
        },
        sepaAccepted: {
            value: "",
            layout: "normal",
            message: "",
            valid: false,
        },
        channel: {
            value: "",
            layout: "normal",
            message: "",
            valid: false,
        },
        subsidiary: {
            value: "",
            layout: "normal",
            message: "",
            valid: false,
        },
    });
    const [orderIsValid, setOrderIsValid] = useState(false);
    const [orderIsTransmitted, setOrderIsTransmitted] = useState(false);
    const [errors, setErrors] = useState(false);
    const isStep01Valid = internalState.orderNo.valid && internalState.mail.valid ? true : false;
    const { data, refetch } = useOrderValidation({
        orderNo: internalState.orderNo.value,
        mail: internalState.mail.value,
        firstname: internalState.firstname.value,
        lastname: internalState.lastname.value,
        street: internalState.street.value,
        streetNumber: internalState.streetNumber.value,
        zip: internalState.zip.value,
        city: internalState.city.value,
        iban: internalState.iban.value,
        bic: internalState.bic.value,
        bank: internalState.bank.value,
        sepaAccepted: internalState.sepaAccepted.valid,
        channel: internalState.channel.value,
        subsidiary: internalState.subsidiary.value,
    }, { enabled: false });
    function handleStep01(e) {
        e.preventDefault();
        refetch().then((response) => {
            if (response.data?.success) {
                setOrderIsValid(true);
            }
        });
    }
    function handleStep02(e) {
        e.preventDefault();
        refetch().then((response) => {
            if (response.data?.success) {
                setOrderIsTransmitted(true);
            }
        });
    }
    function handleIBANInput(stateObject, value, message) {
        let object = stateObject;
        if (!value) {
            object = {
                value: "",
                layout: "error",
                message: message,
                valid: false,
            };
            return object;
        }
        if (!/^[0-9A-Z]*$/.test(value)) {
            object = {
                value: value,
                layout: "error",
                message: "Es sind nur Zahlen erlaubt.",
                valid: false,
            };
            return object;
        }
        object = {
            value: value,
            layout: "normal",
            message: "",
            valid: true,
        };
        return object;
    }
    function areAllFieldsValid(inputObject) {
        for (const key in inputObject) {
            if (inputObject[key]) {
                if (!inputObject[key].valid) {
                    return false;
                }
            }
        }
        return true;
    }
    useEffect(() => {
        if (data) {
            setInternalState({
                ...internalState,
                firstname: {
                    ...internalState.firstname,
                    value: data.payload.firstname,
                },
                lastname: {
                    ...internalState.lastname,
                    value: data.payload.lastname,
                },
                street: {
                    ...internalState.street,
                    value: data.payload.street,
                },
                streetNumber: {
                    ...internalState.streetNumber,
                    value: data.payload.streetNumber,
                },
                zip: {
                    ...internalState.zip,
                    value: data.payload.zip,
                },
                city: {
                    ...internalState.city,
                    value: data.payload.city,
                },
                iban: {
                    ...internalState.iban,
                    value: data.payload.iban,
                },
                bank: {
                    ...internalState.bank,
                    value: data.payload.bank,
                },
                channel: {
                    ...internalState.iban,
                    value: data.channel,
                },
                subsidiary: {
                    ...internalState.iban,
                    value: data.subsidiary,
                },
            });
            setErrors(data.validationErrors);
        }
    }, [data]);
    useEffect(() => {
        if (internalState.iban.valid) {
            refetch();
        }
        if (!internalState.iban.value) {
            setInternalState({
                ...internalState,
                bank: {
                    ...internalState.bank,
                    value: "",
                },
            });
        }
    }, [internalState.iban.valid]);
    useEffect(() => {
        if (errors) {
            const updatedFormData = { ...internalState };
            Object.entries(errors).map(([path, message]) => {
                updatedFormData[path] = {
                    value: "",
                    layout: "error",
                    message: `${message}`,
                    valid: false,
                };
            });
            setInternalState(updatedFormData);
        }
    }, [errors]);
    return (_jsxs(_Fragment, { children: [!orderIsTransmitted && (_jsxs(Surface, { variant: "narrow", shape: "roundedSmall", mt: "6", children: [_jsxs(Box, { mb: "8", children: [_jsx(HeadlineSourceSansPro, { size: "30", type: "h1", text: "Vielen Dank f\u00FCr deinen Auftrag!", mt: "0", mb: "4" }), _jsxs(Text, { children: ["Zur Finalisierung deiner monatlichen Plusgarantie oder monatlichen Plusschutzes ben\u00F6tigen wir nun noch deine Adress-und Zahlungsdaten zur monatlichen Abbuchung. Bitte beachte, dass wir die Bestellung ansonsten aus rechtlichen Gr\u00FCnden stornieren m\u00FCssen.", _jsx("br", {}), _jsx("br", {}), "Du m\u00F6chtest deine Daten lieber auf anderem Wege an Media Markt oder Saturn senden? Lade dir", " ", _jsx("a", { href: "https://storage.googleapis.com/content-ekontor24/mediamarkt/pdf/msd-sepa-%20lastschrift-mandat.pdf", target: "_blank", rel: "noreferrer", children: "hier" }), " ", "das Formular SEPA Lastschrift-Mandat herunter und sende es ausgef\u00FCllt per Mail an", " ", _jsx("a", { href: "mailto:mm.vertragsservice@mediamarkt.de", children: "mm.vertragsservice@mediamarkt.de" }), "."] })] }), _jsxs(BorderBox, { qaName: "qa-orderNumber", mb: "4", children: [_jsx(HeadlineSourceSansPro, { size: "25px", type: "h2", text: "Auftrag", mt: "0", mb: "0" }), !orderIsValid && (_jsx(Text, { children: "Damit wir deine Angaben zuordnen k\u00F6nnen, gib hier bitte die Bestellnummer deiner Bestellung und die E-Mailadresse, mit der du bestellt hast, ein." })), _jsxs("form", { onSubmit: (e) => handleStep01(e), children: [_jsxs(FieldRow, { children: [_jsx(InputText, { id: "orderNumber", label: "Bestellnummer*", value: internalState.orderNo.value, layout: internalState.orderNo.layout, message: internalState.orderNo.layout === "error"
                                                    ? internalState.orderNo.message
                                                    : "", maxLength: "10", onChange: (value) => setInternalState({
                                                    ...internalState,
                                                    orderNo: handleNumberInput(internalState.orderNo, value, "Bitte prüfe deine Bestellnummer."),
                                                }), disabled: orderIsValid }), _jsx(InputText, { id: "mail", label: "E-Mail Adresse*", value: internalState.mail.value, layout: internalState.mail.layout, message: internalState.mail.layout === "error"
                                                    ? internalState.mail.message
                                                    : "", onChange: (value) => setInternalState({
                                                    ...internalState,
                                                    mail: handleMailInput(internalState.mail, value, "Bitte prüfe deine E-Mail Adresse."),
                                                }), disabled: orderIsValid })] }), !orderIsValid && (_jsxs(_Fragment, { children: [_jsx(DataSecurity, {}), _jsx(Flex, { justifyContent: "end", children: _jsx(Button, { disabled: !isStep01Valid, children: "Weiter" }) })] }))] })] }), orderIsValid && (_jsx("form", { onSubmit: (e) => handleStep02(e), children: _jsxs(BorderBox, { qaName: "qa-customer-infos", children: [_jsx(HeadlineSourceSansPro, { size: "25px", type: "h2", text: "Zahlungsdaten", mt: "0", mb: "0" }), _jsx(Text, { children: "Bitte gib hier die Daten des Zahlungspflichtigen ein." }), _jsxs(FieldRow, { children: [_jsx(InputText, { id: "firstname", label: "Vorname*", value: internalState.firstname.value, layout: internalState.firstname.layout, message: internalState.firstname.layout === "error"
                                                ? internalState.firstname.message
                                                : "", maxLength: "100", onChange: (value) => setInternalState({
                                                ...internalState,
                                                firstname: handleTextInput(internalState.firstname, value, "Bitte prüfe dein Vorname."),
                                            }) }), _jsx(InputText, { id: "lastname", label: "Nachname*", value: internalState.lastname.value, layout: internalState.lastname.layout, message: internalState.lastname.layout === "error"
                                                ? internalState.lastname.message
                                                : "", maxLength: "100", onChange: (value) => setInternalState({
                                                ...internalState,
                                                lastname: handleTextInput(internalState.lastname, value, "Bitte prüfe dein Nachname."),
                                            }) })] }), _jsxs(FieldRow, { className: "row02", children: [_jsx(InputText, { id: "street", label: "Stra\u00DFe*", value: internalState.street.value, layout: internalState.street.layout, message: internalState.street.layout === "error"
                                                ? internalState.street.message
                                                : "", maxLength: "100", onChange: (value) => setInternalState({
                                                ...internalState,
                                                street: handleTextInput(internalState.street, value, "Bitte prüfe deine Straße."),
                                            }) }), _jsx(InputText, { id: "streetNumber", label: "Nummer*", value: internalState.streetNumber.value, layout: internalState.streetNumber.layout, message: internalState.streetNumber.layout === "error"
                                                ? internalState.streetNumber.message
                                                : "", maxLength: "10", onChange: (value) => setInternalState({
                                                ...internalState,
                                                streetNumber: handleStreetNumberInput(internalState.streetNumber, value, "Bitte prüfe deine Hausnummer."),
                                            }) })] }), _jsxs(FieldRow, { className: "row03", children: [_jsx(InputText, { id: "zip", label: "PLZ*", value: internalState.zip.value, layout: internalState.zip.layout, message: internalState.zip.layout === "error"
                                                ? internalState.zip.message
                                                : "", maxLength: "5", onChange: (value) => setInternalState({
                                                ...internalState,
                                                zip: handleNumberInput(internalState.zip, value, "Bitte prüfe deine PLZ."),
                                            }) }), _jsx(InputText, { id: "city", label: "Ort*", value: internalState.city.value, layout: internalState.city.layout, message: internalState.city.layout === "error"
                                                ? internalState.city.message
                                                : "", maxLength: "100", onChange: (value) => setInternalState({
                                                ...internalState,
                                                city: handleTextInput(internalState.city, value, "Bitte prüfe dein Ort."),
                                            }) })] }), _jsxs(FieldRow, { children: [_jsx(InputText, { id: "iban", label: "IBAN*", value: internalState.iban.value, layout: internalState.iban.layout, message: internalState.iban.layout === "error"
                                                ? internalState.iban.message
                                                : "", maxLength: "22", onChange: (value) => setInternalState({
                                                ...internalState,
                                                iban: handleIBANInput(internalState.iban, value, "Bitte prüfe deine IBAN."),
                                            }) }), _jsx(InputText, { id: "bank", label: "Bank", value: internalState.bank.value, disabled: true })] }), _jsx("br", {}), _jsxs(Flex, { onClick: () => setInternalState({
                                        ...internalState,
                                        sepaAccepted: {
                                            ...internalState.sepaAccepted,
                                            valid: !internalState.sepaAccepted.valid,
                                        },
                                    }), children: [_jsx(Box, { flexShrink: "0", mr: "4", children: _jsx(Checkbox, { checked: internalState.sepaAccepted.valid }) }), _jsxs(Text, { children: ["Ich erm\u00E4chtige die Media-Saturn Deutschland GmbH Ingolstadt, Gl\u00E4ubiger-Identifikationsnummer DE21Z0100000642216, Zahlungen von meinem Konto mittels Lastschrift einzuziehen. Zugleich weise ich mein Kreditinstitut an, die von Media-Saturn Deutschland GmbH Ingolstadt auf mein Konto gezogenen Lastschrift einzul\u00F6sen. Hinweis: Ich kann innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrages verlangen. Es gelten dabei die mit meinem Kreditinstitut vereinbarten Bedingungen.", _jsx("br", {}), _jsx("br", {}), "Mir ist bekannt, dass ich f\u00FCr die erforderliche Deckung Sorge tragen muss. Durch R\u00FCckbelastung fallen Geb\u00FChren und Verzugskosten an, die vom Nutzer zu tragen sind."] })] }), _jsx(DataSecurity, {}), _jsx(Flex, { justifyContent: "end", children: _jsx(Button, { disabled: !internalState.sepaAccepted.valid, children: "Absenden" }) })] }) }))] })), orderIsTransmitted && _jsx(ThankYouSection, {})] }));
};
const SEPAPage = () => {
    return (_jsx(StandardPageTemplate, { header: _jsx(PageHeader, { itemCount: 1 }), sections: [_jsx(SEPASection, {}, "SEPASection")], footer: _jsx(PageFooter, {}) }));
};
export default SEPAPage;
