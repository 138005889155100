// TODO: get proper label values from BE
export var ServiceProvider;
(function (ServiceProvider) {
    ServiceProvider["vodafone"] = "vodafone";
    ServiceProvider["o2"] = "o2";
    ServiceProvider["einsundeins"] = "1und1";
    ServiceProvider["waipu"] = "waipu";
})(ServiceProvider || (ServiceProvider = {}));
export const getServiceProviderLabel = (serviceProvider) => {
    switch (serviceProvider) {
        case ServiceProvider.vodafone: {
            return "Vodafone";
        }
        case ServiceProvider.o2: {
            return "o2";
        }
        case ServiceProvider.einsundeins: {
            return "1&1";
        }
        case ServiceProvider.waipu: {
            return "Waipu TV";
        }
        default: {
            throw new Error(`ServiceProvider ${serviceProvider} has no valid label`);
        }
    }
};
