import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { RadioInput } from "@/components/atoms";
import styled from "styled-components";
import { THEME } from "@core/theme";
import { provideIsLoadingProp, } from "@/utils/context/LoadingContext";
const { skeletonBase } = THEME;
const Fieldset = styled.fieldset `
  display: flex;
  gap: 1.25rem;
  border: 0;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;
`;
const ItemWrapper = styled.div `
  position: relative;
  display: flex;
  align-items: center;
`;
const Text = styled.div.attrs(provideIsLoadingProp) `
  margin-left: 0.3125rem;
  display: flex;
  align-items: center;
  ${({ hasDescription }) => hasDescription && "font-weight: bold"};
`;
const Description = styled.span `
  margin-left: 0.3125rem;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.grey[3]};
`;
const Message = styled("div") `
  font-size: ${({ theme }) => theme.typography.size.s}px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.grey[4]};
  margin-top: ${({ theme }) => theme.space.xs}px;
  margin-bottom: ${({ theme }) => theme.space.xs}px;

  ${({ layout, theme }) => {
    switch (layout) {
        case "error":
            return `
                    color: ${theme.colors.brand.primary};
                `;
        default:
            return ``;
    }
}}
`;
const Icon = styled.i `
  cursor: pointer;
  margin-left: 0.25rem;
`;
export const RadioList = ({ checked, disabled, error, id, items, onChange, }) => {
    return (_jsxs(_Fragment, { children: [_jsx(Fieldset, { "data-tk-cobrowse-hidden": "true", children: items.map((item) => (_jsxs(ItemWrapper, { id: id, children: [_jsx(RadioInput, { disabled: disabled, id: item.key, name: id, checked: item.key === checked, onChange: (e) => {
                                onChange(e.target.id);
                            } }), _jsxs(Text, { hasDescription: Boolean(item.description), children: [_jsx("label", { htmlFor: item.key, children: item.label }), item.onClickInfo && (_jsx(Icon, { onClick: (e) => {
                                        e.stopPropagation();
                                        item.onClickInfo && item.onClickInfo(item);
                                    }, className: "icon-MM_iconfont_Info", role: "dialog", "aria-modal": "true", "aria-label": "Informationen zum Wechselservice" })), _jsx(Description, { id: `${item.key}-description`, children: item.description })] })] }, item.key))) }), error && _jsx(Message, { layout: "error", children: error })] }));
};
