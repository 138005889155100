export const config = [
    {
        key: "vodafone",
        steps: [
            {
                type: "connection",
                sections: [
                    {
                        title: "Dein Anschluss",
                        sections: [
                            {
                                component: "connectionAddressSection",
                            },
                        ],
                    },
                ],
            },
        ],
    },
];
