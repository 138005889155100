import { createGlobalStyle } from "styled-components";

import { CDN_URL } from "../constants";

/**
 *  we manually set the font styles because if we use styled-components,
 *  <GlobalStyle /> might be executed multiple times,
 *  causing the fonts to be requested multiple times as well.
 *  This results in flashing fonts on the website
 */

const style = document.createElement("style");

style.textContent = `
  @font-face {
    font-family: 'EurostileNextLTPro';
    font-style: normal;
    font-weight: normal;
    src: url('${CDN_URL}/fonts/sat/EurostilePro-Regular.woff2') format('woff2');
  }

  @font-face {
    font-family: 'EurostileNextLTProBold';
    font-style: normal;
    font-weight: normal;
    src: url('${CDN_URL}/fonts/sat/EurostilePro-Bold.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Source Sans Pro';
    font-stretch: normal;
    font-style: normal;
    font-weight: 400;
    src: url('${CDN_URL}/fonts/core/SourceSansPro-Regular.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Source Sans Pro';
    font-stretch: normal;
    font-style: normal;
    font-weight: 600;
    src: url('${CDN_URL}/fonts/core/SourceSansPro-Semibold.woff2') format('woff2');
  }

  @font-face {
    font-family: 'mm-icons';
    font-style: normal;
    font-weight: normal;
    src: url('${CDN_URL}/fonts/core/mm-icons/fonts/mm-icons.woff2') format('woff2');
  }
`;

document.head.appendChild(style);

const GlobalStyles = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }
  body {
    font-family: ${({ theme }) => theme.fonts.text};
    font-weight: 400;
    font-size: 14px;
    line-height: normal;
    color: #000000;
    margin: 0;
    background-color: #ffffff;

    @media (min-width: ${({ theme }) => theme.breakpointsMap.md.min}px) {
      font-size: 16px;
      line-height: normal;
    }
  }

  body.modal-open, body.ReactModal__Body--open {
    overflow-y: hidden;
    position: absolute;
    width: 100vw;
    max-width: 100vw;
  }

  .ReactModalPortal {
      display: block;
      position: absolute;
      z-index: 999;
  }

  a {
    color: inherit
  }

  input {
    font-family: ${({ theme }) => theme.fonts.text};
    font-weight: 400;
  }

  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: ${({ theme }) => theme.fonts.icon} !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .icon-MM_iconfont_ShoppingCart:before {
    content: "${({ theme }) => theme.fontIcons.ShoppingCart}";
  }

  .icon-MM_iconfont_Menu:before {
    content: "${({ theme }) => theme.fontIcons.Menu}";
  }

  .icon-MM_iconfont_ArrowR {
    &:before {
        content: "${({ theme }) => theme.fontIcons.ArrowR}";
    }
  }

  .icon-MM_iconfont_ArrowU:before {
      content: "${({ theme }) => theme.fontIcons.ArrowU}";
  }

  .icon-MM_iconfont_ArrowL:before {
      content: "${({ theme }) => theme.fontIcons.ArrowL}";
  }

  .icon-MM_iconfont_ArrowD:before {
      content: "${({ theme }) => theme.fontIcons.ArrowD}";
  }

  .icon-MM_iconfont_Info:before {
    content: "${({ theme }) => theme.fontIcons.Info}";
  }

  .icon-plus {
    content: "${({ theme }) => theme.fontIcons.Plus}";
  }

  * {
    box-sizing: border-box;
  }

  // custom styles for cookie information within data security modal
  #cmpcookieinfo {
      margin: 30px 0
  }

  #cmpcookieinfo .cookieinfotable {
      margin-bottom: 15px;
      border-collapse: collapse;
  }

  #cmpcookieinfo .cookieinfotable td, #cmpcookieinfo .cookieinfotable th {
      border: 2px solid #dbdbdb;
      padding: 0 10px
  }

  #cmpcookieinfo .cookieinfotable th {
      text-align: center
  }
`;
export default GlobalStyles;
