import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCheckoutState } from "../../context/CheckoutContext";
import { useCheckout } from "../../hooks/api";
import { OverviewGrid, CheckoutSection } from "../../components";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ContactOverviewSection = (props) => {
    const { state } = useCheckoutState();
    const { data } = useCheckout(state);
    return (_jsx(CheckoutSection, { children: _jsx(OverviewGrid, { items: [
                {
                    title: "Anschrift",
                    content: (_jsxs("p", { children: [data?.data.contactAddressFirstName, " ", data?.data.contactAddressLastName, _jsx("br", {}), data?.data.contactAddressStreet, " ", data?.data.contactAddressStreetNumber, _jsx("br", {}), data?.data.contactAddressZipCode, " ", data?.data.contactAddressPlace] })),
                },
                {
                    title: "Lieferadresse",
                    content: data?.data.differingShippingAddress ? (_jsxs("p", { children: [data?.data.shippingAddressFirstName, " ", data?.data.shippingAddressLastName, _jsx("br", {}), data?.data.shippingAddressStreet, " ", data?.data.shippingAddressStreetNumber, _jsx("br", {}), data?.data.shippingAddressZipCode, " ", data?.data.shippingAddressPlace] })) : ("Entspricht Kontaktadresse"),
                },
            ] }) }));
};
