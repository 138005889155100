import { jsxs as _jsxs, Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useCheckoutState } from "../../context/CheckoutContext";
import { useCheckout } from "../../hooks/api";
import { OverviewGrid, CheckoutSection } from "../../components";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const PaymentOverviewSection = (props) => {
    const { state } = useCheckoutState();
    const { data } = useCheckout(state);
    return (_jsx(CheckoutSection, { children: _jsx(OverviewGrid, { items: [
                {
                    title: "Bezahlung",
                    content: (_jsxs(_Fragment, { children: [_jsxs("p", { children: ["IBAN: ", data?.data.iban] }), _jsxs("p", { children: ["Bank: ", data?.data.bank] })] })),
                },
            ] }) }));
};
