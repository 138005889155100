import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { z } from "zod";
import { extendURLParams } from "@/utils/fetchData";
import { formatISODuration, intervalToDuration, parse } from "date-fns";
import { CheckoutSectionText } from "../../components";
const { cdnUrl, tenant } = extendURLParams();
export const config = [
    {
        key: "waipu",
        steps: [
            {
                type: "contactAndPayment",
                sections: [
                    {
                        component: "contactAddressSection",
                        defaultValues: {
                            contactAddressSalutation: "Frau",
                        },
                        schema: z.object({
                            contactAddressSalutation: z.enum(["Herr", "Frau"], {
                                required_error: "Bitte wähle eine Anrede aus.",
                            }),
                            contactAddressFirstName: z
                                .string({
                                required_error: "Bitte gib deinen Vornamen an.",
                            })
                                .trim()
                                .nonempty("Bitte gib deinen Vornamen ein.")
                                .regex(/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u, "Gib einen gültigen Vornamen ein."),
                            contactAddressLastName: z
                                .string({
                                required_error: "Bitte gib deinen Nachnamen an.",
                            })
                                .trim()
                                .nonempty("Bitte gib deinen Nachnamen ein.")
                                .regex(/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u, "Gib einen gültigen Nachnamen ein."),
                            contactAddressZipCode: z
                                .string({
                                required_error: "Bitte gib deine Postleitzahl ein.",
                            })
                                .trim()
                                .nonempty("Bitte gib deine Postleitzahl ein.")
                                .length(5, "Deine Postleitzahl muss aus 5 Ziffern bestehen.")
                                .regex(/^[0-9]+$/, "Deine Postleitzahl muss aus Zahlen bestehen."),
                            contactAddressPlace: z
                                .string({
                                required_error: "Bitte wähle deinen Wohnort aus.",
                            })
                                .trim()
                                .nonempty("Bitte wähle deinen Wohnort aus."),
                            contactAddressStreet: z
                                .string({
                                required_error: "Bitte gib deinen Straßennamen ein.",
                            })
                                .trim()
                                .nonempty("Bitte gib deinen Straßennamen ein.")
                                .max(50, "Dein Straßenname darf maximal 50 Zeichen lang sein."),
                            contactAddressStreetNumber: z
                                .string({
                                required_error: "Bitte gib deine Hausnummer ein.",
                            })
                                .trim()
                                .nonempty("Bitte gib deine Hausnummer ein."),
                            contactAddressBirthDay: z
                                .string({
                                required_error: "Bitte gib dein Geburtsdatum ein.",
                            })
                                .trim()
                                .nonempty("Bitte gib dein Geburtsdatum ein.")
                                .regex(/^[0-9]{2}\.[0-9]{2}\.[0-9]{4}$/, "Bitte gib ein Datum im Format 'TT.MM.JJJJ' ein.")
                                .refine((value) => {
                                try {
                                    const age = formatISODuration(intervalToDuration({
                                        start: parse(value, "dd.MM.yyyy", new Date()),
                                        end: new Date(),
                                    })).match(/^P(\d+)Y/)?.[1];
                                    return Number(age) >= 18;
                                }
                                catch (e) {
                                    // eslint-disable-next-line no-console
                                    console.error(e);
                                    return false;
                                }
                            }, {
                                message: "Das Mindestalter zum Abschluss des Vertrages beträgt 18 Jahre.",
                            }),
                            contactAddressEmail: z
                                .string({
                                required_error: "Bitte gib deine E-Mail Adresse ein.",
                            })
                                .trim()
                                .nonempty("Bitte gib deine E-Mail Adresse ein.")
                                .email("Bitte gib eine gültige E-Mail-Adresse ein."),
                            contactAddressConfirmEmail: z
                                .string({
                                required_error: "Bitte gib deine E-Mail Adresse erneut ein.",
                            })
                                .trim()
                                .nonempty("Bitte gib deine E-Mail Adresse erneut ein.")
                                .email("Bitte gib eine gültige E-Mail-Adresse ein."),
                            contactAddressPhoneNumberPrefix: z
                                .string({
                                required_error: "Bitte gib eine Vorwahl ein.",
                            })
                                .trim()
                                .nonempty("Bitte gib eine Vorwahl ein.")
                                .regex(/^0[1-9][0-9]{1,4}$/, "Bitte gib eine gültige Vorwahl ein."),
                            contactAddressPhoneNumberExtension: z
                                .string({
                                required_error: "Bitte gib eine Rufnummer ein.",
                            })
                                .trim()
                                .nonempty("Bitte gib eine Rufnummer ein.")
                                .max(8, "Bitte gib maximal 8 Zeichen ein.")
                                .regex(/^[0-9]{3,}$/, "Bitte gib eine Rufnummer ein."),
                        }),
                    },
                    {
                        component: "bankAccountSection",
                        defaultValues: {
                            sepaAccepted: false,
                        },
                        options: {
                            label: "Ich ermächtige die Media-Saturn Deutschland GmbH Ingolstadt, Gläubiger-Identifikationsnummer DE21Z0100000642216, Zahlungen von meinem Konto mittels Lastschrift einzuziehen. Zugleich weise ich mein Kreditinstitut an, die von Media-Saturn Deutschland GmbH Ingolstadt auf mein Konto gezogenen Lastschrift einzulösen. Hinweis: Ich kann innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrages verlangen. Es gelten dabei die mit meinem Kreditinstitut vereinbarten Bedingungen.",
                            content: (_jsx(CheckoutSectionText, { children: "Mir ist bekannt, dass ich f\u00FCr die erforderliche Deckung Sorge tragen muss. Durch R\u00FCckbelastung fallen Geb\u00FChren und Verzugskosten an, die vom Nutzer zu tragen sind." })),
                        },
                        schema: z.object({
                            sepaAccepted: z.boolean().refine((value) => value, {
                                message: "Bitte stimme dem SEPA-Mandat zu",
                            }),
                            iban: z
                                .string({
                                required_error: "Bitte gib deine IBAN ein.",
                            })
                                .trim()
                                .nonempty("Bitte gib deine IBAN ein."),
                        }),
                    },
                ],
            },
            {
                type: "orderSummary",
                legalNote: (pibUrl, openAGBModal, openPrivacyModal) => (_jsxs(_Fragment, { children: [_jsxs("p", { children: ["Hiermit erkl\u00E4re ich, dass ich die", " ", _jsx("a", { href: "/agb/", target: "_blank", rel: "noreferrer", children: "Allgemeinen Gesch\u00E4ftsbedingungen der S-KON eKontor 24 GmbH" }), " ", "und die", " ", _jsx("a", { href: `${cdnUrl}/pdf/waipu/agb-${tenant}-waipu.pdf`, target: "_blank", rel: "noreferrer", children: "Allgemeinen Gesch\u00E4ftsbedingungen der Media-Saturn Deutschland GmbH" }), " ", "sowie die", " ", _jsx("a", { href: "/datenschutz/", target: "_blank", rel: "noreferrer", children: "Datenschutzhinweise der S-KON eKontor 24 GmbH" }), " ", "und die", " ", _jsx("a", { href: `${cdnUrl}/pdf/waipu/datenschutz-hinweise-${tenant}-waipu-skon.pdf`, target: "_blank", rel: "noreferrer", children: "Datenschutzhinweise der Media-Saturn Deutschland GmbH" }), " ", "zur Kenntnis genommen habe und mit ihnen einverstanden bin."] }), _jsxs("p", { children: ["Mit den", " ", _jsx("a", { href: `${cdnUrl}/pdf/waipu/asset_spm_2465.pdf`, target: "_blank", rel: "noreferrer", children: "Vertragsbedingungen" }), " ", "bin ich einverstanden."] })] })),
                submitLabel: "Kostenpflichtig bestellen",
                sections: [
                    {
                        title: "Kontakt & Bezahlung",
                        sections: [
                            {
                                component: "waipu.contactOverviewSection",
                            },
                            {
                                component: "waipu.paymentOverviewSection",
                            },
                        ],
                    },
                ],
            },
        ],
    },
];
