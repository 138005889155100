import React from "react";
import styled from "styled-components";
import { extendURLParams } from "@/utils/fetchData";

import { Box } from "@mm/ui";

import Step01 from "@core/svgs/internet/infobox/step1.svg";
import Step02 from "@core/svgs/internet/infobox/step2.svg";
import Step03 from "@core/svgs/internet/infobox/step3.svg";
import Step04 from "@core/svgs/internet/infobox/step4.svg";

const StyledInfoBox = styled("div")`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;
  color: white;
  padding: 40px;
  background-color: ${({ theme }) => theme.colors.brand.primary};
  border-radius: ${({ theme }) => theme.borders.radius};
  overflow: hidden;

  ::before {
    content: "";
    display: block;
    position: absolute;
    top: -80px;
    left: -14%;
    width: 80%;
    height: 160%;
    border-top-right-radius: 180%;
    border-bottom-right-radius: 180%;
    background-color: ${({ theme }) => theme.colors.brand.primary};
    filter: brightness(0.9);
    z-index: 1;
  }

  @media (min-width: ${({ theme }) => theme.breakpointsMap.md.min}px) {
    flex-direction: row;
    gap: 12px;
  }
`;

const StyledSpacer = styled("span")`
  position: relative;
  display: none;
  z-index: 3;

  ::after {
    content: "";
    display: block;
    position: absolute;
    top: calc(50% - 2px);
    height: 2px;
    width: 100%;
    background-color: white;
  }

  @media (min-width: ${({ theme }) => theme.breakpointsMap.md.min}px) {
    display: block;
    height: 100px;
    width: 10vw;
    max-width: 140px;
    margin: 0 -50px;
  }
`;

const StyledHeadlineWrapper = styled("div")`
  display: flex;
  align-items: center;
  flex-basis: 100%;
  z-index: 3;
  margin-bottom: 20px;

  .styledHeadline {
    font-size: 24px;
    font-weight: 600;
  }
`;

const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  z-index: 3;

  p {
    text-align: left;
    padding: 20px;
    margin: 0;
    max-width: 250px;
  }

  @media (min-width: ${({ theme }) => theme.breakpointsMap.md.min}px) {
    flex-direction: column;
    flex-basis: 160px;
    p {
      text-align: center;
      padding: 20px 20px 0 20px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpointsMap.lg.min}px) {
    flex-basis: 200px;
  }
`;

const StyledIcon = styled("div")`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: white;

  svg {
    fill: ${({ theme }) => theme.colors.brand.primary};
    transform: scale(1.3);
  }
`;

const LinkToTenant = () => {
  const { tenant } = extendURLParams();

  return (
    <>
      {tenant === "mediamarkt" && (
        <p>
          Gehe mit Erhalt deiner ersten Rechnung auf{" "}
          <a
            href="https://vorteile.mediamarkt.de/"
            target="_blank"
            rel="noreferrer"
          >
            vorteile.mediamarkt.de
          </a>
          .
        </p>
      )}
      {tenant === "saturn" && (
        <p>
          Gehe mit Erhalt deiner ersten Rechnung auf{" "}
          <a
            href="https://vorteile.saturn.de/"
            target="_blank"
            rel="noreferrer"
          >
            vorteile.saturn.de
          </a>
          .
        </p>
      )}
    </>
  );
};

const InternetInfoBox = () => {
  return (
    <StyledInfoBox>
      <StyledHeadlineWrapper>
        <span className="styledHeadline">
          So sicherst du dir deine Prämie zum Vodafone Tarif
        </span>
      </StyledHeadlineWrapper>
      <StyledBox>
        <StyledIcon>
          <Step01 />
        </StyledIcon>
        <LinkToTenant />
      </StyledBox>
      <StyledSpacer />
      <StyledBox>
        <StyledIcon>
          <Step02 />
        </StyledIcon>
        <p>
          Gib deinen Prämiencode ein, den du mit deiner Auftragsbestätigung per
          E-Mail erhalten hast.
        </p>
      </StyledBox>
      <StyledSpacer />
      <StyledBox>
        <StyledIcon>
          <Step03 />
        </StyledIcon>
        <p>Wähle deine Prämie aus und lade deine Rechnung als Nachweis hoch.</p>
      </StyledBox>
      <StyledSpacer />
      <StyledBox>
        <StyledIcon>
          <Step04 />
        </StyledIcon>
        <p>
          Fülle das Bestellformular aus und schon macht sich die Prämie auf den
          Weg zu dir.
        </p>
      </StyledBox>
    </StyledInfoBox>
  );
};

export default InternetInfoBox;
