import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Headline, useBtnPosition, Box, Text } from "@mm/ui";

import {
  HARDWARE,
  SIMONLY,
  COUPON,
  useOfferConfigurator,
} from "features/Offer";

import {
  Price,
  PriceBox,
  SmallInlineLink,
  StickyElement,
  GeneralModalShell,
} from "components/atoms";

import { CartButton, TariffMonthlyPriceLabel } from "components/molecules";

import { BottomPriceBanner } from "components/organisms";

import {
  extendedVariantPropTypes,
  textVariantsPropTypes,
} from "sharedPropTypes";

import ExampleCalculations from "./exampleCalculation";

import ShippingHint from "./ShippingHint";
import DevicesHint from "./DevicesHint";
import PriceEntry from "./PriceEntry";

const PriceSection = ({
  priceOnceSub,
  priceShippingInfo,
  customExtendedVariant,
  priceTextVariant,
  sticky,
  shippingTextVariant,
  showOnePriceRow,
  tradeInActive,
  voucherCode,
  ...props
}) => {
  const cartBtnRef = useRef(null);
  const cartBtnPosition = useBtnPosition(cartBtnRef);

  const {
    state: {
      rootInfo,
      rootInfo: { accessories, offerId },
      activeAccessoryVariantIds,
      extendedVariant: storeExtendedVariant,
      extendedVariants,
      activeTariff,
      selectedAccessoryType,
      stock,
      isProductDetailInterface,
      isSoldOut,
      isTariff,
    },
  } = useOfferConfigurator();

  const isDoubleCardTariff = activeTariff?.doubleCard || false;
  const doubleCardPriceText =
    activeTariff?.properties?.tariff_monthly_price || activeTariff.monthlyPrice;

  const extendedVariant = customExtendedVariant || storeExtendedVariant;

  const deliveryInfo = () => {
    // This is for the tariff pdp
    if (isProductDetailInterface && isTariff) {
      return rootInfo;
    }

    // This is for the tariff offer hero
    if (isTariff) {
      return activeTariff;
    }

    // This is for the hardware offer hero and hardware pdp
    return extendedVariant.variant;
  };

  const extendedVariantsLegalNotes =
    extendedVariants?.[0]?.tariff?.legalNote?.text;

  // TODO add loading?
  if (!activeTariff) return null;
  // tariff related prices
  const {
    monthlyPrice,
    pricingIntervals: { intervals },
    calculations,
    commission,
  } = activeTariff;

  // checks for avg or monthly price, some tarifs need to be converted to float and then toFixed.
  const basicPrice =
    parseFloat(calculations?.averageCalculatedMonthlyPrice.toFixed(2)) ||
    monthlyPrice;

  const priceMonthlySub = (() => {
    if (intervals[1]) {
      return (
        <span>
          Ab dem {intervals[1].startMonth}. Monat{" "}
          <Price price={intervals[1].price} preventStyling preventMargin />
        </span>
      );
    } else if (isDoubleCardTariff) {
      return <span>{doubleCardPriceText}</span>;
    }
    return null;
  })();

  const displayOfferPreviewDate = (id) => {
    const dateNow = new Date();

    /**
     * MM 626b90a07c49e202fc220d42
     * SAT 626b90ff7a92a045b623c992
     * INT2-MM: 626bedf45bbf9a1e980a9392
     * IDs vom Offer was vorgestellt werden soll
     */
    const selectedOffers = [
      "626b90a07c49e202fc220d42",
      "626b90ff7a92a045b623c992",
      "626bedf45bbf9a1e980a9392",
    ];

    /**
     * am 03.05.2022 um 00:00:01 Uhr wird auf “Jetzt Bestellen” gewechselt
     */
    const offerAvailableDate = new Date(2022, 4, 3, 0, 0, 1);

    /**
     * Label Sting wird auf “Bestellbar ab 03.05.2022” gesetzt
     */
    const options = {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    };
    const label = `Bestellbar ab ${offerAvailableDate.toLocaleDateString(
      "de-DE",
      options
    )}`;

    if (selectedOffers.includes(id) === false) {
      return false;
    }

    if (offerAvailableDate <= dateNow && selectedOffers.includes(id)) {
      return false;
    }

    return {
      status: true,
      label,
    };
  };

  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <PriceBox
        legalNotes={extendedVariantsLegalNotes}
        monthlyPriceRow={
          basicPrice ? (
            <PriceEntry
              textVariant={priceTextVariant}
              highlight={
                selectedAccessoryType === SIMONLY ||
                selectedAccessoryType === COUPON
              }
              price={basicPrice}
              priceStr={<TariffMonthlyPriceLabel commission={commission} />}
              legalNotes={extendedVariantsLegalNotes}
              sub={priceMonthlySub || ""}
              tariffId={activeTariff._id || activeTariff.id}
            />
          ) : (
            <></>
          )
        }
        oncePriceRow={
          <>
            {!!activeTariff.commission && (
              <GeneralModalShell
                opener={<SmallInlineLink>Beispielrechnung</SmallInlineLink>}
              >
                <Headline variant="h1">Beispielrechnung</Headline>
                <ExampleCalculations />
              </GeneralModalShell>
            )}
            {extendedVariant &&
              selectedAccessoryType === HARDWARE &&
              showOnePriceRow && (
                <PriceEntry
                  textVariant={priceTextVariant}
                  legalNotes={extendedVariantsLegalNotes}
                  highlight
                  price={
                    activeTariff.oneTimePriceFrom
                      ? activeTariff.oneTimePriceFrom
                      : extendedVariant.variant.price
                  }
                  priceStr="Einmaliger Gerätepreis"
                  sub={priceOnceSub}
                  tariffId={activeTariff._id || activeTariff.id}
                />
              )}
            {tradeInActive && (
              <Text variant="smallCopy" textAlign="right" mt="2" highlighted>
                Rabattierter Einmalpreis bei Einsendung des Altgeräts
              </Text>
            )}
          </>
        }
        cartBtn={
          <CartButton
            voucherCode={voucherCode}
            isDisabled={isSoldOut || displayOfferPreviewDate(offerId).status}
            label={
              displayOfferPreviewDate(offerId).status
                ? displayOfferPreviewDate(offerId).label
                : "Jetzt bestellen"
            }
          />
        }
        devicesHint={
          stock !== 9999 && !isSoldOut ? (
            <DevicesHint stock={stock} textVariant={shippingTextVariant} />
          ) : (
            false
          )
        }
        shippingHint={
          !isSoldOut && (
            <ShippingHint
              textVariant={shippingTextVariant}
              deliveryInfo={deliveryInfo()}
              priceShippingInfo={priceShippingInfo}
              accessories={accessories}
              activeAccessoryVariantIds={activeAccessoryVariantIds}
            />
          )
        }
      />
      {cartBtnPosition !== 0 && sticky && (
        <StickyElement position="bottomleft" showAfterScroll={cartBtnPosition}>
          <BottomPriceBanner
            priceMonthly={activeTariff.monthlyPrice}
            basicPrice={basicPrice}
            priceOnce={
              customExtendedVariant?.variant.price ||
              (selectedAccessoryType === HARDWARE &&
                extendedVariant?.variant.price) ||
              0
            }
            tariffId={activeTariff._id || activeTariff.id}
          />
        </StickyElement>
      )}
    </Box>
  );
};

PriceSection.propTypes = {
  priceOnceSub: PropTypes.string,
  priceShippingInfo: PropTypes.node,
  customExtendedVariant: extendedVariantPropTypes,
  sticky: PropTypes.bool,
  showOnePriceRow: PropTypes.bool,
  priceTextVariant: textVariantsPropTypes,
  shippingTextVariant: textVariantsPropTypes,
  voucherCode: PropTypes.string,
};

PriceSection.defaultProps = {
  priceOnceSub: "",
  priceShippingInfo: null,
  customExtendedVariant: null,
  sticky: false,
  showOnePriceRow: true,
  priceTextVariant: "copy",
  shippingTextVariant: "copy",
  voucherCode: "",
};

export default PriceSection;
