import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { noop } from "lodash";
import dataLayer from "@/helper/dataLayer";
import "./ImageOnlyTeaser.scss";
const getCssVariable = (variable) => {
    return getComputedStyle(document.documentElement).getPropertyValue(variable);
};
const breakpoints = {
    xs: getCssVariable("--breakpoint-xs"),
    sm: getCssVariable("--breakpoint-sm"),
    md: getCssVariable("--breakpoint-md"),
    lg: getCssVariable("--breakpoint-lg"),
    xl: getCssVariable("--breakpoint-xl"),
};
const ImageOnlyTeaser = ({ onClick = noop, content: { images, linkUrl, type }, className, qaName, }) => {
    const handleClick = (onClick, url, component) => {
        dataLayer({
            eventCategory: "Teaser",
            eventAction: "click",
            eventLabel: `${component}:${url}`,
        });
        if (onClick)
            onClick(url);
    };
    return (_jsx("div", { className: `picture-wrapper ${className}`, "data-qa": qaName, children: linkUrl ? (_jsx("a", { className: "link-wrapper", href: linkUrl, target: "_self", onClick: () => handleClick(onClick, linkUrl, type), children: _jsxs("picture", { children: [_jsx("source", { srcSet: window.CDN_URL + images.xlUrl, media: `(min-width: ${breakpoints.xl})` }), _jsx("source", { srcSet: window.CDN_URL + images.lgUrl, media: `(min-width: ${breakpoints.lg})` }), _jsx("source", { srcSet: window.CDN_URL + images.mdUrl, media: `(min-width: ${breakpoints.md})` }), _jsx("source", { srcSet: window.CDN_URL + images.smUrl, media: `(min-width: ${breakpoints.sm})` }), _jsx("source", { srcSet: window.CDN_URL + images.xsUrl, media: `(min-width: 0px)` }), _jsx("img", { className: "picture-wrapper-image", alt: "", src: window.CDN_URL + images.lgUrl })] }) })) : (_jsxs("picture", { children: [_jsx("source", { srcSet: window.CDN_URL + images.xlUrl, media: `(min-width: ${breakpoints.xl})` }), _jsx("source", { srcSet: window.CDN_URL + images.lgUrl, media: `(min-width: ${breakpoints.lg})` }), _jsx("source", { srcSet: window.CDN_URL + images.mdUrl, media: `(min-width: ${breakpoints.md})` }), _jsx("source", { srcSet: window.CDN_URL + images.smUrl, media: `(min-width: ${breakpoints.sm})` }), _jsx("source", { srcSet: window.CDN_URL + images.xsUrl, media: `(min-width: 0px)` }), _jsx("img", { alt: "", src: window.CDN_URL + images.lgUrl, className: "picture-wrapper-image" })] })) }));
};
export default ImageOnlyTeaser;
