/* eslint-disable max-len */
import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";

import axios from "axios";

import { Modal, Button } from "@mm/ui";

import { useOfferConfigurator, HARDWARE } from "features/Offer";

import dataLayer from "helper/dataLayer";

import { extendLegacyTariffDetails } from "utils/tariffUtils";

import { CartModalItemBtn } from "components/atoms";

// FIXME refactor components (dependency cycle)
import CartFilledModal from "../CartFilledModal";
import CartModalItemForSmartphone from "../CartModalItemForSmartphone";
import CartModalItemForTariff from "../CartModalItemForTariff";

/**
 * Get hardware info object (hardware_id, variant_id) for add-to-cart call
 * @private
 * @constant
 * @type {object}
 * @param isTariffOffer {boolean} - whether the current page is tariff or hardware pdp
 * @param hasHardwareAccessory {boolean} - if the user has picked the hardware instead of coupon (tariff pdp only)
 * @param extendedVariant {object} - the current hardware on smartphone pdp
 * @param activeAccessoryVariantIds {object} - an object with hardware & variant ids describing the accessories
 */
const getHardwareInfo = (
  isTariffOffer,
  hasHardwareAccessory,
  extendedVariant
) => {
  if (isTariffOffer) {
    if (hasHardwareAccessory && extendedVariant) {
      return {
        hardware_id: extendedVariant.hardwareId,
        variant_id: extendedVariant.variant.ebootisId,
      };
    }
    return {
      hardware_id: null,
      variant_id: null,
    };
  }
  return {
    hardware_id: extendedVariant.hardwareId,
    variant_id: extendedVariant.variant.ebootisId,
  };
};

const getActiveAccessoryVariantIds = (variantIds) => {
  return Object.values(variantIds).map(({ hardwareId, ebootisId }) => ({
    id: hardwareId,
    variantId: ebootisId,
  }));
};

const RightCartItem = ({
  activeTariff,
  extendedVariant,
  rootInfo,
  selectedAccessoryType,
  sendToCart,
}) => {
  const avgMonthlyPrice =
    activeTariff?.calculations?.averageCalculatedMonthlyPrice;

  const priceMonthly = activeTariff.comission
    ? avgMonthlyPrice
    : activeTariff.monthlyPrice;

  const hasHardwareAccessory = selectedAccessoryType === HARDWARE;

  if (!rootInfo.hardware) {
    return (
      <CartModalItemForTariff
        tariff={{
          serviceProvider: activeTariff.serviceProvider,
          carrier: activeTariff.carrier,
          name: activeTariff.name,
          commission: activeTariff.commission,
        }}
        price={avgMonthlyPrice || priceMonthly}
        selectedVariant={extendedVariant ? extendedVariant.variant : null}
        couponValue={!hasHardwareAccessory ? rootInfo.coupon : null}
        buttonAction={
          <CartModalItemBtn
            onClick={() => {
              sendToCart(true);
              return false;
            }}
          />
        }
      />
    );
  }

  if (rootInfo && extendedVariant) {
    const { accessories } = rootInfo;
    const { variant, manufacturer, hardwareName } = extendedVariant;

    return (
      <CartModalItemForSmartphone
        accessories={accessories}
        hardware={{
          manufacturer,
          name: hardwareName,
        }}
        variant={{
          storage: variant.storage,
          color: variant.color.name,
        }}
        tariff={{
          serviceProvider: activeTariff.serviceProvider,
          carrier: activeTariff.carrier,
          name: activeTariff.name,
          commission: activeTariff.commission,
        }}
        prices={{
          // FIXME please clean/dry me up (whole file)
          monthly: priceMonthly,
          once: variant.price,
        }}
        image={extendedVariant.variant.images}
        buttonAction={
          <CartModalItemBtn
            onClick={() => {
              sendToCart(true);
              return false;
            }}
          />
        }
      />
    );
  }

  return <div>Kein Item gefunden</div>;
};

const CartButton = ({ label, isDisabled, dataQa, voucherCode }) => {
  // 0 = normal, 1 = loading, 2 = disabled
  const initialCartBtnStatus = isDisabled ? 2 : 0;
  const [cartBtnStatus, setCartBtnStatus] = useState(initialCartBtnStatus);
  const [cartFilledModalItem, setCartFilledModalItem] = useState(null);
  const [cartError, setCartError] = useState(null);

  useEffect(() => {
    setCartBtnStatus(initialCartBtnStatus);
  }, [isDisabled]);

  const {
    state: {
      activeTariff,
      extendedVariant,
      rootInfo: { hardware: rootInfoHw, tradeInActive, tradeInValue },
      rootInfo,
      selectedAccessoryType,
      activeAccessoryVariantIds,
    },
  } = useOfferConfigurator();

  const sendToCart = async (shouldOverwrite) => {
    // If cart btn is disabled or loading
    if (cartBtnStatus > 0) {
      return;
    }

    // Select button
    setCartBtnStatus(1);

    // Send to cart
    let response = null;

    // Tarif-ID in the new and old format
    const tariffId = activeTariff.id || activeTariff._id;

    // we are using the rootInfo offerId for none mobile offers
    const offerId = activeTariff.offerId || rootInfo.offerId;

    // For the getHardwareInfo function
    const hasHardwareAccessory = selectedAccessoryType === HARDWARE;

    try {
      const requestBody = {
        offer_id: offerId,
        tariff_id: tariffId,
        selectedTariff: activeTariff,
        overwrite: shouldOverwrite,
        ...getHardwareInfo(!rootInfoHw, hasHardwareAccessory, extendedVariant),
        accessories: getActiveAccessoryVariantIds(activeAccessoryVariantIds),
        tradeInActive,
        tradeInValue,
        voucherCode,
      };

      response = await axios.post("/checkout/add-to-cart/", requestBody);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("ADD-TO-CART", e);
    }

    // Unselect button and tell callback that we're finished
    if (response && response.data && response.data.success) {
      // Success, redirect
      window.location.href = "/checkout/step/1/";
    } else if (response && response.data && response.data.offer) {
      // Cart was filled, show modal
      const { hardware, tariff, coupon, accessories } = response.data.offer;
      const preparedTariff = extendLegacyTariffDetails(tariff);

      if (response.data.offer.hardware) {
        const { storage, color, images, price } = hardware.variants[0];
        const prices = {
          monthly: preparedTariff.monthlyPrice,
          once: price,
        };
        setCartFilledModalItem(
          <CartModalItemForSmartphone
            hardware={{
              manufacturer: hardware.manufacturer,
              name: hardware.name,
            }}
            variant={{
              storage,
              color,
            }}
            tariff={{
              serviceProvider: tariff.service_provider,
              carrier: tariff.carrier,
              name: tariff.tariffname,
              commission: preparedTariff.promotionBonus,
            }}
            prices={prices}
            accessories={accessories}
            image={images}
            buttonAction={
              <CartModalItemBtn
                onClick={() => {
                  window.location.href = "/checkout/step/1/";
                }}
              />
            }
          />
        );
      } else {
        setCartFilledModalItem(
          <CartModalItemForTariff
            tariff={{
              serviceProvider: preparedTariff.service_provider,
              carrier: preparedTariff.carrier,
              name: preparedTariff.tariffname,
              commission: preparedTariff.promotionBonus,
            }}
            price={tariff.averagePrice || tariff.monthlyPrice}
            couponValue={coupon ? coupon.value : null}
            selectedVariant={null}
            buttonAction={
              <CartModalItemBtn
                onClick={() => {
                  window.location.href = "/checkout/step/1/";
                }}
              />
            }
          />
        );
      }
    } else {
      setCartError(
        <Modal
          dynamicHeight
          isInitialOpen
          onRequestClose={() => {
            setCartError(null);
          }}
          size="xl"
        >
          Leider ist während der Verarbeitung dieser Anfrage ein Fehler
          aufgetreten, bitte versuchen Sie es erneut. {/* TODO: wording! */}
        </Modal>
      );
    }

    setCartBtnStatus(0); // Enable button again
  };

  return (
    <>
      {cartError}
      {!!cartFilledModalItem && (
        <CartFilledModal
          leftItem={cartFilledModalItem}
          rightItem={
            <RightCartItem
              activeTariff={activeTariff}
              extendedVariant={extendedVariant}
              rootInfo={rootInfo}
              sendToCart={sendToCart}
              selectedAccessoryType={selectedAccessoryType}
            />
          }
          modalOpened
          modalOnRequestClose={() => {
            setCartFilledModalItem(null);
          }}
        />
      )}
      <Button
        data-codeception="orderButton"
        data-qa-energy={dataQa}
        loading={cartBtnStatus === 1}
        disabled={cartBtnStatus > 0}
        onClick={() => {
          dataLayer({
            eventAction: "click",
            eventCategory: "Basket Button",
            eventLabel: "buy",
          });

          sendToCart(false);
        }}
      >
        {label}
      </Button>
    </>
  );
};

CartButton.propTypes = {
  label: PropTypes.node,
  isDisabled: PropTypes.bool,
  dataQa: PropTypes.string,
  voucherCode: PropTypes.string,
};

CartButton.defaultProps = {
  label: "Jetzt bestellen",
  isDisabled: false,
  dataQa: "",
  voucherCode: "",
};

export default CartButton;
