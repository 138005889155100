import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Text } from "@mm/ui";

import PhoneSvg from "@core/svgs/phone.svg";

import { HOTLINE_NUMBER_CLICKABLE } from "helper/globals";

const Display = styled("a")`
  color: ${(props) => props.theme.colors.grey[0]};
  display: flex;
  flex-direction: column;
  text-decoration: none;

  @media (max-width: ${(props) => props.theme.breakpointsMap.lg.min}px) {
    padding: 0.75rem 0.5rem;
  }
  @media (min-width: ${(props) => props.theme.breakpointsMap.lg.min}px) {
    color: ${(props) => props.theme.colors.white};
  }
`;

const Header = styled("span")`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.4;

  svg {
    height: 12px;
    margin-right: 3px;
    position: relative;
    top: 1px;
    transform: rotate(90deg);
    width: 12px;

    g use {
      fill: ${(props) => props.theme.colors.grey[0]};

      @media (min-width: ${(props) => props.theme.breakpointsMap.lg.min}px) {
        fill: ${(props) => props.theme.colors.white};
      }
    }
  }
  &.navMob {
    font-size: 14px;
    font-weight: 400;
    svg {
      display: none;
    }
  }
`;

const OrderHotline = ({ className, showDetails }) => (
  <Display href={`tel:${HOTLINE_NUMBER_CLICKABLE}`}>
    <Header className={className}>
      <PhoneSvg />
      Bestellhotline
      {!showDetails && <>&#58;&nbsp;{window.HOTLINE_NUMBER}</>}
    </Header>
    {showDetails && (
      <>
        <Text variant={className === "navMob" && "smallCopy"}>
          {window.HOTLINE_NUMBER}
        </Text>
        <Text variant={className === "navMob" ? "xs" : "s"}>
          Mo-Fr: 8-20 Uhr, Sa: 10-18 Uhr
        </Text>
      </>
    )}
  </Display>
);

OrderHotline.propTypes = {
  showDetails: PropTypes.bool,
};

OrderHotline.defaultProps = {
  showDetails: true,
};

export default OrderHotline;
