import { useQuery, useQueryClient } from "react-query";
import { extendURLParams } from "@/utils/fetchData";
import { useEffect } from "react";
export const useAddressSuggestions = (payload, options) => {
    const queryClient = useQueryClient();
    const { tenant, environment, restUrl } = extendURLParams();
    const { data: citiesResponse } = useQuery(["getCitiesForZipCode", tenant, environment], () => fetch(`${restUrl}/v1/address/cities/${payload.zip}?tenant=${tenant}&environment=${environment}`, {
        method: "get",
        credentials: "include",
    })
        .then((res) => {
        return res.json();
    })
        .catch((error) => new Error(error)), {
        ...options,
        cacheTime: 5 * 60 * 1000,
        enabled: Boolean(payload.zip) && payload.zip !== "",
        refetchOnWindowFocus: false,
        onSettled: () => queryClient.invalidateQueries(["getStreetsForZipCodeAndCity"]),
    });
    const { data: streetsResponse } = useQuery(["getStreetsForZipCodeAndCity", tenant, environment], () => fetch(`${restUrl}/v1/address/streets/${payload.zip}/${payload.city}?tenant=${tenant}&environment=${environment}`, {
        method: "get",
        credentials: "include",
    })
        .then((res) => {
        return res.json();
    })
        .catch((error) => new Error(error)), {
        ...options,
        cacheTime: 5 * 60 * 1000,
        enabled: Boolean(payload.zip) && Boolean(payload.city),
        refetchOnWindowFocus: false,
    });
    useEffect(() => {
        queryClient.invalidateQueries(["getCitiesForZipCode"]);
    }, [payload.zip]);
    useEffect(() => {
        queryClient.invalidateQueries(["getStreetsForZipCodeAndCity"]);
    }, [payload.city]);
    const cities = citiesResponse?.payload ?? [];
    const streets = streetsResponse?.payload ?? [];
    return {
        clear: () => {
            queryClient.invalidateQueries([
                "getCitiesForZipCode",
                "getStreetsForZipCodeAndCity",
            ]);
        },
        data: {
            zip: payload.zip,
            cities,
            streets,
        },
    };
};
