import React from "react";
import { Surface } from "@mm/ui";

import { useInternetProvider } from "features/Internet";

import { InternetInfoBox } from "components/organisms";

const InternetInfoBoxSection = () => {
  const {
    state: {
      availabilityChecked,
      page: {
        internet: { serviceProvider },
      },
    },
  } = useInternetProvider();

  /**
   * The ID 588b19050f85961f691e2b24 is the o2-ID
   * we show the section only for vodafone
   */

  if (availabilityChecked || serviceProvider === "588b19050f85961f691e2b24") {
    return null;
  }

  return (
    <Surface variant="narrow" shape="roundedSmall" mt={6}>
      <InternetInfoBox />
    </Surface>
  );
};

export default InternetInfoBoxSection;
