import { jsx as _jsx } from "react/jsx-runtime";
import { useCheckoutState } from "../../context/CheckoutContext";
import { useCheckout } from "../../hooks/api";
import { OverviewGrid, CheckoutSection } from "../../components";
import { getItemisedBillingLabel } from "..";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ContractOverviewSection = (props) => {
    const { state } = useCheckoutState();
    const { data } = useCheckout(state);
    return (_jsx(CheckoutSection, { children: _jsx(OverviewGrid, { items: [
                {
                    title: "Einzelverbindungsnachweis",
                    content: data?.data.itemisedBilling &&
                        getItemisedBillingLabel(data.data.itemisedBilling),
                },
                {
                    title: "Persönliche Kundenkennzahl",
                    content: data?.data.personalCustomerCode,
                },
                {
                    title: "Vertragszusammenfassung per E-Mail",
                    content: data?.data.eeccApprovalToSendSummary
                        ? "Hiermit stimme ich zu, dass mir die Vertragsdokumente und rechtliche Pflichtinformationen von o2 (Telefónica Germany GmbH & Co. OHG) per E-Mail zugesandt werden."
                        : "Nein",
                },
            ] }) }));
};
