import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { CheckoutSection, CheckoutSectionHeadline, CheckoutSectionText, RadioList, StyledSelect, } from "../../components";
import { useCheckout } from "../../hooks/api";
import { ACTIONS as CONTEXT_ACTIONS, useCheckoutState, } from "../../context/CheckoutContext";
import styled from "styled-components";
import { useField, useValidation, ValidateOnEvent, } from "../../hooks";
import { providers } from "../../providers";
const Row = styled.div `
  display: flex;
  width: 100%;
  gap: 1.5rem;
  @media (max-width: 760px) {
    flex-wrap: wrap;
  }
`;
const InputWrapper = styled.div `
  flex: 1;
`;
export const ProviderChangeSection = ({ defaultValues, schema, }) => {
    const { state, dispatch: dispatchContext } = useCheckoutState();
    const { errors: providerChangeErrors, fieldState: providerChange, setFieldState: setProviderChange, } = useField("providerChange", schema, defaultValues?.providerChange
        ? Boolean(defaultValues.providerChange)
        : undefined, {
        validateOn: ValidateOnEvent.Change,
        mutate: true,
    });
    const { errors: formerProviderErrors, fieldState: formerProvider, setFieldState: setFormerProvider, } = useField("formerProvider", schema, "");
    const { isFetching, isLoading } = useCheckout(state);
    const { clearErrors, } = useValidation();
    const checked = () => {
        if (providerChange === undefined) {
            return undefined;
        }
        return providerChange ? "providerChange--yes" : "providerChange--no";
    };
    return (_jsxs(CheckoutSection, { children: [_jsx(CheckoutSectionHeadline, { children: "Anschlussart" }), _jsx(RadioList, { checked: checked(), id: "providerChange", items: [
                    {
                        key: "providerChange--yes",
                        label: "Ich wohne hier und möchte meinen bestehenden Anschluss zu 1&1 wechseln",
                    },
                    {
                        key: "providerChange--no",
                        label: "Ich benötige einen neuen Internet-Anschluss (z.B. beim Umzug)",
                    },
                ], onChange: (key) => {
                    clearErrors([
                        "formerProvider",
                        "contractTerminationDate",
                        "contractTerminated",
                        "digitalLetterOfIntent",
                        "numberPortingIntent",
                        "currentProviderNumberPrefix",
                        "currentProviderNumberExtension",
                    ]);
                    if (key === "providerChange--no") {
                        dispatchContext({
                            type: CONTEXT_ACTIONS.RESET_FIELDS,
                            payload: [
                                "formerProvider",
                                "contractTerminationDate",
                                "contractTerminated",
                                "digitalLetterOfIntent",
                                "numberPortingIntent",
                                "currentProviderNumberPrefix",
                                "currentProviderNumberExtension",
                            ],
                        });
                    }
                    setProviderChange(key === "providerChange--yes");
                }, disabled: isLoading || isFetching, error: providerChangeErrors?.[0]?.message }), providerChange && (_jsx(_Fragment, { children: _jsx(Row, { children: _jsx(InputWrapper, { children: _jsx(StyledSelect, { id: "formerProvider", label: "Bitte w\u00E4hle deinen Anbieter aus*", options: state.serviceProvider
                                ? [
                                    {
                                        code: "CARRIER_NOT_FOUND",
                                        name: "Anbieter nicht aufgeführt",
                                    },
                                    ...providers[state.serviceProvider],
                                ]
                                : [], idField: "code", labelField: "name", onChange: (_, value) => {
                                clearErrors(["formerProvider"]);
                                setFormerProvider(value?.code ?? "");
                            }, value: formerProvider, disabled: isLoading || isFetching, message: formerProviderErrors?.[0]?.message, layout: formerProviderErrors && "error" }) }) }) })), providerChange === false && (_jsx(CheckoutSectionText, { children: "Du erh\u00E4ltst 3 kostenlose neue Rufnummern. Auf Wunsch kannst du nach Bestellung auch die \u00DCbernahme deiner bisherigen Rufnummer direkt bei 1&1 beauftragen." }))] }));
};
