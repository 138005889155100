import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { RadioList, CheckoutSection, CheckoutSectionHeadline, } from "../../components";
import { useCheckoutState } from "../../context/CheckoutContext";
import { useField } from "../../hooks";
import { useCheckout } from "../../hooks/api";
export const PhoneNumberAmountSection = ({ defaultValues, schema, }) => {
    const { state } = useCheckoutState();
    const { errors, fieldState, setFieldState } = useField("phoneNumberAmount", schema, defaultValues?.phoneNumberAmount);
    const { isFetching, isLoading } = useCheckout(state);
    return (_jsxs(CheckoutSection, { children: [_jsx(CheckoutSectionHeadline, { children: "Wieviele Rufnummern ben\u00F6tigst du?" }), _jsx(RadioList, { checked: fieldState, id: "phoneNumberAmount", items: [
                    {
                        key: "multiple",
                        label: "bis zu 10 Rufnummern (ISDN Anschluss) 2,99€/mtl.",
                    },
                    {
                        key: "single",
                        label: "1 Rufnummer (analoger Anschluss) 0,00€/mtl.",
                    },
                ], onChange: (key) => {
                    setFieldState(key);
                }, disabled: isLoading || isFetching, error: errors?.[0]?.message })] }));
};
