import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useCheckoutState } from "../../context/CheckoutContext";
import { useCheckout } from "../../hooks/api";
import { OverviewGrid, CheckoutSection } from "../../components";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ContractOverviewSection = (props) => {
    const { state } = useCheckoutState();
    const { data } = useCheckout(state);
    return (_jsx(CheckoutSection, { children: _jsx(OverviewGrid, { items: [
                {
                    title: "Dokumente zum Vertrag",
                    content: (_jsxs("p", { children: ["Vertragsdokumente und rechtliche Pflichtangaben erhalten:", " ", data?.data.eeccDocumentsDownloaded ? "Ja" : "Nein"] })),
                },
            ] }) }));
};
