import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, InputSelect, InputText, Box } from "@mm/ui";
import { GeneralModalShell } from "@/components/atoms";
import { useAddressSuggestions, } from "@/features/Internet/hooks";
import { extendURLParams } from "@/utils/fetchData";
import CheckmarkSvg from "@core/svgs/checkmark.svg";
import ErrorSvg from "@core/svgs/icon-x.svg";
import { useDebounce } from "@/utils/hooks";
import { provideIsLoadingProp, } from "@/utils/context/LoadingContext";
import { THEME } from "@core/theme";
import { constructAccessoriesLabel, constructAccessoriesStartingPrice, constructOfferLabel, } from "@/utils/offer";
import { useNavigate } from "react-router-dom";
const { skeletonBase } = THEME;
const StyledAvailabilityCheck = styled.div `
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey[7]};
  padding: 0.875rem 1.25rem 1.5rem;
  border-radius: 4px;
`;
const HeadlineWrapper = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;

  .posOpener {
    background: transparent;
    border: 0;
    text-decoration: underline;
  }
`;
const PosForm = styled.div `
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  div:first-child {
    flex-grow: 1;
  }
`;
const Headline = styled.p ``;
const Form = styled.form `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 1.5rem;

  @media (max-width: 760px) {
    gap: 1rem;
  }
`;
const Row = styled.div `
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1.5rem;

  @media (max-width: 760px) {
    flex-wrap: wrap;
    gap: 1rem;
  }
`;
const NarrowWrapper = styled.div `
  flex: 0 0 33%;
  @media (max-width: 760px) {
    flex: 1 0 100%;
  }
`;
const Wrapper = styled.div `
  flex: 1;
  @media (max-width: 760px) {
    flex: 1 0 100%;
  }
`;
const Status = styled.div.attrs(provideIsLoadingProp) `
  ${({ isLoading }) => isLoading && "min-height: 7.5rem;"};
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  font-size: 1.25rem;
`;
const StatusIcon = styled.aside.attrs(provideIsLoadingProp) `
  flex: 0;

  ${({ isLoading }) => isLoading &&
    `
  svg {
    border-color: #ccc;
    path {
      fill: #ccc;
    }
  }
    `};
`;
const StatusContent = styled.main.attrs(provideIsLoadingProp) `
  ${({ isLoading }) => isLoading && skeletonBase + "height: 6rem;"};

  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
const ActionLink = styled.a.attrs(provideIsLoadingProp) `
  cursor: pointer;
  text-decoration: underline;
  font-size: 1rem;
  display: inline-block;
  line-height: 1.1;

  ${({ isLoading }) => isLoading && skeletonBase + "display:block;margin-right: auto;"}
`;
const PosText = styled.p `
  font-size: 1rem;
  display: inline-block;
  line-height: 1.1;
  padding: 0;
  margin: 0;
`;
const ErrorSvgWrapper = styled("div") `
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid red;

  svg {
    display: block;
    position: absolute;
    top: 21px;
    left: 21px;
    transform: scale(1.5);

    path {
      fill: red;
    }
  }
`;
const ActionLinks = styled.div `
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;
const AlternativeOffersWrapper = styled.div `
  flex: 1 100%;
`;
const AlternativeOffersList = styled.ul `
  padding: 0;
  margin: 0;
  list-style: none;
`;
export const AvailabilityCheck = ({ alternativeOffers = [], available, basePath, checked = false, clearAvailability = false, data, isLoading, onlineOnly = false, onReset, onSubmit, }) => {
    const [availabilityChecked, setAvailabilityChecked] = useState(checked);
    const [state, setState] = useState(data ?? {});
    const [errors, setErrors] = useState({
        zip: undefined,
        city: undefined,
        street: undefined,
        streetNumber: undefined,
        promoCode: undefined,
        marketCode: undefined,
    });
    const [success, setSuccess] = useState({
        zip: undefined,
        city: undefined,
        street: undefined,
        streetNumber: undefined,
        promoCode: undefined,
        marketCode: undefined,
    });
    const [addressFieldsDirty, setAddressFieldsDirty] = useState(false);
    const navigate = useNavigate();
    const { data: addressSuggestions, clear: clearAddressSuggestions } = useAddressSuggestions(useDebounce(state, 800));
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleToggle = (isOpen) => {
        setIsModalOpen(isOpen);
    };
    function validateMarketCode(value) {
        if (value.length >= 4) {
            const { tenant, environment, restUrl } = extendURLParams();
            fetch(`${restUrl}/v1/market/${value}?tenant=${tenant}&environment=${environment}`, {
                method: "get",
                credentials: "include",
            })
                .then((res) => {
                return res.json();
            })
                .then((data) => {
                setErrors({ ...errors, marketCode: undefined });
                setSuccess({
                    ...success,
                    marketCode: "Die POS-ID ist gültig und wurde gespeichert. Du kannst das Overlay schließen.",
                });
                const timer = setTimeout(() => {
                    handleToggle(!isModalOpen);
                }, 1000);
                return () => clearTimeout(timer);
            })
                .catch(() => {
                setSuccess({ ...success, marketCode: undefined });
                return setErrors({ ...errors, marketCode: "Ungültige POS-ID." });
            });
        }
        else {
            return setErrors({
                ...errors,
                marketCode: "Bitte geben Sie eine POS-ID ein.",
            });
        }
    }
    const validateForm = (form) => {
        setErrors({});
        const validationErrors = [];
        if (form.zip && !/^[0-9]*$/.test(form.zip)) {
            validationErrors.push({
                key: "zip",
                message: "Nur Zahlen erlaubt",
            });
        }
        if (form.zip === "" ||
            !form.zip?.length ||
            form.zip.length < 5 ||
            form.zip.length > 5) {
            validationErrors.push({
                key: "zip",
                message: "Bitte gib eine 5-stellige PLZ an",
            });
        }
        if (form.streetNumber &&
            !/^[1-9][0-9]*[a-zA-Z]?(\s*[/-]?\s*[1-9][0-9]*[a-zA-Z]?)?$/.test(form.streetNumber)) {
            validationErrors.push({
                key: "streetNumber",
                message: "Bitte gib eine gültige Hausnummer ein.",
            });
        }
        if (form.streetNumber && form.streetNumber.length > 10) {
            validationErrors.push({
                key: "streetNumber",
                message: "Deine Hausnummer darf maximal 10 Zeichen lang sein.",
            });
        }
        if (!form.streetNumber) {
            validationErrors.push({
                key: "streetNumber",
                message: "Bitte gib eine Hausnummer an",
            });
        }
        if (form.city && !/^[a-z,A-Z,\-,., ,Ä-ä,Ü-ü,Ö-ö]*$/.test(form.city)) {
            validationErrors.push({
                key: "city",
                message: "Keine Sonderzeichen erlaubt",
            });
        }
        if (!form.city) {
            validationErrors.push({
                key: "city",
                message: "Bitte gib eine Stadt an",
            });
        }
        if (form.street &&
            !/^[a-z,A-Z,0-9,\-,., ,Ä-ä,Ü-ü,Ö-ö]*$/.test(form.street)) {
            validationErrors.push({
                key: "street",
                message: "Keine Sonderzeichen erlaubt",
            });
        }
        if (!form.street) {
            validationErrors.push({
                key: "street",
                message: "Bitte gib eine Straße an",
            });
        }
        if (validationErrors.length > 0) {
            setErrors(validationErrors.reduce((errorObject, { key, message }) => {
                errorObject[key] = message;
                return errorObject;
            }, {}));
            return false;
        }
        return true;
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { promoCode, marketCode, ...address } = state;
    useEffect(() => {
        if (addressSuggestions?.cities?.length === 1) {
            setState({
                ...state,
                city: addressSuggestions.cities[0],
            });
        }
    }, [addressSuggestions.cities]);
    useEffect(() => {
        if (document.location.search) {
            setState({
                ...state,
                promoCode: new URLSearchParams(document.location.search).get("promo") ??
                    undefined,
            });
        }
    }, [document.location.search]);
    useEffect(() => {
        setState(data ?? {});
    }, [data]);
    useEffect(() => {
        setAvailabilityChecked(checked);
    }, [checked, available]);
    useEffect(() => {
        if (Object.values(address).every((value) => value && value !== "")) {
            setAddressFieldsDirty(true);
        }
    }, [state]);
    // clearAvailability is passed as uri param
    // (used when resetting availability check from /checkout)
    useEffect(() => {
        if (clearAvailability) {
            clearAddressSuggestions();
            setState({});
            setAvailabilityChecked(false);
            onReset && onReset();
        }
    }, [clearAvailability]);
    return (_jsx(StyledAvailabilityCheck, { children: !availabilityChecked ? (_jsxs(_Fragment, { children: [_jsxs(HeadlineWrapper, { children: [_jsx(Headline, { children: "Pr\u00FCfe jetzt, welches Angebot an Deiner Adresse verf\u00FCgbar ist." }), _jsxs(GeneralModalShell, { isOpen: isModalOpen, onToggle: handleToggle, opener: _jsx("button", { type: "button", className: "posOpener", children: "POS-ID" }), children: [_jsx("p", { children: "Bitte gib deine POS-ID ein, damit wir passende Angebote anzeigen k\u00F6nnen." }), _jsxs(PosForm, { children: [_jsx(InputText, { id: "marketCode", label: "POS-ID", value: state.marketCode, message: errors.marketCode || success.marketCode, layout: (errors.marketCode && "error") ||
                                                (success.marketCode && "success"), maxLength: "4", onChange: (value) => setState({
                                                ...state,
                                                marketCode: value,
                                            }) }), _jsx(Box, { ml: "6", children: _jsx(Button, { type: "button", onClick: () => validateMarketCode(state.marketCode || ""), children: "Validieren" }) })] })] })] }), _jsxs(Form, { onSubmit: (e) => {
                        e.preventDefault();
                        if (validateForm(state)) {
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            const { promoCode, ...address } = state;
                            onSubmit && onSubmit(address);
                            setAvailabilityChecked(true);
                        }
                    }, children: [_jsxs(Row, { children: [_jsx(NarrowWrapper, { children: _jsx(InputText, { id: "zipCode", label: "PLZ", maxLength: "6", value: state?.zip ?? "", message: errors.zip, layout: errors.zip && "error", onChange: (value) => setState({
                                            ...state,
                                            zip: value,
                                        }) }) }), _jsx(Wrapper, { children: _jsx(InputSelect, { id: "city", label: "Ort", message: errors.city, layout: errors.city && "error", onChange: (value) => setState({
                                            ...state,
                                            city: value,
                                        }), options: addressSuggestions.cities ?? [], open: state.zip &&
                                            !state.city &&
                                            addressSuggestions.cities?.length > 1, value: state?.city ?? "" }) })] }), _jsxs(Row, { children: [_jsx(Wrapper, { children: _jsx(InputSelect, { id: "street", label: "Stra\u00DFe", value: state?.street ?? "", message: errors.street, layout: errors.street && "error", onChange: (value) => setState({
                                            ...state,
                                            street: value,
                                        }), options: addressSuggestions.streets ?? [], open: state.city &&
                                            !state.street &&
                                            addressSuggestions.streets?.length > 0 }) }), _jsx(NarrowWrapper, { children: _jsx(InputText, { id: "streetNumber", label: "Nummer", value: state?.streetNumber ?? "", message: errors.streetNumber, layout: errors.streetNumber && "error", onChange: (value) => setState({
                                            ...state,
                                            streetNumber: value,
                                        }) }) })] }), _jsx(Row, { children: _jsx(Wrapper, { children: _jsx(Button, { type: "submit", color: "primary", size: "fullWidth", loading: isLoading, disabled: !addressFieldsDirty, children: "Verf\u00FCgbarkeit pr\u00FCfen" }) }) })] })] })) : (_jsx(_Fragment, { children: _jsxs(Status, { children: [_jsx(StatusIcon, { children: available ? (_jsx(CheckmarkSvg, { width: 60, height: 60 })) : (_jsx(ErrorSvgWrapper, { children: _jsx(ErrorSvg, {}) })) }), _jsxs(StatusContent, { children: [_jsx("strong", { children: available
                                    ? "Prüfung an deiner Wunschadresse erfolgreich abgeschlossen."
                                    : "Leider gibt es kein passendes Angebot an deiner Adresse." }), state && (_jsxs("span", { children: [state.zip, " ", state.city, ", ", state.street, " ", state.streetNumber] })), _jsxs(ActionLinks, { children: [_jsx(ActionLink, { onClick: () => {
                                            clearAddressSuggestions();
                                            setState({});
                                            setAvailabilityChecked(false);
                                            onReset && onReset();
                                        }, children: "Adresse \u00E4ndern" }), !onlineOnly && (_jsx(ActionLink, { onClick: () => navigate(basePath), children: "Zur \u00DCbersicht" })), data?.marketCode && (_jsxs(PosText, { children: ["POS-ID: ", data?.marketCode] })), !available && onlineOnly && alternativeOffers.length > 0 && (_jsxs(AlternativeOffersWrapper, { children: [_jsxs("strong", { children: [alternativeOffers.length > 1
                                                        ? "Diese Alternativangebote sind"
                                                        : "Dieses Alternativangebot ist", " ", "an deiner Adresse verf\u00FCgbar:"] }), _jsx(AlternativeOffersList, { children: alternativeOffers.map((offer) => (_jsx("li", { children: _jsxs(ActionLink, { onClick: () => navigate(`/dsl-und-internet/pks/${offer.offerGroupUrl}`), children: [constructOfferLabel(offer), offer.accessories?.length > 0 && (_jsxs(_Fragment, { children: [_jsx("br", {}), constructAccessoriesLabel(offer.accessories), constructAccessoriesStartingPrice(offer)] }))] }) }, offer.id))) })] }))] })] })] }) })) }));
};
