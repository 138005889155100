import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { CheckoutSection, CheckoutSectionHeadline, StyledSelect, } from "../../components";
import { ACTIONS, useCheckoutState } from "../../context/CheckoutContext";
import { useCheckout } from "../../hooks/api";
import { useField, useValidation } from "../../hooks";
import styled from "styled-components";
import { BuildingAlignment, BuildingFloor, BuildingType, getBuildingAlignmentLabel, getBuildingFloorLabel, getBuildingTypeLabel, } from "../../types";
const InputWrapper = styled.div `
  width: 100%;
`;
// TODO: get BuildingType, BuildingFloor and BuildingAlignment from endpoint
export const BuildingTypeSection = ({ defaultValues, schema, }) => {
    const { state, dispatch } = useCheckoutState();
    const { clearErrors } = useValidation();
    const { errors: buildingTypeErrors, fieldState: buildingType, setFieldState: setBuildingType, } = useField("buildingType", schema, defaultValues?.buildingType ?? "");
    const { errors: buildingFloorErrors, fieldState: buildingFloor, setFieldState: setBuildingFloor, } = useField("buildingFloor", schema, "");
    const { errors: buildingAlignmentErrors, fieldState: buildingAlignment, setFieldState: setBuildingAlignment, } = useField("buildingAlignment", schema, "");
    const { isFetching, isLoading } = useCheckout(state);
    return (_jsxs(CheckoutSection, { children: [_jsx(CheckoutSectionHeadline, { children: "Ort des Anschlusses*" }), _jsxs(InputWrapper, { children: [_jsx(StyledSelect, { id: "buildingType", label: "Ort ausw\u00E4hlen", options: Object.values(BuildingType).map((buildingType) => ({
                            key: buildingType,
                            label: getBuildingTypeLabel(buildingType),
                        })), idField: "key", labelField: "label", onChange: (_, value) => {
                            clearErrors(["buildingFloor", "buildingAlignment"]);
                            if (!value?.key || value?.key === BuildingType.Detached) {
                                dispatch({
                                    type: ACTIONS.RESET_FIELDS,
                                    payload: ["buildingFloor", "buildingAlignment"],
                                });
                            }
                            setBuildingType((value?.key ?? ""));
                        }, value: buildingType, disabled: isLoading || isFetching, message: buildingTypeErrors?.[0]?.message, layout: buildingTypeErrors && "error" }), (buildingType === BuildingType.Apartment ||
                        buildingType === BuildingType.FrontApartment ||
                        buildingType === BuildingType.BackApartment) && (_jsxs(_Fragment, { children: [_jsx(StyledSelect, { id: "buildingFloor", label: "Stockwerk/Etage ausw\u00E4hlen", options: Object.values(BuildingFloor).map((buildingFloor) => ({
                                    key: buildingFloor,
                                    label: getBuildingFloorLabel(buildingFloor),
                                })), idField: "key", labelField: "label", onChange: (_, value) => {
                                    setBuildingFloor((value?.key ?? ""));
                                }, value: buildingFloor, disabled: isLoading || isFetching, message: buildingFloorErrors?.[0]?.message, layout: buildingFloorErrors && "error" }), _jsx(StyledSelect, { id: "buildingAlignment", label: "Ausrichtung ausw\u00E4hlen", options: Object.values(BuildingAlignment).map((buildingAlignment) => ({
                                    key: buildingAlignment,
                                    label: getBuildingAlignmentLabel(buildingAlignment),
                                })), idField: "key", labelField: "label", onChange: (_, value) => {
                                    setBuildingAlignment((value?.key ?? ""));
                                }, value: buildingAlignment, disabled: isLoading || isFetching, message: buildingAlignmentErrors?.[0]?.message, layout: buildingAlignmentErrors && "error" })] }))] })] }));
};
