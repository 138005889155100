import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Flex, Headline, Text } from "@mm/ui";
import { useSimOnlyProvider } from "@/features/SimOnly";
import { BackgroundSurface, ContentColor, InnerWrapper, } from "./styledComponents";
const energyHeroConfig = {
    content: {
        flexDirection: ["column", "column", "row", "row", "row"],
        colSizeRight: ["100%", "100%", "50%", "50%", "50%"],
        colSizeLeft: ["100%", "100%", "50%", "50%", "50%"],
    },
};
const SimOnlyHero = () => {
    const { state: { page }, } = useSimOnlyProvider();
    const { hero } = page ?? {
        hero: {
            backgroundDesktop: "https://storage.googleapis.com/content-ekontor24/placeholder/hero/502-1341x642.jpg",
            fontColor: "white",
            headline: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit.",
            intro: "Foobar Foobar Foobar",
            text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque",
        },
    };
    const { backgroundDesktop, fontColor, headline, intro, text } = hero;
    return (_jsx(Box, { mb: 6, children: _jsx("section", { className: "internet-hero", children: _jsx(BackgroundSurface, { variant: "wide", shape: "sharp", color: "black", backgroundImg: backgroundDesktop, children: _jsx(InnerWrapper, { children: _jsx(Flex, { flexDirection: energyHeroConfig.content.flexDirection, children: _jsx(Box, { width: energyHeroConfig.content.colSizeLeft, children: _jsxs(ContentColor, { variant: fontColor, children: [intro && _jsx(Text, { variant: "smallCopy", children: intro }), headline && _jsx(Headline, { variant: "h1", children: headline }), text && _jsx(Text, { children: text })] }) }) }) }) }) }) }));
};
export default SimOnlyHero;
