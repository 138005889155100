import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { Eyecatchers } from "@/components/atoms";
const StyledHeader = styled("header") `
  position: relative;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.brand.primary};
  border: 1px solid red;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: white;
  padding: 40px 10px 20px;

  .offer-logo {
    display: block;
    max-width: 150px;
    max-height: 50px;
  }

  .tariffHeadline {
    font-family: ${({ theme }) => theme.fonts.headline};
    font-size: 48px;
    line-height: 1;
    font-weight: 700;
    text-align: center;
  }

  .tariffCarrier,
  .tariffName {
    font-weight: 700;
  }

  .tariffName {
    display: block;
    padding: 5px 0;
  }
`;
const Header = ({ carrier, carrierOverwrite, eyeCatchers, headline, subHeadline, }) => {
    const validateEyeCatcher = () => {
        return eyeCatchers
            ? Object.values(eyeCatchers).some((item) => item.length >= 1)
            : false;
    };
    return (_jsxs(StyledHeader, { className: "logoHeadline", children: [validateEyeCatcher() && (_jsx(Eyecatchers, { eyecatchers: eyeCatchers, variant: "affOffer" })), _jsx("span", { className: "tariffHeadline", "data-qa": `tariffDataVolume-${headline}`, children: headline && headline == 9999 ? "Unlimited" : `${headline}GB` }), subHeadline && _jsx("span", { className: "tariffName", children: subHeadline }), carrier && !carrierOverwrite && (_jsxs("span", { className: "tariffCarrier", children: ["im ", carrier, " Netz"] })), carrierOverwrite && (_jsx("span", { className: "tariffCarrier", children: carrierOverwrite }))] }));
};
export default Header;
