import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { Checkbox } from "@/components/atoms";
import { THEME } from "@core/theme";
import { provideIsLoadingProp, } from "@/utils/context/LoadingContext";
const { skeletonBase } = THEME;
const StyledCheckbox = styled(Checkbox) `
  display: inline-block;
  margin-right: 1rem;
  float: left;
  ${({ error, theme }) => error &&
    `
    border-color: ${theme.colors.brand.primary};
    outline: 2px solid ${theme.colors.brand.primary};
    `};
`;
const Label = styled.span.attrs(provideIsLoadingProp) `
  display: block;
  margin-left: 2.5rem;
`;
const ErrorMessage = styled("div") `
  font-size: ${({ theme }) => theme.typography.size.s}px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.grey[4]};
  margin-top: ${({ theme }) => theme.space.xs}px;
  margin-bottom: ${({ theme }) => theme.space.xs}px;
  color: ${({ theme }) => theme.colors.brand.primary};
`;
export const CheckboxWithLabel = ({ checked, disabled, error, id, label, onChange, }) => {
    return (_jsxs("div", { onClick: !disabled ? onChange : undefined, children: [_jsx(StyledCheckbox, { error: Boolean(error), checked: checked, id: id }), _jsx(Label, { children: label }), error && _jsx(ErrorMessage, { children: error })] }));
};
