import { ServiceProvider } from "@/types";
export const getProviderNameByCode = (code, serviceProvider) => {
    if (!serviceProvider) {
        return undefined;
    }
    const providerWithCode = providers[serviceProvider].find((provider) => provider.code === code);
    if (code === "CARRIER_NOT_FOUND") {
        return "Unbekannter Anbieter";
    }
    return providerWithCode ? providerWithCode.name : undefined;
};
/**
 * TODO: This should come from an endpoint, so we can later make it editable in the ACP
 */
export const providers = {
    [ServiceProvider.einsundeins]: [
        {
            code: "D153",
            name: "amplus AG",
        },
        {
            code: "D035",
            name: "BITel",
        },
        {
            code: "D906",
            name: "BORnet",
        },
        {
            code: "D916",
            name: "Brandl Services GmbH",
        },
        {
            code: "D080",
            name: "BreisNet",
        },
        {
            code: "D914",
            name: "Buchholz Media",
        },
        {
            code: "D933",
            name: "comingolstadt",
        },
        {
            code: "D900",
            name: "Congstar",
        },
        {
            code: "D938",
            name: "Dachau KOM",
        },
        {
            code: "D907",
            name: "Deutsche Glasfaser Home",
        },
        {
            code: "D001",
            name: "Deutsche Telekom",
        },
        {
            code: "D167",
            name: "DNS:NET",
        },
        {
            code: "D378",
            name: "Dolphin IT-Systeme e.K.",
        },
        {
            code: "D032",
            name: "DOKOM 21",
        },
        {
            code: "D163",
            name: "dus.net",
        },
        {
            code: "D961",
            name: "easybell",
        },
        {
            code: "D970",
            name: "echtschnell GmbH",
        },
        {
            code: "D323",
            name: "ecotel communication ag",
        },
        {
            code: "D109",
            name: "encoLine",
        },
        {
            code: "D956",
            name: "e.NET GmbH und Co. KG",
        },
        {
            code: "D057",
            name: "envia TEL",
        },
        {
            code: "D240",
            name: "equada",
        },
        {
            code: "D013",
            name: "EWE TEL",
        },
        {
            code: "D917",
            name: "E-Werk Goldbach-Hösbach GmbH",
        },
        {
            code: "D909",
            name: "FL!NK",
        },
        {
            code: "D945",
            name: "Fonial",
        },
        {
            code: "D951",
            name: "Gemeindewerke Hohenwestedt",
        },
        {
            code: "D427",
            name: "GGEW net GmbH / MySpeedy",
        },
        {
            code: "D918",
            name: "GVG Glasfaser GmbH",
        },
        {
            code: "D928",
            name: "GWHtel GmbH & Co. KG",
        },
        {
            code: "D023",
            name: "HeLiNet",
        },
        {
            code: "D276",
            name: "Herzomedia",
        },
        {
            code: "D117",
            name: "HFO Telekom",
        },
        {
            code: "D072",
            name: "HSE medianet",
        },
        {
            code: "D321",
            name: "HTP - Hannover",
        },
        {
            code: "D202",
            name: "Inexio",
        },
        {
            code: "D931",
            name: "KEVAG Telekom GmbH",
        },
        {
            code: "D016",
            name: "KielNET",
        },
        {
            code: "D934",
            name: "komro GmbH",
        },
        {
            code: "D912",
            name: "K-net Kaiserslautern",
        },
        {
            code: "D963",
            name: "LüneCom",
        },
        {
            code: "D965",
            name: "MAINGAU Energie GmbH",
        },
        {
            code: "D115",
            name: "MDCC Magdeburg-City-Com",
        },
        {
            code: "D936",
            name: "MEGA Monheimer Elektrizitäts- und Gasversorgung",
        },
        {
            code: "D007",
            name: "M-Net",
        },
        {
            code: "D054",
            name: "Net Aachen GmbH",
        },
        {
            code: "D004",
            name: "Netcologne",
        },
        {
            code: "D297",
            name: "Netcom Kassel",
        },
        {
            code: "D364",
            name: "Nexiu GmbH",
        },
        {
            code: "D971",
            name: "NGN Telecom GmbH",
        },
        {
            code: "D065",
            name: "O2 / Telefonica",
        },
        {
            code: "D207",
            name: "OpenNumbers GmbH",
        },
        {
            code: "D942",
            name: "OR Network",
        },
        {
            code: "D089",
            name: "Osnatel",
        },
        {
            code: "D180",
            name: "Outbox AG",
        },
        {
            code: "D929",
            name: "Pinnau.com GmbH & Co. KG",
        },
        {
            code: "D397",
            name: "PYUR / Tele Columbus",
        },
        {
            code: "D137",
            name: "QSC",
        },
        {
            code: "D263",
            name: "QUIX",
        },
        {
            code: "D905",
            name: "reventix",
        },
        {
            code: "D911",
            name: "RWE FiberNet",
        },
        {
            code: "D932",
            name: "R-KOM GmbH & Co. KG",
        },
        {
            code: "D962",
            name: "Schiffel IT-Service GmbH",
        },
        {
            code: "D146",
            name: "sipgate",
        },
        {
            code: "D371",
            name: "SOCO Network Solutions GmbH",
        },
        {
            code: "D957",
            name: "Stadtnetze Barmstedt GmbH",
        },
        {
            code: "D947",
            name: "Stadtwerke Ahrensburg GmbH",
        },
        {
            code: "D953",
            name: "Stadtwerke Engen GmbH",
        },
        {
            code: "D925",
            name: "Stadtwerke Eutin",
        },
        {
            code: "D941",
            name: "Stadtwerke Flensburg",
        },
        {
            code: "D960",
            name: "Stadtwerke Geesthacht GmbH",
        },
        {
            code: "D924",
            name: "Stadtwerke Konstanz",
        },
        {
            code: "D966",
            name: "Stadtwerke Münster GmbH",
        },
        {
            code: "D935",
            name: "Stadtwerke Nortorf",
        },
        {
            code: "D959",
            name: "Stadtwerke Oldenburg in Holstein Media GmbH",
        },
        {
            code: "D968",
            name: "Stadtwerke Rendsburg GmbH",
        },
        {
            code: "D972",
            name: "Stadtwerke Schleswig GmbH",
        },
        {
            code: "D937",
            name: "SternKom",
        },
        {
            code: "D919",
            name: "Stiegeler internet Service GmbH & Co. KG",
        },
        {
            code: "D048",
            name: "SWB (Stadtwerke Bremen)",
        },
        {
            code: "D920",
            name: "SWN Stadtwerke Neumünster GmbH",
        },
        {
            code: "D913",
            name: "SWT TRILAN",
        },
        {
            code: "D952",
            name: "SWU TeleNet GmbH",
        },
        {
            code: "D334",
            name: "süc // Dacor GmbH",
        },
        {
            code: "D944",
            name: "symbox",
        },
        {
            code: "D022",
            name: "TELE2",
        },
        {
            code: "D955",
            name: "TeleData GmbH",
        },
        {
            code: "D940",
            name: "Telepark Passau",
        },
        {
            code: "D930",
            name: "Tel.quick GmbH & Co. KG",
        },
        {
            code: "D355",
            name: "Thüringer Netkom GmbH",
        },
        {
            code: "D967",
            name: "TKRZ Stadtwerke GmbH",
        },
        {
            code: "D038",
            name: "TNG Stadtnetz GmbH",
        },
        {
            code: "D127",
            name: "Unitymedia BW / Kabel BW",
        },
        {
            code: "D927",
            name: "Unitymedia Hessen",
        },
        {
            code: "D120",
            name: "Unitymedia NRW",
        },
        {
            code: "D943",
            name: "Vereinigte Stadtwerke Media (Nusse)",
        },
        {
            code: "D008",
            name: "Verizon",
        },
        {
            code: "D011",
            name: "Versatel",
        },
        {
            code: "D958",
            name: "Versorgungsbetriebe Bordesholm GmbH",
        },
        {
            code: "D009",
            name: "Vodafone (DSL)",
        },
        {
            code: "D191",
            name: "Vodafone / Kabel Deutschland (Kabel)",
        },
        {
            code: "D396",
            name: "VoiceON Telecom Services GmbH",
        },
        {
            code: "D964",
            name: "Voiceworks",
        },
        {
            code: "D084",
            name: "VSE NET",
        },
        {
            code: "D101",
            name: "wilhelm.tel",
        },
        {
            code: "D330",
            name: "willy.tel GmbH",
        },
        {
            code: "D921",
            name: "WiSoTEL GmbH",
        },
        {
            code: "D922",
            name: "Wittenberg Net",
        },
        {
            code: "D037",
            name: "WOBCOM",
        },
        {
            code: "D954",
            name: "zollernalb-data GmbH",
        },
        {
            code: "OTHER",
            name: "Anbieter unbekannt",
        },
        {
            code: "NO_NUMBER_PORTING",
            name: "Anschluss vorhanden, Keine Rufnummermitnahme gewünscht",
        },
    ],
    [ServiceProvider.o2]: [
        {
            code: "DEU.1UND1",
            name: "1 & 1, Tiscali, freenet",
        },
        {
            code: "DEU.BITEL",
            name: "Stadtwerke Bielefeld GmbH",
        },
        {
            code: "DEU.BORNET",
            name: "BORnet GmbH",
        },
        {
            code: "DEU.BRANDL",
            name: "Brandl Services GmbH",
        },
        {
            code: "DEU.BUDI",
            name: "Buchholz Digital",
        },
        {
            code: "DEU.COLTDE",
            name: "COLT TELECOM",
        },
        {
            code: "DEU.COMIN",
            name: "COM-IN",
        },
        {
            code: "DEU.CONG",
            name: "Congstar (T-Online)",
        },
        {
            code: "DEU.D012",
            name: "BT Ignite",
        },
        {
            code: "DEU.D061",
            name: "o2 Mobile / o2 Homezone (Festnetznummer)",
        },
        {
            code: "DEU.D115",
            name: "MDCC, Magdeburg",
        },
        {
            code: "DEU.DACOR",
            name: "Dacor GmbH",
        },
        {
            code: "DEU.DCC",
            name: "DCC GmbH",
        },
        {
            code: "DEU.DE1EPC",
            name: "epcan GmbH",
        },
        {
            code: "DEU.DETEST",
            name: "Deu.Tel.Standard",
        },
        {
            code: "DEU.DEUNOR",
            name: "Stadtwerke Nortel",
        },
        {
            code: "DEU.DGH",
            name: "Deutsche Glasfaser Home GmbH",
        },
        {
            code: "DEU.DGW",
            name: "Deutsche Glasfaser Wholesale GmbH",
        },
        {
            code: "DEU.DNSNET",
            name: "DNS:NET Internet Service GmbH",
        },
        {
            code: "DEU.DOKOM",
            name: "DOKOM",
        },
        {
            code: "DEU.DTAG",
            name: "Deutsche Telekom, T-Mobile",
        },
        {
            code: "DEU.DUSNET",
            name: "dus.net GmbH",
        },
        {
            code: "DEU.EASYBL",
            name: "easybell GmbH",
        },
        {
            code: "DEU.ECOTEL",
            name: "Ecotel",
        },
        {
            code: "DEU.ELINE",
            name: "encoLine GmbH, Thüringer Netkom",
        },
        {
            code: "DEU.ENET",
            name: "E.Net GmbH und Co.KG",
        },
        {
            code: "DEU.EQUADA",
            name: "Equada GmbH",
        },
        {
            code: "DEU.ETEL",
            name: "envia.tel, Chemnitz",
        },
        {
            code: "DEU.EWE",
            name: "EWETel",
        },
        {
            code: "DEU.EWG",
            name: "Elektrizitätswerk Goldbach-Hösbach",
        },
        {
            code: "DEU.FLINK",
            name: "FLINK GmbH",
        },
        {
            code: "DEU.FLTC",
            name: "Pyur (Tele Columbus/HL-Komm)",
        },
        {
            code: "DEU.GWHTEL",
            name: "GWHtel GmbH und Co.KG",
        },
        {
            code: "DEU.HELI01",
            name: "HeLi NET GmbH",
        },
        {
            code: "DEU.HERZO",
            name: "HerzoMedia",
        },
        {
            code: "DEU.HFO",
            name: "HFO Telecom",
        },
        {
            code: "DEU.HMN",
            name: "HEAG MediaNet, Darmstadt",
        },
        {
            code: "DEU.HTP",
            name: "HTP, Hannover",
        },
        {
            code: "DEU.INEXIO",
            name: "inexio IT und TK KGaA",
        },
        {
            code: "DEU.INOPLA",
            name: "inopla GmbH",
        },
        {
            code: "DEU.KBW",
            name: "Kabel BW",
        },
        {
            code: "DEU.KDVS",
            name: "Kabel Deutschland Vertrieb",
        },
        {
            code: "DEU.KNET",
            name: "K-Net Telekommunikation GmbH",
        },
        {
            code: "DEU.KOMRO",
            name: "komro GmbH",
        },
        {
            code: "DEU.MCI",
            name: "Verizon Deutschland GmbH",
        },
        {
            code: "DEU.MD8000",
            name: "mobilcom-debitel GmbH",
        },
        {
            code: "DEU.MNET",
            name: "M-net, AugustaKom, NEFKom",
        },
        {
            code: "DEU.NCKS",
            name: "Netcom, Kassel",
        },
        {
            code: "DEU.NETAC",
            name: "NETAACHEN GmbH",
        },
        {
            code: "DEU.NETCO",
            name: "NETCOLOGNE",
        },
        {
            code: "DEU.ORNET",
            name: "OR Network GmbH",
        },
        {
            code: "DEU.OSNATL",
            name: "Osnatel, Osnabrück",
        },
        {
            code: "DEU.PICODE",
            name: "pinnau.com GmbH und Co.KG",
        },
        {
            code: "DEU.QSC",
            name: "QSC, Broadnet Mediascape, Ventelo",
        },
        {
            code: "DEU.QUIX",
            name: "Quix Breitband GmbH",
        },
        {
            code: "DEU.REVTIX",
            name: "Reventix GmbH",
        },
        {
            code: "DEU.RKOM",
            name: "R-KOM, Regensburg",
        },
        {
            code: "DEU.RWEFN",
            name: "innogy TelNet GmbH (RWE)",
        },
        {
            code: "DEU.SG",
            name: "Sipgate Wireless, netzquadrat",
        },
        {
            code: "DEU.SIS",
            name: "Stiegeler Internet Services GmbH Co.KG",
        },
        {
            code: "DEU.SNBG",
            name: "Stadtnetze Barmstedt GmbH",
        },
        {
            code: "DEU.STERN",
            name: "Stern Kom GmbH",
        },
        {
            code: "DEU.SWATEL",
            name: "Stadtwerke Ahrensburg GmbH",
        },
        {
            code: "DEU.SWB",
            name: "NordCom, Bremen",
        },
        {
            code: "DEU.SWENG",
            name: "Stadtwerke Engen",
        },
        {
            code: "DEU.SWEUDI",
            name: "Stadtwerke Eutin GmbH",
        },
        {
            code: "DEU.SWFL",
            name: "Stadtwerke Flensburg GmbH",
        },
        {
            code: "DEU.SWKN",
            name: "Stadtwerke Konstanz",
        },
        {
            code: "DEU.SWNNET",
            name: "Stadtwerke Neumünster GmbH",
        },
        {
            code: "DEU.SYMBOX",
            name: "symbox",
        },
        {
            code: "DEU.TDGMBH",
            name: "TeleData GmbH",
        },
        {
            code: "DEU.TEL2DE",
            name: "Tele 2, Düsseldorf",
        },
        {
            code: "DEU.TELSA1",
            name: "Telsakom GmbH",
        },
        {
            code: "DEU.TNG",
            name: "TNG Stadtnetz",
        },
        {
            code: "DEU.TQUICK",
            name: "tel.quick GmbH und Co. KG",
        },
        {
            code: "DEU.TRILAN",
            name: "SWT trilan GmbH ",
        },
        {
            code: "DEU.UMHESS",
            name: "Unitymedia Hessen GmbH & Co.KG",
        },
        {
            code: "DEU.UMNRW",
            name: "Unitymedia GmbH",
        },
        {
            code: "DEU.VBBGFA",
            name: "Versorgungsbetriebe Bordesholm GmbH",
        },
        {
            code: "DEU.VFDE",
            name: "Vodafone, ISIS, WüCom",
        },
        {
            code: "DEU.VOICEW",
            name: "Voiceworks",
        },
        {
            code: "DEU.VSENET",
            name: "VSE Net, Saarbrücken",
        },
        {
            code: "DEU.VSM",
            name: "Vereinigte Stadtwerke Media GmbH",
        },
        {
            code: "DEU.VTEL",
            name: "Versatel, TROPOLYS, CNE, KielNet",
        },
        {
            code: "DEU.WISOTL",
            name: "Wisotel",
        },
        {
            code: "DEU.WOBCOM",
            name: "WOBCOM GmbH, Wolfsburg",
        },
        {
            code: "DEU.WTHAM",
            name: "willy.tel GmbH",
        },
        {
            code: "DEU.WTNET",
            name: "wilhelm.tel",
        },
        {
            code: "DEU.ZDGMBH",
            name: "zollernalb-data GmbH",
        },
    ],
    [ServiceProvider.vodafone]: [],
    [ServiceProvider.waipu]: [],
};
