import React from "react";
import styled from "styled-components";

import { GeneralModalShell, DetailsAccordion } from "components/atoms";
import dataLayer from "helper/dataLayer";

import TariffDetailModal from "../TariffDetailModal/TariffDetailModal";

const modalCouponContent = {
  descriptions: [
    {
      headline: "Details zum Geschenk-Coupon",
      text: "Der Geschenk-Coupon gewährt einen einmaligen Preisnachlass i.H. des in der jeweiligen E-Mail hinterlegten Coupon-Wertes. Das jeweilige Gültigkeitsdatum ist auf dem Coupon abgedruckt. Einlösbar in einem Media Markt in Deutschland (online nicht einlösbar) ab einem Mindesteinkaufswert in Höhe des Couponwerts und nur gegen Vorlage des ausgedruckten Coupons (keine elektronische Vorlage).",
    },
    {
      headline: "Einschränkungen",
      text: "Keine Barauszahlung. Nicht kombinierbar mit anderen Aktionen. Nicht einlösbar für: Verträge mit Drittanbietern, sämtliche Download-/Content-/GamingCards, Gutschein(-karten/-boxen), Prepaid-Aufladekarten/-Services, E-Books/Bücher, Zusatzgarantien, Lieferservice, Reparaturdienstleistungen, Fotoservice, Lebensmittel, IQOS, E-Zigaretten, Heets, Liquids und Tchibo Cafissimo Produkte.",
    },
    {
      headline: "Versand",
      text: "Nach Ablauf der Widerrufsfrist des abgeschlossenen Mobilfunk-, Strom/Gas- , oder DSL/Kabelvertrages wird Ihnen Ihr Geschenk-Coupon, gestückelt in Teilbeträge, an die bei der Bestellung angegebene E-Mail Adresse zugesendet. Bitte überprüfen Sie regelmäßig Ihr E-Mail Postfach und Ihren SPAM-Ordner.",
    },
  ],
};

const modalFoodNotesContentStromEon = {
  descriptions: [
    {
      headline: "1",
      text: "Ausgenommen von der Preisgarantie sind folgende Kostenbestandteile: Umsatzsteuer; neue Steuern, Abgaben oder sonstige unmittelbar die Beschaffung, Erzeugung, Speicherung, Netznutzung (Übertragung und Verteilung), Belieferung oder den Verbrauch von elektrischer Energie betreffenden Belastungen sowie entsprechende neuen Entlastungen.",
    },
    {
      headline: "2",
      text: "Der Strom wird in Höhe Ihres Verbrauchs aus regenerativen Energiequellen mit Standort in Deutschland gewonnen und in das Stromnetz eingespeist. Der Nachweis erfolgt über die Entwertung von Herkunftsnachweisen beim Umweltbundesamt.",
    },
  ],
};

const modalFoodNotesContentStromEonNatur = {
  descriptions: [
    {
      headline: "1",
      text: "Ausgenommen von der Preisgarantie sind folgende Kostenbestandteile: Umsatzsteuer; neue Steuern, Abgaben oder sonstige unmittelbar die Beschaffung, Erzeugung, Speicherung, Netznutzung (Übertragung und Verteilung), Belieferung oder den Verbrauch von elektrischer Energie betreffenden Belastungen sowie entsprechende neuen Entlastungen.",
    },
    {
      headline: "2",
      text: "Der Strom wird in Höhe Ihres Verbrauchs aus regenerativen Energiequellen mit Standort in Deutschland gewonnen und in das Stromnetz eingespeist. Der Nachweis erfolgt über die Entwertung von Herkunftsnachweisen beim Umweltbundesamt.",
    },
  ],
};

const modalFoodNotesContentGasEon = {
  descriptions: [
    {
      headline: "1",
      text: "Ausgenommen von der Preisgarantie sind Preisänderungen, die auf Änderungen der Umsatzsteuer beruhen sowie Preisänderungen, die auf nach Vertragsschluss während der Preisgarantie erstmals wirksam werdenden neuen Steuern, Abgaben oder sonstigen unmittelbar die Gewinnung, Produktion, Einspeisung, Beschaffung, Speicherung und Netznutzung (Transport über Fernleitungs- und Verteilnetze), Belieferung oder den Verbrauch von Erdgas betreffenden Belastungen oder entsprechenden neuen Entlastungen beruhen.",
    },
  ],
};

const modalFoodNotesContentGasEonNatur = {
  descriptions: [
    {
      headline: "1",
      text: "Ausgenommen von der Preisgarantie sind Preisänderungen, die auf Änderungen der Umsatzsteuer beruhen sowie Preisänderungen, die auf nach Vertragsschluss während der Preisgarantie erstmals wirksam werdenden neuen Steuern, Abgaben oder sonstigen unmittelbar die Gewinnung, Produktion, Einspeisung, Beschaffung, Speicherung und Netznutzung (Transport über Fernleitungs- und Verteilnetze), Belieferung oder den Verbrauch von Erdgas betreffenden Belastungen oder entsprechenden neuen Entlastungen beruhen.",
    },
  ],
};

const modalFoodNotesContentStromEprimo = {
  descriptions: [
    {
      headline: "1",
      text: "Während der vertraglich vereinbarten Laufzeit der eprimo-Preisgarantie ist eine Preisänderung ausgeschlossen. Von der Preisgarantie ausgenommen sind Änderungen der Umsatzsteuer und etwaige Preisänderungen, die die Stromsteuer und die künftig neue Steuern, Abgaben oder sonstige staatlich veranlasste, die Beschaffung, Erzeugung, Netznutzung (Übertragung und Verteilung) oder den Verbrauch von Strom betreffende Belastungen oder Entlastungen (vgl. Ziffer 6.4 der AGB) betreffen. Etwaige Preisänderungen erfolgen in dem in Ziffer 6.2 und / oder 6.4 der AGB beschriebenen Verfahren. Bei einer gesetzlichen Änderung der Umsatzsteuer gilt Ziffer 6.3 der AGB.",
    },
    {
      headline: "2",
      text: "Der Vertrag hat eine Vertragslaufzeit bis zum genannten Datum bzw. der genannten Monate und kann mit einer Frist von 1 Monat vor Ablauf der Laufzeit gekündigt werden (vgl. Ziffer 16.2 und 16.5 der AGB). Wenn keine Vertragslaufzeit vereinbart ist (vgl. Ziff. 15.1 der AGB) oder die ursprünglich vereinbarte Vertragslaufzeit ohne Kündigung abgelaufen ist (vgl. 15.2 der AGB), läuft der Vertrag auf unbestimmte Zeit. In diesen Fällen ist der Vertrag jederzeit mit einer Frist von einem Monat kündbar (vgl. Ziff. 16.1 der AGB).",
    },
  ],
};

const modalFoodNotesContentGasEprimo = {
  descriptions: [
    {
      headline: "1",
      text: "Für den Zeitraum vom 01.03.2023 bis zunächst 31.12.2023 profitieren Sie von der Gaspreisbremse der Bundesregierung. D. h. für diesen Zeitraum zahlen Sie auf 80 % Ihres im September 2022 prognostizierten Verbrauchs 12 ct/kWh brutto. Die Entlastung werden wir bei Ihren Abschlagszahlungen im o. g. Zeitraum berücksichtigen. Für die Monate Januar und Februar erhalten Sie eine rückwirkende Entlastung. Sind Sie bei uns Neukunde, dürfen wir die Entlastung erst gewähren, wenn uns Informationen dazu vorliegen, wie viel Sie beim Vorversorger bereits von dem entlastungsfähigen Verbrauch verbraucht haben („Entlastungskontingent“). Dies kann z. B. durch Vorlage der Abrechnung Ihres Vorversorgers erfolgen. Die Preisbremse greift nur, sofern Ihr vertraglicher Arbeitspreis über dem Arbeitspreis der Bremse liegt. Liegt er darunter, gelten die vertraglichen Konditionen. Besuchen Sie für weitere Informationen sowie eine Abschlagsberechnung unsere Internetseite unter https://www.eprimo.de/gaslage",
    },
    {
      headline: "2",
      text: "Der Vertrag hat eine Vertragslaufzeit bis zum genannten Datum bzw. der genannten Monate und kann mit einer Frist von 1 Monat vor Ablauf der Laufzeit gekündigt werden (vgl. Ziffer 16.2 und 16.5 der AGB). Wenn keine Vertragslaufzeit vereinbart ist (vgl. Ziff. 15.1 der AGB) oder die ursprünglich vereinbarte Vertragslaufzeit ohne Kündigung abgelaufen ist (vgl. 15.2 der AGB), läuft der Vertrag auf unbestimmte Zeit. In diesen Fällen ist der Vertrag jederzeit mit einer Frist von einem Monat kündbar (vgl. Ziff. 16.1 der AGB).",
    },
  ],
};

const DetailsLinkList = styled("div")`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpointsMap.sm.min}px) {
    align-items: flex-end;
    flex-direction: row;
  }
`;

const DetailsLinkBtn = styled("button")`
  text-decoration: underline;
  cursor: pointer;
  background: none;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
`;

const DetailsLink = styled("a")`
  font-size: 14px;
`;

const DetailsLinks = (props) => {
  const {
    tariff,
    tariff: { name, pibUrl, serviceProvider, apiData },
    coupon,
  } = props;

  const { energyType } = apiData;

  const eventLabel = (DetailLink) =>
    `Provider:${serviceProvider}:Tariff:${name}:DetailLink:${DetailLink}`;

  const isStrom = energyType === "1";
  const isStromNatur = name === "E.ON Strom Natur";
  const isGasNatur = name === "Erdgas Natur";

  return (
    <DetailsLinkList>
      <GeneralModalShell
        opener={
          <DetailsLinkBtn
            type="button"
            onClick={(evt) => {
              evt.preventDefault();
              dataLayer({
                event: "gaEvent",
                eventCategory: "Details",
                eventAction: "click",
                eventLabel: eventLabel("Tarif-Details"),
              });
            }}
          >
            Tarif-Details
          </DetailsLinkBtn>
        }
      >
        <TariffDetailModal tariff={tariff} />
      </GeneralModalShell>
      {coupon && (
        <GeneralModalShell
          opener={
            <DetailsLinkBtn
              type="button"
              onClick={(evt) => {
                evt.preventDefault();
                dataLayer({
                  event: "gaEvent",
                  eventCategory: "Details",
                  eventAction: "click",
                  eventLabel: eventLabel("Coupon-Details"),
                });
              }}
            >
              Coupon-Details
            </DetailsLinkBtn>
          }
        >
          <DetailsAccordion details={modalCouponContent} />
        </GeneralModalShell>
      )}
      {pibUrl && (
        <DetailsLink
          rel="noreferrer"
          href={pibUrl}
          target="_blank"
          onClick={() => {
            dataLayer({
              event: "gaEvent",
              eventCategory: "Details",
              eventAction: "click",
              eventLabel: eventLabel("Produktinformationsblatt"),
            });
          }}
        >
          Produktinformationsblatt
        </DetailsLink>
      )}
      {serviceProvider === "eprimo" && (
        <GeneralModalShell
          opener={
            <DetailsLinkBtn
              type="button"
              onClick={(evt) => {
                evt.preventDefault();
                dataLayer({
                  event: "gaEvent",
                  eventCategory: "Details",
                  eventAction: "click",
                  eventLabel: eventLabel("Fußnoten"),
                });
              }}
            >
              Fußnoten 1) 2)
            </DetailsLinkBtn>
          }
        >
          <DetailsAccordion
            details={
              isStrom
                ? modalFoodNotesContentStromEprimo
                : modalFoodNotesContentGasEprimo
            }
          />
        </GeneralModalShell>
      )}
      {serviceProvider === "E.ON" && (
        <GeneralModalShell
          opener={
            <DetailsLinkBtn
              type="button"
              onClick={(evt) => {
                evt.preventDefault();
                dataLayer({
                  event: "gaEvent",
                  eventCategory: "Details",
                  eventAction: "click",
                  eventLabel: eventLabel("Fußnoten"),
                });
              }}
            >
              {isStrom && isStromNatur
                ? "Fußnoten 1) 2)"
                : isStrom
                ? "Fußnoten 1) 2)"
                : ""}
              {!isStrom && isGasNatur
                ? "Fußnoten 1)"
                : !isStrom
                ? "Fußnoten 1)"
                : ""}
            </DetailsLinkBtn>
          }
        >
          <DetailsAccordion
            details={
              isStrom
                ? isStromNatur
                  ? modalFoodNotesContentStromEonNatur
                  : modalFoodNotesContentStromEon
                : isGasNatur
                ? modalFoodNotesContentGasEonNatur
                : modalFoodNotesContentGasEon
            }
          />
        </GeneralModalShell>
      )}
    </DetailsLinkList>
  );
};

DetailsLinks.propTypes = {};

export default DetailsLinks;
