import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { CheckoutSectionActionLink, CheckoutSection, CheckoutSectionHeadline, } from "../components";
import { provideIsLoadingProp, useLoadingState, } from "@/utils/context/LoadingContext";
import { useCheckout } from "../hooks/api";
import { useCheckoutState } from "../context/CheckoutContext";
import { THEME } from "@core/theme";
import { useNavigate } from "react-router-dom";
const { skeletonBase } = THEME;
export const AddressElement = styled.span.attrs(provideIsLoadingProp) `
  ${({ isLoading }) => isLoading && skeletonBase + "display: inline-block;"};
`;
export const ConnectionAddressSection = () => {
    const { state } = useCheckoutState();
    const { data } = useCheckout(state);
    const { state: { isLoading }, } = useLoadingState();
    const navigate = useNavigate();
    return (_jsxs(CheckoutSection, { children: [_jsx(CheckoutSectionHeadline, { children: "Anschlussadresse" }), _jsxs("p", { "data-tk-cobrowse-hidden": "true", children: [_jsx(AddressElement, { children: data?.availability.address.street }), " ", _jsx(AddressElement, { children: data?.availability.address.streetNumber }), _jsx("br", {}), _jsx(AddressElement, { children: data?.availability.address.zip }), " ", _jsx(AddressElement, { children: data?.availability.address.city })] }), _jsx(CheckoutSectionActionLink, { onClick: () => !isLoading &&
                    navigate(`/dsl-und-internet/pks/${data?.summary.url}?clearAvailability=true`), children: "Adresse \u00E4ndern" })] }));
};
